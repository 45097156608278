<ng-container *ngIf="show">
  <app-stripbar></app-stripbar>
  <app-header-mobile></app-header-mobile>

  <router-outlet></router-outlet>

  <app-navbar-mobile
    (menuClick)="navLinks.open()"
    (searchClick)="searchMobile.open()"
  ></app-navbar-mobile>
  <app-nav-links-mobile
    #navLinks
    (logout)="onLogoutClick()"
  ></app-nav-links-mobile>

  <app-search-mobile #searchMobile></app-search-mobile>
  <app-cookie-modal
    *ngIf="areCookiesAccepted !== true && this.siteService.IS_BROWSER"
  ></app-cookie-modal>
</ng-container>
