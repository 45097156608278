<div
  #carousel
  [id]="id"
  [ngStyle]="styles"
  [attr.data-ride]="'carousel'"
  [attr.data-interval]="INTERVAL"
  [attr.data-pause]="'hover'"
  [class.shadows]="shadows"
  class="carousel slide"
>
  <ol class="carousel-indicators">
    <li
      *ngFor="let image of images; let i = index"
      [attr.data-target]="'#' + id"
      [attr.data-slide-to]="i"
      [class.active]="i === 0"
    ></li>
  </ol>

  <div class="carousel-inner">
    <ng-container *ngFor="let image of images; let i = index">
      <div
        *ngIf="image.isEnabled"
        class="carousel-item"
        [class.active]="i === 0"
      >
        <a *ngIf="image.routerLink" [routerLink]="[image.routerLink]">
          <img
            #img
            [src]="image.imageSrc"
            [ngStyle]="styles"
            [class.w-100]="isFullWidth"
            [alt]="image.alt"
            (load)="onImageLoad(i)"
            class="d-block"
          />
        </a>

        <a *ngIf="image.href" [href]="image.href">
          <img
            #img
            [src]="image.imageSrc"
            [ngStyle]="styles"
            [class.w-100]="isFullWidth"
            [alt]="image.alt"
            (load)="onImageLoad(i)"
            class="d-block"
          />
        </a>

        <img
          #img
          *ngIf="!image.routerLink && !image.href"
          [src]="image.imageSrc"
          [ngStyle]="styles"
          [class.w-100]="isFullWidth"
          [alt]="image.alt"
          (load)="onImageLoad(i)"
          class="d-block"
        />

        <ng-container *ngIf="image.squareLinks && image.loaded">
          <ng-container *ngFor="let square of image.squareLinks">
            <a
              *ngIf="square.link"
              [href]="square.link"
              [ngStyle]="square.style"
              class="this-square-link"
            ></a>

            <a
              *ngIf="square.routerLink"
              [routerLink]="[square.routerLink]"
              [ngStyle]="square.style"
              class="this-square-link"
            ></a>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <a
    class="carousel-control carousel-control-prev"
    href="#{{ id }}"
    role="button"
    data-slide="prev"
  >
  </a>

  <a
    class="carousel-control carousel-control-next"
    href="#{{ id }}"
    role="button"
    data-slide="next"
  >
  </a>
</div>
