import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Renderer2,
  NgZone,
  ViewChild,
  HostListener,
  OnInit,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';

import { SubscribeComponent } from '../components/subscribe/subscribe.component';

import { SiteService } from '../ecommerce/services/site/site.service';
import { CookieService } from '../ecommerce/services/cookie/cookie.service';

import { AppConfig } from '../app.config';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-layout',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './layout.template.html',
  styleUrls: ['./layout.style.scss'],
})
export class LayoutComponent implements OnInit {
  @ViewChild(SubscribeComponent)
  subscribeComponent: SubscribeComponent;

  config: any;
  configFn: any;
  $sidebar: any;
  el: ElementRef;
  router: Router;

  currentYear: number;
  showScrollToTopButton: boolean;

  constructor(
    @Inject(WINDOW) protected window: Window,
    config: AppConfig,
    el: ElementRef,
    router: Router,
    protected renderer: Renderer2,
    protected ngZone: NgZone,
    protected cookieService: CookieService,

    public siteService: SiteService
  ) {
    this.el = el;
    this.config = config.getConfig();
    this.configFn = config;
    this.router = router;
    this.showScrollToTopButton = false;

    this.currentYear = new Date().getFullYear();
  }

  get isFullPage(): boolean {
    const { url } = this.router;

    return (
      url === '/login' ||
      url === '/register' ||
      url.startsWith('/account/forgot') ||
      url.startsWith('/account/activate') ||
      url.startsWith('/account/login') ||
      url.match(/\/checkout\/.*\/pay/) !== null
    );
  }

  get areCookiesAccepted(): boolean {
    return this.cookieService.get('cookies-accepted') === 'true';
  }

  ngOnInit(): void {}

  onButtonScrollToTopClick(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onCloseCookieBannerClick(): void {
    this.cookieService.set('cookies-accepted', 'true');
  }

  onSubscribeClick() {
    this.subscribeComponent.open();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any): void {
    const { scrollTop } = event.srcElement.scrollingElement;

    if (scrollTop > 32) {
      this.showScrollToTopButton = true;
    } else {
      this.showScrollToTopButton = false;
    }
  }
}
