import {
  EventEmitter,
  OnInit,
  Output,
  Directive,
  HostListener,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Select } from '@angular-redux2/store';
import { Observable } from 'rxjs';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { ItemsViewedService } from '../../ecommerce/services/items-viewed/items-viewed.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { WishListService } from '../../ecommerce/services/wish-list/wish-list.service';

import currencies from '../../../assets/currencies.json';

@Directive()
export class NavbarComponent implements OnInit {
  @Output() login: EventEmitter<void>;
  @Output() register: EventEmitter<void>;

  @Select((s) => s.cart.items) cartProducts: Observable<any>;
  @Select((s) => s.wishList) wishList: Observable<any>;

  searchText: string;

  currencyList: any[];
  nbItemsInCart: number;
  nbItemsInWishList: number;

  hideLogin: boolean = false;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected itemsViewedService: ItemsViewedService,
    protected cartService: CartService,
    protected wishListService: WishListService,

    public siteService: SiteService
  ) {
    this.login = new EventEmitter<void>();
    this.register = new EventEmitter<void>();
    this.currencyList = currencies;
  }

  ngOnInit(): void {
    if (this.router.url.includes('/search')) {
      const { query } = this.activatedRoute.snapshot.queryParams;
      this.searchText = query.replace('+', ' ');
    }

    // Set & Update nbItemsInCart
    this.cartProducts.subscribe((products) => {
      this.nbItemsInCart = 0;

      for (const product of products) {
        this.nbItemsInCart += product.stock;
      }
    });

    // Set & Update nbItemsInWishList
    this.wishList.subscribe((wishlist: any) => {
      this.nbItemsInWishList = wishlist.items.length;
    });
  }

  onDashboardSearch(): void {
    this.router.navigate(['/search'], {
      queryParams: {
        query: this.searchText.replace(' ', '+'),
      },
    });
  }

  onCurrencyChange() {
    this.cookieService.set('currency', this.siteService.currency);

    document.dispatchEvent(
      new CustomEvent('currencyChange', {
        bubbles: true,
        cancelable: true,
      })
    );
  }

  onRegisterClick(): void {
    this.register.emit();
  }

  onLoginClick(): void {
    this.login.emit();
  }

  onLogoutClick() {
    const { id, token, tokenType } = this.siteService.user;
    const params: any = {
      memberId: id,
      token: token,
      login: tokenType,
    };

    this.accountService.logout(params).subscribe(() => {
      this.nbItemsInCart = 0;
      this.cartService.onAddItem = [];

      this.cartService.clear();
      this.siteService.logout();
      this.itemsViewedService.clear();
      this.itemsViewedService.get();
      this.wishListService.clear();
    });
  }

  @HostListener('document:hideLogins')
  onHideLogins() {
    this.hideLogin = true;
  }

  @HostListener('document:showLogins')
  onShowLogins() {
    this.hideLogin = false;
  }
}
