<ng-container *ngIf="stripbar">
  <div>
    <a
      *ngIf="stripbar.href"
      [href]="stripbar.href"
      class="d-block animate__animated"
    >
      <ng-container [ngTemplateOutlet]="stripeMessage"></ng-container>
    </a>

    <a
      *ngIf="stripbar.routerLink"
      [routerLink]="[stripbar.routerLink]"
      class="d-block animate__animated"
    >
      <ng-container [ngTemplateOutlet]="stripeMessage"></ng-container>
    </a>

    <a
      *ngIf="stripbar.href === undefined && stripbar.routerLink === undefined"
      class="animate__animated"
      href="javascript:void(0)"
    >
      <ng-container [ngTemplateOutlet]="stripeMessage"></ng-container>
    </a>
  </div>
</ng-container>

<ng-template #stripeMessage>
  <p class="font-weight-normal" [innerHTML]="stripbar.message"></p>
</ng-template>
