import { OnInit, Input, Directive } from '@angular/core';

@Directive()
export class AdAreaComponent implements OnInit {
  @Input() ads: any[];
  @Input() isEnabled: boolean;

  constructor() {}

  ngOnInit() {}
}
