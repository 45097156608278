import { Injectable } from '@angular/core';
import { CourierService } from '../courier/courier.service';

@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  constructor(protected courierService: CourierService) {}

  /**
   * This function returns the shipping cost
   * for non-courier items based on shipping/Group obj
   */
  getNonCourierShippingCost(countryCode: string, item: any) {
    let shippingGroup = this.getShippingGroupForItem(item, countryCode);

    return {
      shippingFee: shippingGroup?.shippingFee ?? 0,
      addFee: shippingGroup?.addFee ?? 0,
    };
  }

  getShippingGroupForItem(item: any, country: string) {
    //NOTE: this currentItem can be this.infos.product or current cart item (update qty request or re-added)
    let shippingGroup = null;
    if (item?.shippingGroup?.countryCode) {
      shippingGroup = item?.shippingGroup;
    } else {
      shippingGroup = item?.shippingGroup?.find(
        (x) => x.countryCode === country
      );
    }

    return shippingGroup;
  }

  validateNonCourierShippingGroup(
    currentItem: any,
    cartItems: any,
    country: string
  ) {
    let isShippingGroupSame = false;
    let isItemExists = false;
    let isQtyIncrease = false;

    if (cartItems?.length > 0) {
      //get shippingGroup id from this.infos.product.shippingGroup of current countryCode
      //and compare with all items in cartItems
      //NOTE: this currentItem can be this.infos.product or current cart item (update qty request or re-added)
      let shippingGroup = this.getShippingGroupForItem(currentItem, country);
      let shippingGroupId = shippingGroup.id;

      //is currentItem fullId same as any of the cartItems then its qty update - so return fale
      if (cartItems?.length > 0) {
        cartItems.forEach((item) => {
          if (item.fullId === currentItem.fullId) isItemExists = true;
        });
      }

      //check if shippingGroupId exists for any of cartItems with shippingGroup array of object {id, countryCode, shippingfee}
      if (shippingGroupId && !isItemExists) {
        cartItems.forEach((item) => {
          if (
            item?.shippingGroup?.id === shippingGroupId &&
            item?.shippingGroup?.countryCode === country
          )
            isShippingGroupSame = true;
        });
      }

      //explicit condtion
      if (isItemExists) {
        //check currentItem shippingGroupId is same as any of the item in cartItems
        cartItems.forEach((item) => {
          if (
            item?.shippingGroup?.id === shippingGroupId &&
            item?.shippingGroup?.countryCode === country
          ) {
            isShippingGroupSame = true;
            isQtyIncrease = true;
          }
        });
      }

      return {
        isShippingGroupSame: isShippingGroupSame,
        isQtyIncrease: isQtyIncrease,
      };
    } else {
      return {
        isShippingGroupSame: false,
        isQtyIncrease: false,
      };
    }
  }
}
