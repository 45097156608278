import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from '@angular/core';
import { SiteService } from '../../ecommerce/services/site/site.service';

declare let $: any;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, AfterViewInit {
  readonly INTERVAL: number;

  @ViewChild('carousel', { static: true })
  carouselEl: ElementRef<HTMLDivElement>;
  @ViewChildren('img') imgEls: QueryList<ElementRef<HTMLImageElement>>;
  @Input() id: string;
  @Input() images: any[];
  @Input() width: string;
  @Input() height: string;
  @Input() shadows: boolean;

  styles: any = {};
  isFullWidth: boolean = false;

  constructor(private siteService: SiteService) {
    this.INTERVAL = 4000;
  }

  ngOnInit() {
    if (this.width) {
      this.styles.width = this.width;
    }

    if (this.height) {
      this.styles.height = this.height;
    }

    if (this.styles.width === undefined && this.styles.height === undefined) {
      this.isFullWidth = true;
    }

    if (this.shadows !== false) {
      this.shadows = true;
    }
  }

  ngAfterViewInit() {
    if (this.siteService.IS_BROWSER) {
      $(`#${this.id}`).carousel('cycle');
    }
  }

  onImageLoad(index: number) {
    const image = this.images[index];

    image.loaded = true;

    if (image.squareLinks) {
      const imageEl = this.imgEls.toArray()[index].nativeElement;
      const { squareLinks } = image;
      const { width, height } =
        this.carouselEl.nativeElement.getBoundingClientRect();
      const { naturalWidth, naturalHeight } = imageEl;
      const widthEv = 1 + (width - naturalWidth) / naturalWidth;
      const heightEv = 1 + (height - naturalHeight) / naturalHeight;

      for (const square of squareLinks) {
        square.x = square.x * widthEv;
        square.y = square.y * heightEv;
        square.width = square.width * widthEv;
        square.height = square.height * heightEv;
        square.style = {
          top: `${square.y}px`,
          left: `${square.x}px`,
          width: `${square.width}px`,
          height: `${square.height}px`,
        };
      }
    }
  }
}
