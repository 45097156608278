<div id="this">
  <div (click)="onMenuClick()">
    <span class="fa fa-bars"></span>
  </div>

  <div [routerLink]="['/']">
    <span class="fa fa-home"></span>
  </div>

  <div (click)="onSearchClick()">
    <span class="fa fa-search"></span>
  </div>

  <div
    *ngIf="!siteService.user"
    [routerLink]="['/login']"
    [queryParams]="{redirectTo: router.url}"
  >
    <span class="fa fa-heart"></span>
  </div>

  <div *ngIf="siteService.user" [routerLink]="['/wishlist']">
    <span class="fa fa-heart"></span>

    <span
      *ngIf="nbItemsInWishList"
      class="fw-normal badge badge-pill badge-danger p-1 text-white"
    >
      {{ nbItemsInWishList }}
    </span>
  </div>

  <div [routerLink]="['/cart']">
    <span class="fa fa-shopping-cart fa-lg"></span>

    <span
      *ngIf="nbItemsInCart && nbItemsInCart > 0"
      class="fw-normal badge badge-pill badge-success p-1 text-white"
    >
      {{ nbItemsInCart }}
    </span>
  </div>

  <div *ngIf="showFilters" (click)="onFiltersClick()">
    <span class="fa fa-tasks"></span>
  </div>
</div>
