<ng-template #cookieModalTemplate>
  <div class="modal-body text-center">
    <p>
      We use cookies to improve your experience on our portal and provide you
      with relevant and personalised services. By continuing on
      <span class="font-title">qubyk marketplace</span> you agree to our use of
      cookies.
    </p>

    <button (click)="onOKClick()" class="btn btn-dark">OK</button>
  </div>
</ng-template>
