<div class="section">
  <div class="d-flex align-items-baseline pb-4">
    <div [class.flex-grow-1]="link === undefined">
      <h3 [class]="titleClass" class="section-title">{{title}}</h3>
    </div>

    <div *ngIf="link">
      <a [routerLink]="link.routerLink">
        <h4>{{link.name}}</h4>
      </a>
    </div>
  </div>

  <ng-content></ng-content>
</div>
