import { combineReducers } from 'redux';
import {
  IProductsState,
  PRODUCTS_INITIAL_STATE,
  ICategoriesState,
  CATEGORIES_INITIAL_STATE,
  IBrandsState,
  BRANDS_INITIAL_STATE,
  ICarouselState,
  CAROUSEL_INITIAL_STATE,
  ICartState,
  CART_INITIAL_STATE,
  IWishListState,
  WISH_LIST_INITIAL_STATE,
  IOrdersState,
  ORDERS_INITIAL_STATE,
  IItemsViewedState,
  ITEMS_VIEWED_INITIAL_STATE,
  IMemberState,
  MEMBER_INITIAL_STATE,
  IQubykQuickState,
  QUBYK_QUICK_INITIAL_STATE,
  IProductGridState,
  PRODUCT_GRID_STATE,
} from './ecommerce/store';
import { categoriesReducer } from './ecommerce/store/categories.store';
import { productsReducer } from './ecommerce/store/products.store';
import { brandsReducer } from './ecommerce/store/brands.store';
import { carouselReducer } from './ecommerce/store/carousel.store';
import { cartReducer } from './ecommerce/store/cart.store';
import { wishListReducer } from './ecommerce/store/wish-list.store';
import { ordersReducer } from './ecommerce/store/orders.store';
import { itemsViewedReducer } from './ecommerce/store/items-viewed.store';
import { memberReducer } from './ecommerce/store/member.store';
import { qubykQuickReducer } from './ecommerce/store/qubykQuick.store';
import { productGridReducer } from './ecommerce/store/product-grid.store';
import {
  IProductMetaState,
  productMetaReducer,
  PRODUCT_META_INITIAL_STATE,
} from './ecommerce/store/product-meta.store';

export interface IAppState {
  products: IProductsState;
  productMeta: IProductMetaState;
  categories: ICategoriesState;
  brands: IBrandsState;
  carousel: ICarouselState;
  cart: ICartState;
  wishList: IWishListState;
  orders: IOrdersState;
  itemsViewed: IItemsViewedState;
  member: IMemberState;
  qubykQuick: IQubykQuickState;
  productGrid: IProductGridState;
}

export const INITIAL_STATE: IAppState = {
  products: PRODUCTS_INITIAL_STATE,
  productMeta: PRODUCT_META_INITIAL_STATE,
  categories: CATEGORIES_INITIAL_STATE,
  brands: BRANDS_INITIAL_STATE,
  carousel: CAROUSEL_INITIAL_STATE,
  cart: CART_INITIAL_STATE,
  wishList: WISH_LIST_INITIAL_STATE,
  orders: ORDERS_INITIAL_STATE,
  itemsViewed: ITEMS_VIEWED_INITIAL_STATE,
  member: MEMBER_INITIAL_STATE,
  qubykQuick: QUBYK_QUICK_INITIAL_STATE,
  productGrid: PRODUCT_GRID_STATE,
};

export const rootReducer = combineReducers({
  products: productsReducer,
  productMeta: productMetaReducer,
  categories: categoriesReducer,
  brands: brandsReducer,
  carousel: carouselReducer,
  cart: cartReducer,
  wishList: wishListReducer,
  orders: ordersReducer,
  itemsViewed: itemsViewedReducer,
  member: memberReducer,
  qubykQuick: qubykQuickReducer,
  productGrid: productGridReducer,
});
