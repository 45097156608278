import { tassign } from 'tassign';

import * as categoriesActions from './categories.actions';

export interface ICategoriesState {
  categories: string[];
}

export const CATEGORIES_INITIAL_STATE: ICategoriesState = {
  categories: [],
};

class CategoriesActions {
  constructor(private state, private action) {}

  getCategories() {
    return tassign(this.state, {
      categories: this.state.categories,
    });
  }

  loadCategories() {
    return tassign(this.state, {
      categories: this.action.categories.categories,
    });
  }
}

export function categoriesReducer(
  state: ICategoriesState = CATEGORIES_INITIAL_STATE,
  action
): ICategoriesState {
  const prodActions = new CategoriesActions(state, action);

  switch (action.type) {
    case categoriesActions.GET_CATEGORIES:
      return prodActions.getCategories();
    case categoriesActions.GET_CATEGORIES_SUCCESS:
      return prodActions.loadCategories();
  }
  return state;
}
