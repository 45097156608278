import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
  HostListener, Inject,
} from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'categories-area-desktop',
  templateUrl: './categories-area-desktop.component.html',
  styleUrls: ['./categories-area-desktop.component.scss'],
})
export class CategoriesAreaDesktopComponent implements OnInit, AfterViewInit {
  @Input() textAlign: string;
  @Input() articles: any[];
  @Input() width: number;
  @Input() nbShowedBoxes: number;

  articleCardStyle: any;

  constructor(@Inject(WINDOW) private window: Window, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.setConfiguration();
  }

  setConfiguration() {
    if (this.width === undefined) {
      this.width = 1400;
    }

    if (this.nbShowedBoxes === undefined) {
      const width = `${100 / 6}%`;

      this.articleCardStyle = {
        flex: `0 0 ${width}`,
        'max-width': width,
      };
    } else {
      const width = `${100 / this.nbShowedBoxes}%`;

      this.articleCardStyle = {
        flex: `0 0 ${width}`,
        'max-width': width,
      };
    }
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setConfiguration();
  }
}
