import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NavbarComponent } from './navbar.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { ItemsViewedService } from '../../ecommerce/services/items-viewed/items-viewed.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { WishListService } from '../../ecommerce/services/wish-list/wish-list.service';

@Component({
  selector: 'app-navbar-desktop',
  templateUrl: './navbar-desktop.template.html',
  styleUrls: ['./navbar-desktop.style.scss'],
})
export class NavbarDesktopComponent extends NavbarComponent {
  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected itemsViewedService: ItemsViewedService,
    protected cartService: CartService,
    protected wishListService: WishListService,

    public siteService: SiteService
  ) {
    super(
      router,
      activatedRoute,
      cookieService,
      accountService,
      itemsViewedService,
      cartService,
      wishListService,
      siteService
    );
  }

  onWishListClick() {
    const event = new CustomEvent('openLogin', {
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(event);
  }
}
