import { tassign } from 'tassign';
import * as brandsActions from './brands.actions';

export interface IBrandsState {
  brands: string[];
}

export const BRANDS_INITIAL_STATE: IBrandsState = {
  brands: [],
};

class BrandsActions {
  constructor(private state, private action) {}

  getBrands() {
    return tassign(this.state, {
      brands: this.action.brands,
    });
  }

  clear() {
    return tassign(this.state, {
      brands: [],
    });
  }
}

export function brandsReducer(
  state: IBrandsState = BRANDS_INITIAL_STATE,
  action
): IBrandsState {
  const brandActions = new BrandsActions(state, action);

  switch (action.type) {
    case brandsActions.GET_BRANDS_SUCCESS:
      return brandActions.getBrands();
    case brandsActions.CLEAR_BRANDS:
      return brandActions.clear();
  }

  return state;
}
