import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ICartItem } from '../../models/carts/ICartItem';
import { SiteService } from '../../ecommerce/services/site/site.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { Select } from '@angular-redux2/store';
import { Observable, Subscription } from 'rxjs';
import { ICartState } from '../../ecommerce/store/cart.store';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { CourierService } from '../../ecommerce/services/courier/courier.service';
import { ShippingService } from '../../ecommerce/services/shipping/shipping.service';
import { StripeService } from '../../ecommerce/services/stripe/stripe.service';
import { OrdersService } from '../../ecommerce/services/orders/orders.service';
import { CouponService } from '../../ecommerce/services/coupon/coupon.service';
import { Router } from '@angular/router';

@Directive()
export class CartItemsComponent implements OnInit {
  @ViewChild('qtyInput') qtyInput: ElementRef;
  @Input() cartItems: ICartItem[];
  @Input() totalShippingCost: number;
  @Input() isShipFeeLoaded: boolean;
  @Input() subTotal: number;
  @Input() selectedCurrency: string = 'AUD';
  @Input() couponPercent: number;
  @Input() couponDiscount: number = 0;
  cartTotal: number = 0;
  tax: number = 0;
  shippingCountry: string;
  couponCode: string;

  @Select((s) => s.cart) cart: Observable<ICartState>;
  carItemsSubscription: Subscription;

  onImageLoad: boolean;
  qtyForm: FormGroup;
  initialQuantity: number;

  constructor(
    protected router: Router,
    protected siteService: SiteService,
    protected cartService: CartService,
    protected cookieService: CookieService,
    protected courierService: CourierService,
    protected shippingService: ShippingService,
    protected stripeService: StripeService,
    protected ordersService: OrdersService,
    protected couponService: CouponService
  ) {}

  ngOnInit() {
    this.qtyForm = new FormGroup({
      qty: new FormControl(1, [Validators.min(1), Validators.required]),
    });

    this.onImageLoad = true;
    this.initialQuantity = 1;

    //pre-load all the cartItems
    this.cart.subscribe(async (cart: ICartState) => {
      const {
        items,
        total,
        totalShippingCost,
        tax,
        shippingCountry,
        couponCode,
      } = cart;
      this.subTotal = total;
      this.totalShippingCost = totalShippingCost;
      this.cartItems = items;
      this.tax = tax;
      this.shippingCountry = shippingCountry;
      this.cartTotal = this.getTotal();
      this.couponCode = couponCode;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.cart.subscribe((cart: ICartState) => {
      const {
        total,
        totalShippingCost,
        tax,
        couponDiscount,
        couponDiscountAmount,
      } = cart;

      this.subTotal = total;
      this.totalShippingCost = totalShippingCost;
      this.couponDiscount = couponDiscountAmount ?? 0;
      this.cartTotal = this.getTotal();
      this.tax = tax;
    });
  }

  getTotal() {
    let isCouponExists = this.couponService.isExists();

    return (
      this.subTotal -
      (isCouponExists
        ? isNaN(this.couponDiscount)
          ? 0
          : this.couponDiscount
        : 0) +
      this.totalShippingCost +
      this.tax
    );
  }

  get taxRate(): number {
    const courier = JSON.parse(this.cookieService.get('courier'));
    return this.siteService.taxRate(courier.country);
  }
  onImageLoaded() {
    if (this.onImageLoad) {
      this.onImageLoad = false;
    }
  }

  get qty(): number {
    return Number(this.qtyForm.get('qty').value);
  }

  get onChange(): boolean {
    // const { fullId } = this.product;

    // if (
    //   this.cartService.onUpdateItem[fullId] ||
    //   this.cartService.onDeleteItem[fullId]
    // ) {
    //return true;
    // }

    return false;
  }

  async onQtyBlur(item) {
    await this.cartService.resetCartStatus(true);

    let shippingCostForItem: number = 0;
    let updateNonCourierParams = null;
    //make http call to get courier rates if item has courier support
    if (item.hasCourier) {
      let requestObj = this.getCourierRequestObject(item);
      shippingCostForItem = await this.courierService.getCourierRates(
        this.siteService.domain,
        requestObj
      );
    } else {
      //Get nonCourier shipping rates
      let { shippingFee, addFee } =
        this.shippingService.getNonCourierShippingCost(
          this.shippingCountry,
          item
        );

      updateNonCourierParams = this.cartService.getNonCourierCartParams(
        shippingFee,
        addFee,
        this.shippingCountry,
        item,
        this.cartItems
      );

      //qty is increased API level
      updateNonCourierParams.qty = updateNonCourierParams.qty - 1;
    }

    if (shippingCostForItem >= 0) {
      const params: any = {
        cartId: this.siteService.cartId,
        fullId: item.fullId,
        prodId: item.id,
        userId: this.siteService?.user?.id ?? this.siteService?.userId,
        siteId: this.siteService.domain,
        currency: this.siteService.currency,
        qty: updateNonCourierParams?.qty ?? item.qty,
        shippingCost:
          updateNonCourierParams?.shippingCost ?? shippingCostForItem,
        country: this.shippingCountry,
      };

      if (item.isVariation) {
        params.isVariation = true;
        params.variationSku = item.variationSku;
        params.size = item.size;
      }

      await this.cartService.updateItem(params);
      await this.updateOrder();
    }

    //Check if couponParams cookie exits
    await this.checkCouponAndEnableBuynowLink();
  }

  onQtyChange(item) {}

  async onDeleteClick(item) {
    await this.cartService.resetCartStatus(true);

    const courier = JSON.parse(this.cookieService.get('courier'));
    const params: any = {
      prodId: item.id,
      fullId: item.fullId,
      cartId: this.siteService.cartId,
      userId: this.siteService?.user?.id ?? this.siteService?.userId,
      currency: this.siteService.currency,
      qty: item.qty,
      country: courier.country,
    };

    if (item.isVariation) {
      params.isVariation = true;
      params.variationSku = item.variationSku;
      params.size = item.size;
    }

    await this.cartService.deleteItem(params);

    //close buynow popup if items are 0
    // remove item from this.cartItems
    this.cartItems = this.cartItems.filter((cartItem) => {
      return cartItem.fullId !== item.fullId;
    });

    if (this.cartItems.length == 0) {
      this.closeBuyNowPopUp();

      //if isMobile then redirect to homepage
      if (this.siteService.isMobileView) {
        this.router.navigate(['/']);
      }
    } else {
      //Update coupon if any preset
      await this.updateOrder();
      await this.checkCouponAndEnableBuynowLink();
    }
  }

  getCourierRequestObject(item: any) {
    const courier = JSON.parse(this.cookieService.get('courier'));

    return {
      prodId: item.id,
      isQubyk: item.isQubyk,
      isVariation: item.isVariation,
      variationSku: item.variationSku,
      weight: item?.weight ?? null,
      weightUnit: item?.weightUnit ?? null,
      qty: item.qty,
      currency: this.siteService.currency,
      country: courier.country,
      state: courier.state,
      postcode: courier.postcode,
    };
  }

  async updateOrder() {
    let courier = JSON.parse(this.cookieService.get('courier'));

    var params = {
      id: this.siteService?.order?.id,
      cartId: this.siteService.cartId,
      userId: this.siteService?.user?.id ?? this.siteService?.userId,
      siteId: this.siteService.domain,
      ipAddress: this.siteService.ip,
      shippingAddress: {
        country: courier.country,
        state: courier.state,
        postcode: courier.postcode,
      },
      billingAddress: {
        country: courier.country,
        state: courier.state,
        postcode: courier.postcode,
      },
    };

    await this.ordersService.updateOrderAsync(params);
  }

  closeBuyNowPopUp() {
    const event = new CustomEvent('buynowPopupClose', {
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(event);
  }

  async checkCouponAndEnableBuynowLink() {
    let couponParams = this.cookieService.get('couponParams');
    let couponResp = null;
    if (couponParams) {
      couponResp = await this.cartService.applyCoupon(JSON.parse(couponParams));

      if (couponResp) {
        this.couponPercent = couponResp.couponDiscount;
        this.couponDiscount = couponResp.couponDiscountAmount;
        this.couponCode = couponResp.couponCode;
        this.subTotal = couponResp.total;
        this.tax = couponResp.tax;
        this.cartTotal =
          couponResp.total -
          couponResp.couponDiscountAmount +
          couponResp.totalShippingCost +
          couponResp.tax;
      }
    } else {
      this.couponCode = null;
    }

    await this.stripeService.enableStripLink(
      this.cartItems,
      this.subTotal,
      this.totalShippingCost,
      this.tax,
      this.couponCode ?? null
    );
  }
}
