<div id="this">
  <ng-container *ngIf="view === 'slider'">
    <app-carousel-items
      *ngIf="categories"
      [width]="width"
      [nbItemsDisplayed]="nbShowedBoxes"
      [hideControls]="true"
      [showScrollBar]="true"
      [items]="categories"
      [$item]="itemTemplate"
    ></app-carousel-items>
  </ng-container>

  <ng-container *ngIf="view === 'vertical'">
    <ng-container *ngFor="let category of categories">
      <ng-container
        *ngTemplateOutlet="categoryTemplate; context: { category: category }"
      >
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #prevTemplate>
    <button class="carousel-btn carousel-btn-left">
      <i class="fa fa-angle-left fa-2x"></i>
    </button>
  </ng-template>

  <ng-template #nextTemplate>
    <button class="carousel-btn carousel-btn-right">
      <i class="fa fa-angle-right fa-2x"></i>
    </button>
  </ng-template>

  <ng-template let-category #itemTemplate>
    <ng-container
      *ngTemplateOutlet="categoryTemplate; context: { category: category }"
    ></ng-container>
  </ng-template>

  <ng-template #categoryTemplate let-category="category">
    <div *ngIf="category.isEnabled" [ngStyle]="imageStyle" class="this-item">
      <a *ngIf="category.routerLink" [routerLink]="category.routerLink">
        <img
          [src]="category.imageSrc"
          [alt]="category.alt"
          class="d-block w-100"
        />
      </a>

      <a *ngIf="category.href" [href]="category.href">
        <img
          [src]="category.imageSrc"
          [alt]="category.alt"
          class="d-block w-100"
        />
      </a>

      <h6 style="font-weight: bold">{{ category.label }}</h6>
    </div>
  </ng-template>
</div>
