import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private ngxCookieService: NgxCookieService) {}

  get(name: string): string {
    return this.ngxCookieService.get(name);
  }

  set(name: string, value: string) {
    this.ngxCookieService.set(name, value, undefined, '/');
  }

  delete(name: string) {
    this.ngxCookieService.delete(name, '/');
  }
}
