import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { SiteService } from '../../ecommerce/services/site/site.service';
import { BuynowComponent } from './buynow.component';
import countries from '../../../assets/countries.json';
import { CourierService } from '../../ecommerce/services/courier/courier.service';
import { StripeService } from '../../ecommerce/services/stripe/stripe.service';
import { CouponService } from '../../ecommerce/services/coupon/coupon.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { OrdersService } from '../../ecommerce/services/orders/orders.service';
import { ShippingService } from '../../ecommerce/services/shipping/shipping.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buynow-modal-desktop',
  templateUrl: './buynow-modal-desktop.component.html',
  styleUrls: ['./buynow-modal-desktop.component.scss'],
})
export class BuynowModalDesktopComponent extends BuynowComponent {
  constructor(
    protected router: Router,
    public siteService: SiteService,
    protected cookieService: CookieService,
    private modalService: BsModalService,
    protected courierService: CourierService,
    protected stripeService: StripeService,
    protected couponService: CouponService,
    protected cartService: CartService,
    protected orderService: OrdersService,
    protected shippingService: ShippingService
  ) {
    super(
      router,
      siteService,
      cookieService,
      courierService,
      stripeService,
      couponService,
      cartService,
      orderService,
      shippingService
    );
  }

  open(data: any) {
    this.showCheckoutSection = false;
    this.onCourier = false;
    this.courierRate = null;
    this.fullCountriesList = [];
    // this.nonCourierShippingFee = 0;

    //This contains shippingInfo & Product data
    this.infos = data;
    this.showModal = false;

    setTimeout(() => {
      this.showModal = true;

      setTimeout(() => {
        this.modalRef = this.modalService.show(
          this.buynowModalTemplate as any,
          {
            class: 'app-modal-width-650px',
          }
        );
        this.modalService.onHide.subscribe(() => {
          this.onHide();

          setTimeout(() => {
            document.dispatchEvent(
              new CustomEvent('modalClosed', {
                bubbles: true,
                cancelable: true,
              })
            );
          }, 100);
        });
      });
    }, 100);

    //Load coupon form here
    const getCouponFormGroup = this.couponService.getCouponFormGroup(
      this.coupon
    );
    this.couponForm = new FormGroup({
      coupon: getCouponFormGroup(),
    });

    //Courier form starts here
    this.hasCourier = this.courierService.hasCourier(this.infos?.courier);

    if (this.hasCourier) {
      const getCourierFormGroup = this.courierService.getCourierFormGroup(
        this.infos?.courier
      );
      this.courierForm = new FormGroup({
        courier: getCourierFormGroup(),
      });

      this.fullCountriesList = countries;
      this.showCourierOpts = true;
    } else {
      //These are for non-qubyk items or doesn't have support for courier options
      //we can match from current
      let courier = {
        country: true,
        state: false,
        postcode: false,
      };

      const getCourierFormGroup = this.courierService.getCourierFormGroup(
        courier,
        false
      );
      this.courierForm = new FormGroup({
        courier: getCourierFormGroup(),
      });

      this.infos.shipping.forEach((element) => {
        //add each element with country name from countries
        let country = countries.find((x) => x.code === element.countryCode);
        //Add country to fullCountriesList if country found
        if (country) this.fullCountriesList.push(country);
      });

      this.showCourierOpts = true;
    }
  }

  onHide() {
    this.fullCountriesList = [];
    super.onHide();
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
