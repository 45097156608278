import { tassign } from 'tassign';
import * as memberActions from './member.action';

export interface IMemberState {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  address2: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export const MEMBER_INITIAL_STATE: IMemberState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  address2: '',
  suburb: '',
  state: '',
  postcode: '',
  country: '',
};

class MemberActions {
  constructor(private state, private action) {}

  getMember() {
    return tassign(this.state, {
      ...this.action.member,
    });
  }

  updateMember() {
    return tassign(this.state, {
      ...this.action.member,
    });
  }
}

export function memberReducer(
  state: IMemberState = MEMBER_INITIAL_STATE,
  action
): IMemberState {
  const actions = new MemberActions(state, action);

  switch (action.type) {
    case memberActions.GET_MEMBER_SUCCESS:
      return actions.getMember();
    case memberActions.UPDATE_MEMBER_SUCCESS:
      return actions.updateMember();
  }

  return state;
}
