import {
  Component,
  ViewEncapsulation,
  ViewChild,
  HostListener,
} from '@angular/core';

import { LayoutComponent } from './layout.component';
import { LoginModalDesktopComponent } from '../components/login/login-modal-desktop.component';
import { RegisterModalDesktopComponent } from '../components/register/register-modal-desktop.component';
import { BuynowModalDesktopComponent } from '../components/buynow/buynow-modal-desktop.component';

@Component({
  selector: 'app-layout-desktop',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './layout-desktop.template.html',
  styleUrls: ['./layout-desktop.style.scss'],
})
export class LayoutDesktopComponent extends LayoutComponent {
  @ViewChild(LoginModalDesktopComponent)
  loginModalDesktopComponent: LoginModalDesktopComponent;

  @ViewChild(RegisterModalDesktopComponent)
  registerModalDesktopComponent: RegisterModalDesktopComponent;

  @ViewChild(BuynowModalDesktopComponent)
  buynowModalDesktopComponent: BuynowModalDesktopComponent;

  show: boolean;

  ngOnInit() {
    super.ngOnInit();

    if (this.siteService.IS_BROWSER) {
      setTimeout(() => (this.show = true), 250);
    } else {
      this.show = true;
    }
  }

  onLoginClick(): void {
    this.loginModalDesktopComponent.open();
  }

  onRegisterClick(): void {
    this.registerModalDesktopComponent.open();
  }

  onLoginSignup() {
    setTimeout(() => this.onRegisterClick(), 500);
  }

  onRegisterSignin() {
    setTimeout(() => this.onLoginClick(), 500);
  }

  onBuynowClick(data: any) {
    this.buynowModalDesktopComponent.open(data);
  }

  onBuynowCloseClick() {
    this.buynowModalDesktopComponent.onHide();
  }

  @HostListener('document:openLogin')
  onOpenLogin() {
    setTimeout(() => {
      this.onLoginClick();
    }, 500);
  }

  @HostListener('document:buynowPopup', ['$event'])
  onBuynowPopup(event: any) {
    setTimeout(() => {
      this.onBuynowClick(event.detail);
    }, 500);
  }

  @HostListener('document:buynowPopupClose', ['$event'])
  onBuynowPopupClose() {
    setTimeout(() => {
      this.onBuynowCloseClick();
    }, 500);
  }
}
