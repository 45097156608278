import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SeoService } from '../seo/seo.service';
import { environment } from '../../../../src/environments/environment';
import { Observable } from 'rxjs';
import { SiteService } from '../services/site/site.service';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  readonly API_PREFIX: string;
  constructor(
    private http: HttpClient,
    private seoService: SeoService,
    private siteService: SiteService
  ) {
    this.API_PREFIX = `${environment.siteUrl}/site-content/V2/blog/${this.siteService.domain}`;
  }

  async getHtmlContent(blogName, siteId): Promise<any> {
    return new Promise<any>(async (resolve) => {
      let templatePath = this.getHtmlTemplate(blogName, siteId);

      this.http
        .get(templatePath, {
          responseType: 'text',
        })
        .subscribe(
          (htmlContent: string) => {
            resolve(htmlContent);
          },
          (error) => {
            resolve(null);
            console.error('Failed to load local HTML file:', error);
          }
        );
    });
  }

  getHtmlTemplate(blogName, siteId) {
    if (blogName == 'black-friday') {
      return `blog/templates/${siteId}/black-friday.html`;
    }

    return `blog/templates/${siteId}/index.html`;
  }

  setSeoTags(blogName, siteId) {
    if (blogName == 'black-friday') {
      if (siteId == 'AU') {
        this.seoService.setH1('Great Black Friday Deals on Home & Living');
        this.seoService.setTitle(
          'Great Black Friday Deals on Home & Living: Transform Your Space with Sizable Savings'
        );
        this.seoService.setMetaTitle(
          'Great Black Friday Deals on Home & Living: Transform Your Space with Sizable Savings'
        );
        this.seoService.setMetaDescription(
          'Explore amazing Black Friday deals on home and living products. Transform your space with sizable savings.'
        );
        this.seoService.setMetaKeywords(
          'Black Friday, Deals, Home, Living, Savings'
        );
      } else if (siteId == 'UK') {
        this.seoService.setH1(
          'Unleash Your Beauty Potential with Black Friday Deals'
        );
        this.seoService.setTitle(
          'Great Black Friday Deals on Beauty: Unlock Your Stunning Transformation'
        );
        this.seoService.setMetaDescription(
          'Discover incredible Black Friday deals on beauty products. Unlock your stunning transformation with amazing savings.'
        );
        this.seoService.setMetaKeywords(
          'Black Friday, Deals, Beauty, Transformation'
        );
      } else {
        this.seoService.setH1(
          'Get Ready to Save Big: Black Friday Deals on Beauty Products From Qubyk Marketplace'
        );
        this.seoService.setTitle(
          'Get Ready to Save Big: Black Friday Deals on Beauty Products in the USA!'
        );
        this.seoService.setMetaDescription(
          'Explore amazing Black Friday deals on beauty products in the USA. Save big on your favorite beauty items this holiday season.'
        );
        this.seoService.setMetaKeywords(
          'Black Friday, beauty products, deals, USA, savings'
        );
      }
    }
  }

  getAllBlogs(pageNo: number) {
    return this.http.get(`${this.API_PREFIX}/all?pageNo=${pageNo}`);
  }

  getSingleBlog(search: string): Observable<any> {
    return this.http.get(`${this.API_PREFIX}/?href=${search}`);
  }

  getFeaturedBlogs(): Observable<any> {
    return this.http.get(`${this.API_PREFIX}/featured-posts`);
  }

  getPostOftheMonthBlog(): Observable<any> {
    return this.http.get(`${this.API_PREFIX}/post-of-the-month`);
  }

  getDetailPosts(blogName: string): Observable<any> {
    return this.http.get(`${this.API_PREFIX}?href=${blogName}`);
  }
}
