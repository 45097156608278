import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { SocialLoginService } from '../../ecommerce/services/social-login/social-login.service';

@Component({
  selector: 'app-instagram-login-form',
  templateUrl: './instagram-login-form.component.html',
  styleUrls: ['./instagram-login-form.component.scss'],
})
export class InstagramLoginFormComponent implements OnInit {
  @Input() onInstagramLogin: string;
  @Input() register: boolean;
  @Input() token: string;
  @Input() userId: string;
  @Output() onInstagramLoginChange: EventEmitter<string>;

  instagramLoginForm: FormGroup;

  onLogin: boolean;

  get f(): { [key: string]: AbstractControl } {
    return this.instagramLoginForm.controls;
  }

  constructor(
    private siteService: SiteService,
    private socialLoginService: SocialLoginService
  ) {
    this.onInstagramLoginChange = new EventEmitter<string>();
  }

  ngOnInit() {
    this.instagramLoginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      ]),
    });

    this.onLogin = false;
  }

  onLoginClick() {
    const params = {
      accessToken: this.token,
      userId: this.userId,
      email: this.f.email.value,
      siteId: this.siteService.domain,
    };

    this.onLogin = true;

    this.socialLoginService.validateInstagramUser(params).subscribe(
      (res: any) => {
        const { data } = res;
        const user = {
          id: data.memberId,
          token: data.token,
          tokenType: 'instagram',
        };

        this.socialLoginService.login(user);
      },
      () => {
        this.onInstagramLoginChange.emit('error');
      }
    );
  }
}
