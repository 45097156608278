<div *ngIf="height" [style.height]="height" id="this">
  <div #menuEl [style.height]="height" [class]="paneAnimation" id="this-menu">
    <div #titleEl [routerLink]="['/']" (click)="close()" id="this-title">
      <a
        onclick="this.style.font='50 30px/1.3 \'Caveat\', Helvetica, sans-serif;'"
      >
        <span
          style="
            font: 100 35px/1.3 'Caveat', Helvetica, sans-serif;
            color: #000;
            font-weight: 100;
            padding-left: 10px;
            text-decoration: none;
          "
        >
          qubyk
        </span>

        <span>{{ siteService.domainExtension }}</span>
      </a>
    </div>

    <div
      *ngIf="categoriesHeight"
      [style.height]="categoriesHeight"
      id="this-categories"
    >
      <ul>
        <ng-container
          *ngFor="let category of categories; let categoryIndex = index"
        >
          <li
            *ngIf="
              category.routerLink !== '/' && category.children === undefined
            "
            class="this-category-item"
          >
            <span (click)="onCategoryChange(category.routerLink)">
              {{ category.name }}
            </span>
          </li>

          <li
            *ngIf="category.routerLink !== '/' && category.children"
            class="this-category-item"
          >
            <span (click)="onCategoryClick(categoryIndex)">
              {{ category.name }}
            </span>

            <div
              *ngIf="activeCategory === categoryIndex"
              class="this-active-category"
            ></div>

            <ul *ngIf="activeCategory === categoryIndex">
              <ng-container
                *ngFor="
                  let subcategory of category.children;
                  let subcategoryIndex = index
                "
              >
                <li *ngIf="subcategory.children === undefined">
                  <span (click)="onCategoryChange(subcategory.routerLink)">
                    {{ subcategory.name }}
                  </span>
                </li>

                <li *ngIf="subcategory.children">
                  <span (click)="onSubCategoryClick(subcategoryIndex)">
                    {{ subcategory.name }}
                  </span>

                  <ul *ngIf="activeSubcategory === subcategoryIndex">
                    <li
                      *ngFor="
                        let subsubcategory of category.children[
                          activeSubcategory
                        ].children
                      "
                    >
                      <span
                        (click)="onCategoryChange(subsubcategory.routerLink)"
                      >
                        {{ subsubcategory.name }}
                      </span>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>

    <div
      #bottomLinksEl
      [style.max-height]="maxBottomLinksHeight"
      id="this-bottom-links"
    >
      <div class="row">
        <div class="col">
          <div id="this-currency-select">
            <div class="input-group input-group-no-border app-custom-select">
              <select
                [(ngModel)]="siteService.currency"
                (change)="onCurrencyChange()"
                class="form-control"
              >
                <option
                  *ngFor="let currency of currencyList"
                  [ngValue]="currency.code"
                >
                  {{ currency.name }} - {{ currency.symbol }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div id="this-links">
        <div class="row">
          <div (click)="onLinksClick()" class="col">
            <span>Links</span>
          </div>
        </div>

        <div *ngIf="showLinks" class="row">
          <div class="col">
            <ul>
              <li>
                <span (click)="onCompanyLinksClick()">Company</span>

                <ul *ngIf="showCompanyLinks">
                  <li (click)="close()" [routerLink]="['/privacy-policy']">
                    <span>Privacy Policy</span>
                  </li>
                  <li (click)="close()" [routerLink]="['/cookie-policy']">
                    <span>Cookie Policy</span>
                  </li>
                  <li (click)="close()" [routerLink]="['/terms-of-use']">
                    <span>Terms of Use</span>
                  </li>
                  <li (click)="close()">
                    <span><a href="blog">Blog</a></span>
                  </li>
                </ul>
              </li>
              <li>
                <span (click)="onSellLinksClick()">Sell</span>

                <ul *ngIf="showSellLinks">
                  <li (click)="close()">
                    <a
                      href="https://portal.qubyk.com/#/register"
                      target="_blank"
                    >
                      Register to sell
                    </a>
                  </li>
                  <li (click)="close()">
                    <a href="https://portal.qubyk.com/#/login" target="_blank">
                      Merchant Login
                    </a>
                  </li>
                  <li (click)="close()">
                    <a href="/docs/index.html#introduction" target="_blank">
                      API Documentation
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <span (click)="onSupportLinksClick()">Support</span>

                <ul *ngIf="showSupportLinks">
                  <li (click)="close()">
                    <a [routerLink]="['/faq']">FAQ's</a>
                  </li>
                  <li (click)="close()">
                    <a [routerLink]="['/shipping']">Shipping</a>
                  </li>
                  <li (click)="close()">
                    <a [routerLink]="['/returns']">Returns</a>
                  </li>
                  <li>
                    <a href="mailto:{{ siteService.supportEmail }}">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <span (click)="onTrendingLinksClick()"
                  >Trending Categories</span
                >

                <ul *ngIf="showTrendingLinks">
                  <li
                    *ngFor="
                      let item of this.siteService.getTrendingCategories(
                        this.siteService.domain
                      )
                    "
                  >
                    <a [href]="item.link">{{ item.name }}</a>
                  </li>
                </ul>
              </li>
              <li>
                <span (click)="onSitesLinksClick()">Sites</span>

                <ul *ngIf="showSitesLinks">
                  <li>
                    <a href="https://qubyk.com.au">Australia</a>
                  </li>
                  <li>
                    <a href="https://qubyk.com">USA</a>
                  </li>
                  <li>
                    <a href="https://qubyk.co.uk">UK</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div *ngIf="!siteService.user && !hideLogin" id="this-auth" class="row">
        <div (click)="onLoginClick()" class="col">
          <span>Login</span>
        </div>

        <div (click)="onRegisterClick()" class="col">
          <span>Register</span>
        </div>
      </div>

      <div *ngIf="siteService.user" id="this-auth-actions">
        <div class="row"></div>

        <div (click)="onViewAccountClick()" class="row">
          <div class="col">
            <span>View Account</span>
          </div>
        </div>

        <div
          *ngIf="showViewAccount"
          (click)="close()"
          id="this-account-actions"
          class="row"
        >
          <div class="col">
            <ul>
              <li [routerLink]="['/account/orders-history']">
                <span> Orders History </span>
              </li>
              <li [routerLink]="['/account/details']">
                <span>Account Details</span>
              </li>
              <li [routerLink]="['/account/contact']">
                <span>Contact Us</span>
              </li>
            </ul>
          </div>
        </div>

        <div (click)="onLogout()" class="row">
          <div class="col">
            <span>Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
