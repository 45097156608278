<div id="this">
  <div class="card">
    <div class="card-header">
      <h5 class="mb-0">Pay by Card or Afterpay</h5>
    </div>

    <div class="card-body">
      <form *ngIf="onCancel !== 'true'" id="this-payment-form">
        <div id="payment-element"></div>

        <div class="mt-3">
          <button
            *ngIf="onPay === false"
            [disabled]="disabled"
            (click)="onSubmit()"
            class="btn btn-dark btn-block"
          >
            Pay Now
          </button>

          <button
            *ngIf="onPay === true"
            [disabled]="true"
            class="btn btn-dark btn-block"
          >
            <span class="fa fa-spinner fa-spin"></span>
          </button>
        </div>

        <div class="mt-1">
          <span *ngIf="error" class="text-danger font-weight-bolder">
            {{ error }}
          </span>
        </div>

        <div class="mt-3">
          <a (click)="onCancelClick()"> Cancel Payment </a>
        </div>

        <div class="mt-5 text-center">
          <img
            [src]="'assets/img/payments/powered-by-stripe.png'"
            alt="Powered by Stripe"
          />
        </div>
      </form>

      <div *ngIf="onCancel === 'true'" class="text-center">
        <span class="fa fa-2x fa-spinner fa-spin"></span>

        <br /><br />

        <h4>Cancelling Payment...</h4>
      </div>
    </div>
  </div>
</div>
