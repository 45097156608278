<div id="this">
  <app-carousel-items
    *ngIf="categories"
    [width]="width"
    [nbItemsDisplayed]="nbShowedBoxes"
    [hideControls]="false"
    [showScrollBar]="false"
    [items]="categories"
    [$item]="itemTemplate"
  ></app-carousel-items>

  <ng-template let-category #itemTemplate>
    <ng-container
      *ngTemplateOutlet="categoryTemplate; context: { category: category }"
    ></ng-container>
  </ng-template>

  <ng-template #categoryTemplate let-category="category">
    <div *ngIf="category.isEnabled" class="this-item">
      <a *ngIf="category.routerLink" [routerLink]="category.routerLink">
        <img [src]="category.imageSrc" [alt]="category.alt" class="d-block" />
      </a>

      <a *ngIf="category.href" [href]="category.href">
        <img [src]="category.imageSrc" [alt]="category.alt" class="d-block" />
      </a>

      <h6 style="font-weight: bold">{{ category.label }}</h6>
    </div>
  </ng-template>
</div>
