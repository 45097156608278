import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { MiscContentService } from '../../ecommerce/services/misc-content/misc-content.service';
import { SiteService } from '../../ecommerce/services/site/site.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent implements OnInit {
  @ViewChild('subscribeModalTemplate')
  subscribeModalTemplate: ElementRef;

  modalRef: BsModalRef;
  subscribeForm: FormGroup;
  recaptchaValue: string;

  onSubscribe: string;
  showModal: boolean;
  showCaptcha: boolean = true;

  constructor(
    private modalService: BsModalService,
    private miscContentService: MiscContentService,
    protected siteService: SiteService
  ) {}

  ngOnInit() {
    if (this.siteService.IS_SSR) this.showCaptcha = false;
  }

  open() {
    this.onSubscribe = 'false';
    this.subscribeForm = new FormGroup({
      fullName: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      ]),
      recaptcha: new FormControl(false, [Validators.required]),
    });

    this.showModal = false;

    setTimeout(() => {
      this.showModal = true;

      setTimeout(() => {
        this.modalRef = this.modalService.show(
          this.subscribeModalTemplate as any
        );
        this.modalService.onHide.subscribe(() => this.onHide());
      });
    }, 100);
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  onRecaptchaChange(recaptcha: any) {
    this.recaptchaValue = null;
    this.recaptchaValue = recaptcha.recaptchaAPI.getResponse();
  }

  onSubscribeSubmit() {
    const params = {
      fullName: this.subscribeForm.controls.fullName.value,
      email: this.subscribeForm.controls.email.value,
      captchaResponse: this.recaptchaValue,
    };

    this.onSubscribe = 'true';

    this.miscContentService.subscribe(params).subscribe(
      () => {
        this.onSubscribe = 'success';
        setTimeout(() => this.hide(), 3000);
      },
      () => {
        this.onSubscribe = 'error';
      }
    );
  }

  onHide() {
    this.showModal = false;
  }
}
