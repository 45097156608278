import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { SiteService } from '../services/site/site.service';
import { environment } from '../../../environments/environment';
import config from '../../../assets/config.json';
import { IOpenGraphTag } from '../../models/seo/IOpenGraphTag';
import { ITwitterTag } from '../../models/seo/ITwitterTag';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  readonly TITLE: string;
  readonly TITLE_SEPARATOR: string;
  readonly API_PREFIX: string;
  readonly ANALYTICS_TAG: any;
  readonly GTM_TAG: any;

  h1Tag: Subject<string> = new Subject<string>();
  descTag: Subject<string> = new Subject<string>();
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private rendererFactory: RendererFactory2,
    private titleService: Title,
    private metaService: Meta,
    private siteService: SiteService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.TITLE = `${config.TITLE} ${this.siteService.countryName}`;
    this.TITLE_SEPARATOR = '|';
    this.API_PREFIX = `${environment.siteUrl}/meta/v2`;
    this.ANALYTICS_TAG = config.GOOGLE.ANALYTICS_TAG;
    this.GTM_TAG = config.GOOGLE.GTM_TAG;
  }

  getMetaInformation(params: any): Observable<any> {
    const url = `${this.API_PREFIX}/info/${this.siteService.domain}`;
    return this.http.post(url, params);
  }

  getAndSetMetaInformation(params: any): Promise<any> {
    return new Promise<any>((resolve) => {
      this.getMetaInformation(params).subscribe((data: any) => {
        const { h1, title, metaDesc, metaKeyword } =
          data && data.status === 'success' && data.data
            ? data.data
            : { h1: '', title: '', metaDesc: '', metaKeyword: '' };

        this.setH1(h1);
        this.setTitle(title, false);
        this.setMetaDescription(metaDesc);
        this.setMetaKeywords(metaKeyword);

        resolve('success');
      });
    });
  }

  setH1(content: string) {
    this.h1Tag.next(content);
  }

  setTitle(
    metaTitle?: string | { title: string | string[]; keywords?: string },
    addTraillingTitle: boolean = true
  ) {
    switch (typeof metaTitle) {
      case 'string':
        if (addTraillingTitle) {
          this.titleService.setTitle(
            `${metaTitle} ${this.TITLE_SEPARATOR} ${this.TITLE}`
          );
        } else {
          this.titleService.setTitle(metaTitle);
        }
        break;
      case 'object':
        let { title } = metaTitle;
        const { keywords } = metaTitle;

        if (typeof title === 'object') {
          title = title.join(` ${this.TITLE_SEPARATOR} `);
        }

        if (!keywords) {
          if (addTraillingTitle) {
            this.titleService.setTitle(
              `${title} ${this.TITLE_SEPARATOR} ${this.TITLE}`
            );
          } else {
            this.titleService.setTitle(title);
          }
        } else {
          this.titleService.setTitle(
            `${title} ${this.TITLE_SEPARATOR} ${this.TITLE} ${this.TITLE_SEPARATOR} ${keywords}`
          );
        }
        break;
      default:
        this.titleService.setTitle(this.TITLE);
    }
  }

  setMetaTitle(content: string) {
    this.metaService.updateTag({
      name: 'title',
      content: content,
    });
  }

  setMetaDescription(content: string) {
    this.descTag.next(content); // to inform any subscribers
    this.metaService.updateTag({
      name: 'description',
      content: content,
    });
  }

  setMetaKeywords(keywords: string) {
    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  setMetaAuthor(author: string) {
    this.metaService.updateTag({
      name: 'author',
      content: author,
    });
  }

  setMetaViewPort() {
    this.metaService.updateTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0',
    });
  }

  setMetaCanonical(canonical: string) {
    this.metaService.updateTag({
      name: 'canonical',
      content: canonical,
    });
  }

  setNoIndexTag() {
    this.metaService.updateTag({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  setIndexTag() {
    this.metaService.updateTag({
      name: 'robots',
      content: 'index, follow',
    });
  }

  setOpenGraphTag(tag: IOpenGraphTag) {
    if (tag == null) return;

    if (tag?.title != null)
      this.metaService.updateTag({
        property: 'og:title',
        content: `${tag?.title}`,
      });

    if (tag?.description != null)
      this.metaService.updateTag({
        property: 'og:description',
        content: `${tag?.description}`,
      });

    if (tag?.url != null)
      this.metaService.updateTag({
        property: 'og:url',
        content: `${tag?.url}`,
      });

    if (tag?.image != null)
      this.metaService.updateTag({
        property: 'og:image',
        content: `${tag?.image}`,
      });

    if (tag?.siteName != null)
      this.metaService.updateTag({
        property: 'og:site_name',
        content: `${tag?.siteName}`,
      });

    if (tag?.type != null)
      this.metaService.updateTag({
        property: 'og:type',
        content: `${tag?.type}`,
      });
  }

  //Set twitter tag
  setTwitterTag(tag: ITwitterTag) {
    if (tag == null) return;

    if (tag?.card != null)
      this.metaService.updateTag({
        name: 'twitter:card',
        content: `${tag?.card}`,
      });

    if (tag?.title != null)
      this.metaService.updateTag({
        name: 'twitter:title',
        content: `${tag?.title}`,
      });

    if (tag?.description != null)
      this.metaService.updateTag({
        name: 'twitter:description',
        content: `${tag?.description}`,
      });

    if (tag?.image != null)
      this.metaService.updateTag({
        name: 'twitter:image',
        content: `${tag?.image}`,
      });

    if (tag?.site != null)
      this.metaService.updateTag({
        name: 'twitter:site',
        content: `${tag?.site}`,
      });

    if (tag?.creator != null)
      this.metaService.updateTag({
        name: 'twitter:creator',
        content: `${tag?.creator}`,
      });
  }

  setGoogleAnalyticsTag() {
    const analyticsId = this.ANALYTICS_TAG[this.siteService.domain];
    const script1El = this.document.createElement('script');
    const script2El = this.document.createElement('script');

    script1El.setAttribute('async', '');
    script1El.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`
    );

    script2El.text = `
      window.dataLayer =  window.dataLayer || [];

      function gtag() {
        dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', '${analyticsId}');
    `;

    this.document.body.appendChild(script1El);
    this.document.body.appendChild(script2El);
  }

  addGtmTagToHead(): void {
    var gtmId = this.GTM_TAG[this.siteService.domain];
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    this.renderer.appendChild(document.head, script);
  }

  addGtmNoScriptTag(): void {
    var gtmId = this.GTM_TAG[this.siteService.domain];
    const noscript = this.renderer.createElement('noscript');
    const iframe = this.renderer.createElement('iframe');
    this.renderer.setAttribute(
      iframe,
      'src',
      `https://www.googletagmanager.com/ns.html?id=${gtmId}`
    );
    this.renderer.setAttribute(iframe, 'height', '0');
    this.renderer.setAttribute(iframe, 'width', '0');
    this.renderer.setStyle(iframe, 'display', 'none');
    this.renderer.setStyle(iframe, 'visibility', 'hidden');
    this.renderer.appendChild(noscript, iframe);
    this.renderer.appendChild(document.body, noscript);
  }

  setSchematics(schema: any) {
    const scriptEl = this.document.createElement('script');
    scriptEl.type = 'application/ld+json';
    scriptEl.text = `${JSON.stringify(schema)}`;
    this.document.body.appendChild(scriptEl);
  }

  removeSchematics() {
    const scriptEl = this.document.querySelector(
      'script[type="application/ld+json"]'
    );

    if (scriptEl) {
      this.document.body.removeChild(scriptEl);
    }
  }
}
