import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgRedux } from '@angular-redux2/store';

import {
  GET_ADS_SUCCESS,
  GET_TRENDING_SUCCESS,
} from '../../store/product-grid.actions';

import { IProductGridState } from '../../store';

import { SiteService } from '../site/site.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MiscContentService {
  readonly API_SITE_CONTENT_PREFIX: string;
  readonly API_SUBSCRIPTION_PREFIX: string;
  readonly API_URL_PREFIX: string;

  onGetAdArea: string;
  onGetTopSellings: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngReduxProductGrid: NgRedux<IProductGridState>,
    private siteService: SiteService
  ) {
    this.API_SITE_CONTENT_PREFIX = `${environment.siteUrl}/site-content/${environment.apiVersion}`;
    this.API_SUBSCRIPTION_PREFIX = `${environment.siteUrl}/subscription/${environment.apiVersion}`;
    this.API_URL_PREFIX = `${environment.apiUrl}/search/api`;
  }

  get category(): string {
    let category = this.router.url
      .replace('/nav/', '')
      .split('/brand/')[0]
      .split('?')[0];

    if (category === '/') {
      category = 'home';
    }

    return category;
  }

  getCategories() {
    const url = `${this.API_SITE_CONTENT_PREFIX}/categories/${this.siteService.domain}`;
    return this.http.get(url);
  }

  getStripBar() {
    const url = `${this.API_SITE_CONTENT_PREFIX}/strip-bar/${this.siteService.domain}`;
    return this.http.get(url);
  }

  getCarousel() {
    const url = `${this.API_SITE_CONTENT_PREFIX}/carousel/${this.category}/${this.siteService.domain}`;
    return this.http.get(url);
  }

  getMobileCarousel() {
    const url = `${this.API_SITE_CONTENT_PREFIX}/carousel-mobile/${this.category}/${this.siteService.domain}`;
    return this.http.get(url);
  }

  getAllBlog(category?: string) {
    if (!category) {
      category = this.category;
    }

    const url = `${this.API_SITE_CONTENT_PREFIX}/blog/${category}/${this.siteService.domain}`;
    return this.http.get(url);
  }

  getTrendings() {
    const url = `${this.API_SITE_CONTENT_PREFIX}/trending/${this.category}/${this.siteService.domain}/${this.siteService.currency}`;
    return this.http.get(url);
  }

  getTrendingsNavGrid() {
    const url = `${this.API_SITE_CONTENT_PREFIX}/trending/${this.category}/${this.siteService.domain}/${this.siteService.currency}`;

    this.onGetTopSellings = 'true';

    this.http.get(url).subscribe(
      (data: any) => {
        let items, show;

        if (data.data) {
          if (data.data.items) {
            show = data.data.isEnabled;
            items = data.data.items;
            this.siteService.setProductsImages(items);
          } else {
            items = [];
          }
        } else {
          show = false;
          items = [];
        }

        this.onGetTopSellings = 'success';

        return this.ngReduxProductGrid.dispatch({
          type: GET_TRENDING_SUCCESS,
          topSellings: {
            items,
            show,
          },
        });
      },
      () => {
        this.onGetTopSellings = 'error';

        return this.ngReduxProductGrid.dispatch({
          type: GET_TRENDING_SUCCESS,
          topSellings: {
            items: [],
            show: false,
          },
        });
      }
    );
  }

  getAds() {
    const url = `${this.API_SITE_CONTENT_PREFIX}/ads/${this.category}/${this.siteService.domain}`;
    return this.http.get(url);
  }

  getAdsNavGrid() {
    const url = `${this.API_SITE_CONTENT_PREFIX}/ads/${this.category}/${this.siteService.domain}`;

    this.onGetAdArea = 'true';

    this.http.get(url).subscribe(
      (data: any) => {
        let items, show;

        if (data.data) {
          show = data.data.isEnabled;

          if (data.data.items) {
            items = data.data.items;
          } else {
            items = [];
          }
        } else {
          items = [];
          show = false;
        }

        this.onGetAdArea = 'success';

        return this.ngReduxProductGrid.dispatch({
          type: GET_ADS_SUCCESS,
          ads: {
            items,
            show,
          },
        });
      },
      () => {
        this.onGetAdArea = 'error';

        return this.ngReduxProductGrid.dispatch({
          type: GET_ADS_SUCCESS,
          ads: {
            items: [],
            show: false,
          },
        });
      }
    );
  }

  subscribe(params: any) {
    const url = `${this.API_SUBSCRIPTION_PREFIX}/save/${this.siteService.domain}`;
    return this.http.post(url, params);
  }
}
