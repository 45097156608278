import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BreadcrumbService } from '../../ecommerce/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() url: string;

  get onProductGrid(): boolean {
    return this.router.url.includes('/nav');
  }

  get queryParams(): any {
    return this.activatedRoute.snapshot.queryParams;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public breadcrumbService: BreadcrumbService
  ) {}

  ngOnInit() {
    this.setBreadcrumb();
  }

  setBreadcrumb() {
    this.breadcrumbService.setBreadcrumb();
  }
}
