import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  ViewEncapsulation,
  ChangeDetectorRef,
  HostListener,
  EventEmitter,
  Inject,
} from '@angular/core';

import { SiteService } from '../../ecommerce/services/site/site.service';

import currencies from '../../../assets/currencies.json';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'slider-mobile',
  templateUrl: './slider-mobile.template.html',
  // TODO: maybe change product-card.style.scss location
  styleUrls: [
    './slider-mobile.style.scss',
    '../../ecommerce/home/product-card.style.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SliderMobileComponent implements OnInit, AfterViewInit {
  readonly DEFAULT_NB_SHOWED_BOXES: number;

  @Input() cards: Array<any> = [];
  @Input() width: number;
  @Input() marginBotton: number;
  @Input() nbShowedBoxes: number;
  @Input() showMoreButton: boolean;
  @Input() gotAllItems: boolean;
  @Output() onChange: EventEmitter<any>;
  @Output() productClick: EventEmitter<any>;
  @Output() moreClick: EventEmitter<void>;

  productCardStyle: any;

  onLoadMore: boolean;
  isImageLoaded: boolean[];

  constructor(
    @Inject(WINDOW) private window: Window,
    private changeDetectorRef: ChangeDetectorRef,

    public siteService: SiteService
  ) {
    this.DEFAULT_NB_SHOWED_BOXES = 3;

    this.onChange = new EventEmitter<any>();
    this.productClick = new EventEmitter<any>();
    this.moreClick = new EventEmitter<void>();
  }

  ngOnInit() {
    this.isImageLoaded = [];
    this.setConfiguration();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  getCurrency(index: number) {
    if (this.cards && this.cards[index]) {
      const currency = this.cards[index].currency.toLowerCase();
      return currencies.find((cur: any) => cur.code === currency).symbol;
    }

    return null;
  }
  setConfiguration() {
    if (this.width === undefined) {
      this.width = this.window.innerWidth;
    }

    if (this.nbShowedBoxes === undefined) {
      if (this.window.innerWidth <= 500) {
        this.nbShowedBoxes = 2;
      } else {
        this.nbShowedBoxes = this.DEFAULT_NB_SHOWED_BOXES;
      }
    }

    const width = `${100 / this.nbShowedBoxes}%`;

    this.productCardStyle = {
      flex: `0 0 ${width}`,
      'max-width': width,
    };

    this.setCardsImage();
  }

  setCardsImage() {
    this.siteService.setProductsImages(this.cards);
  }

  onCardImageLoaded(productId: string) {
    this.isImageLoaded[productId] = true;
  }

  onCardMouseEnter(cardIndex: number): void {
    const cart = this.cards[cardIndex];

    if (cart.image && cart.image.length > 1) {
      cart.currentImage = cart.image[1];
    } else if (cart.images && cart.images.length > 1) {
      cart.currentImage = cart.images[1];
    }
  }

  onCardMouseLeave(cardIndex: number): void {
    const cart = this.cards[cardIndex];

    if (cart.image && cart.image.length > 1) {
      cart.currentImage = cart.image[0];
    } else if (cart.images && cart.images.length > 0) {
      cart.currentImage = cart.images[0];
    }
  }

  onSelectedItem(event) {
    this.onChange.emit({
      start: event.index,
      end: event.index + this.nbShowedBoxes,
    });
  }

  onProductClick(product: any) {
    this.productClick.emit(product);
  }

  onMoreClick() {
    this.moreClick.emit();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setConfiguration();
  }
}
