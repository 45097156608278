import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { LoginComponent } from './login.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { OrdersService } from '../../ecommerce/services/orders/orders.service';
import { ItemsViewedService } from '../../ecommerce/services/items-viewed/items-viewed.service';
import { SocialLoginService } from '../../ecommerce/services/social-login/social-login.service';
import { WishListService } from '../../ecommerce/services/wish-list/wish-list.service';
import { SeoService } from '../../ecommerce/seo/seo.service';
import { forkJoin, Observable } from 'rxjs'; // RxJS 6 syntax

declare let FB: any;

@Component({
  selector: 'app-login-modal-desktop',
  templateUrl: './login-modal-desktop.component.html',
  styleUrls: ['./login-modal-desktop.component.scss'],
})
export class LoginModalDesktopComponent extends LoginComponent {
  @ViewChild('loginModalTemplate')
  loginModalTemplate: ElementRef;

  modalRef: BsModalRef;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected cartService: CartService,
    protected ordersService: OrdersService,
    protected itemsViewedService: ItemsViewedService,
    protected wishListService: WishListService,
    protected seoService: SeoService,

    private modalService: BsModalService,

    public siteService: SiteService,
    public socialLoginService: SocialLoginService
  ) {
    super(
      router,
      activatedRoute,
      cookieService,
      accountService,
      cartService,
      ordersService,
      itemsViewedService,
      wishListService,
      seoService,
      siteService,
      socialLoginService
    );
  }

  open() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(this.MIN_PASSWORD_LENGTH),
        Validators.maxLength(this.MAX_PASSWORD_LENGTH),
        Validators.pattern(/^.*(?=.*?[0-9])(?=.*?[^\w]).*$/),
      ]),
      recaptcha: new FormControl(false, [Validators.required]),
    });

    this.onLogin = 'false';
    this.onInstagramLogin = 'false';
    this.showModal = false;

    document.dispatchEvent(
      new CustomEvent('modalOpened', {
        bubbles: true,
        cancelable: true,
      })
    );

    setTimeout(() => {
      this.showModal = true;

      setTimeout(() => {
        this.modalRef = this.modalService.show(this.loginModalTemplate as any, {
          class: 'app-modal-width-450px',
        });
        this.modalService.onHide.subscribe(() => {
          this.onHide();

          setTimeout(() => {
            document.dispatchEvent(
              new CustomEvent('modalClosed', {
                bubbles: true,
                cancelable: true,
              })
            );
          }, 100);
        });
      });
    }, 100);
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  onLoginClick(recaptcha: any) {
    const params = {
      email: this.f.email.value,
      password: this.f.password.value,
      captchaResponse: this.recaptchaValue,
    };

    this.onLogin = 'true';

    if (recaptcha) recaptcha.recaptchaAPI.reset();

    this.accountService.login(params).subscribe(
      async (data: any) => {
        const user = {
          id: data.memberId,
          token: data.token,
          tokenType: 'general',
        };

        this.siteService.user = user;
        this.onLogin = 'success';

        this.cookieService.set('user', JSON.stringify(user));

        const order = this.cookieService.get('order');
        const cartId = this.cookieService.get('cartId');

        if (order) {
          //Update userId for Order
          const updateOrderParams = {
            cartId: this.siteService.cartId,
            userId: this.siteService.userId,
            newUserId: this.siteService.user.id,
          };

          await this.ordersService.updateUser(updateOrderParams).then(() => {
            setTimeout(async () => {
              await this.ordersService.getOrder();
            }, 500);
          });

          this.siteService.order = JSON.parse(order);
        }

        if (cartId) {
          //Update userId for Cart
          const updateCartParams = {
            cartId: this.siteService.cartId,
            userId: this.siteService.userId,
            newUserId: this.siteService.user.id,
          };

          await this.cartService.updateUser(updateCartParams).then(async () => {
            setTimeout(async () => {
              await this.cartService.getAllItems();
            }, 500);
          });

          this.siteService.cartId = cartId;
        }

        this.accountService.getMember();
        this.wishListService.getAll();

        // Update user id
        const updateParams = {
          siteId: this.siteService.domain,
          tempId: this.siteService.userId,
          userId: this.siteService.user.id,
          currency: this.siteService.currency,
        };

        this.itemsViewedService.updateUserId(updateParams).subscribe(
          () => {
            this.itemsViewedService.clear();
            this.itemsViewedService.get();
          },
          () => {
            this.siteService.unsetUserId();
          }
        );

        this.hide();
      },
      (error: HttpErrorResponse) => {
        this.onLogin = 'error';
        this.loginError = error.error.message;
      }
    );
  }

  public requestDataFromMultipleSources(source: any): Observable<any[]> {
    let response1 = source;
    let response2 = source;
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([response1, response2]);
  }

  onSignUpClick() {
    this.hide();
    super.onSignUpClick();
  }

  onPasswordForgottenClick() {
    this.hide();
    super.onPasswordForgottenClick();
  }

  onHide() {
    super.onHide();

    document.dispatchEvent(
      new CustomEvent('loginModalClosed', {
        bubbles: true,
        cancelable: true,
      })
    );
  }

  @HostListener('document:login')
  onLoginEvent() {
    this.open();
  }

  @HostListener('document:closeLogin')
  onCloseLoginEvent() {
    this.hide();
  }
}
