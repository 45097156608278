<ng-template #subscribeModalTemplate *ngIf="showModal">
  <div class="modal-body">
    <h1 class="font-title text-center pb-4">Subscribe</h1>

    <form [formGroup]="subscribeForm" (submit)="onSubscribeSubmit()">
      <div class="form-group">
        <div
          [ngClass]="{
            success:
              subscribeForm.controls.fullName.pristine === false &&
              subscribeForm.controls.fullName.valid,
            error:
              subscribeForm.controls.fullName.pristine === false &&
              subscribeForm.controls.fullName.invalid
          }"
          class="input-group input-group-no-border"
        >
          <span class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-user"></i>
            </span>
          </span>

          <input
            formControlName="fullName"
            class="form-control form-control-lg"
            type="text"
            placeholder="Full Name"
            name="fullname"
          />
        </div>
      </div>

      <div class="form-group">
        <div
          [ngClass]="{
            success:
              subscribeForm.controls.email.pristine === false &&
              subscribeForm.controls.email.valid,
            error:
              subscribeForm.controls.email.pristine === false &&
              subscribeForm.controls.email.invalid
          }"
          class="input-group input-group-no-border"
        >
          <span class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-at"></i>
            </span>
          </span>

          <input
            formControlName="email"
            class="form-control form-control-lg"
            type="email"
            placeholder="E-Mail"
            name="email"
          />
        </div>
      </div>

      <div class="row">
        <div class="col"></div>
        <div class="col">
          <recaptcha
            #recaptcha
            *ngIf="showCaptcha"
            (ngModelChange)="onRecaptchaChange(recaptcha)"
            formControlName="recaptcha"
            name="recaptcha"
            class="g-recaptcha"
          >
          </recaptcha>
        </div>
        <div class="col"></div>
      </div>

      <div *ngIf="onSubscribe !== 'success'" class="form-group mt-4">
        <button
          *ngIf="onSubscribe !== 'true'"
          [disabled]="subscribeForm.invalid"
          class="btn btn-lg btn-dark btn-block"
        >
          &#10230;
        </button>

        <button
          *ngIf="onSubscribe === 'true'"
          [disabled]="true"
          class="btn btn-lg btn-dark btn-block"
        >
          <span class="fa fa-spinner fa-spin"></span>
        </button>
      </div>

      <div
        *ngIf="onSubscribe === 'success'"
        class="text-success text-center mt-4"
      >
        <span class="font-weight-bolder">
          <span class="fa fa-check-circle"></span>
          You are now subscribed
        </span>
      </div>

      <div *ngIf="onSubscribe === 'error'" class="text-danger text-center mt-4">
        <span class="font-weight-bolder"> Cannot subscribe. </span>
      </div>
    </form>
  </div>
</ng-template>
