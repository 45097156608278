import { Observable, map, catchError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux2/store';
import { HttpClient } from '@angular/common/http';

import {
  GET_PRODUCTS_SUCCESS,
  DELETE_PRODUCTS,
  REQ_PRODUCTS_ERROR,
} from '../store/products.actions';

import { IProductsState } from '../store';
import { ICartItem } from '../../models/carts/ICartItem';
import { SiteService } from './site/site.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProductsService {
  readonly API_PREFIX_SEARCH: string;
  readonly API_PREFIX_MARKETPLACE_PRODUCTS: string;
  readonly API_PREFIX_MARKETPLACE_PRODUCTS_V2: string;
  readonly API_PREFIX_MARKETPLACE_GET_PRODUCT_META: string;
  readonly API_PREFIX_MARKETPLACE_GET_PRODUCT_VARIATION: string;
  readonly DEFAULT_GET_PRODUCTS_LIMIT: number;
  readonly API_PREFIX_CATEGORIES: string;

  onGetProducts: boolean;
  skipChildCat: boolean = false; //set this initally to false
  gotAllProducts: boolean;

  getProductsStatus: string = '';
  onGetProductsStatusUpdated: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngRedux: NgRedux<IProductsState>,
    private siteService: SiteService
  ) {
    this.API_PREFIX_SEARCH = `${environment.productsUrl}/api/products`;
    this.API_PREFIX_MARKETPLACE_PRODUCTS = `${environment.productsUrl}/api/products`;
    this.API_PREFIX_MARKETPLACE_PRODUCTS_V2 = `${environment.productsUrl}/api/v2/products`;
    this.API_PREFIX_MARKETPLACE_GET_PRODUCT_META = `${environment.productsMetaUrl}/api/product-meta`;
    this.API_PREFIX_MARKETPLACE_GET_PRODUCT_VARIATION = `${environment.productsMetaUrl}/api/product-variation`;
    this.DEFAULT_GET_PRODUCTS_LIMIT = 48;
    this.API_PREFIX_CATEGORIES = `${environment.siteUrl}/api/category-page`;
  }

  getProducts(params: {
    start?: number;
    limit?: number;
    brand?: string;
    priceFilter?: string;
    justArrivedFirst?: any;
    stack?: boolean; // on load more
    preStack?: boolean; // on load previous
  }) {
    let { category, categoryCount, url } =
      this.getUrlAndCategoryCountFromParams(params);

    let { stack, preStack } = params;

    if (stack === undefined) {
      stack = false;
    }

    if (preStack === undefined) {
      preStack = false;
    }

    this.onGetProducts = true;
    this.getProductsStatus = 'true';
    this.onGetProductsStatusUpdated.next(true);

    if (categoryCount == 3) {
      this.http.get(url).subscribe(
        (response: any) => {
          let prods: any[] = [];
          if (
            response &&
            response.products &&
            Array.isArray(response.products) &&
            response.products.length
          ) {
            prods = response.products;
          }

          this.skipChildCat = response.skipChildCat;

          this.onGetProducts = false;
          this.getProductsStatus = 'success';
          this.onGetProductsStatusUpdated.next(true);

          //  this.gotAllProducts = prods.products.length < limit;

          this.setProductsFullId(prods);
          this.siteService.setProductsImages(prods);

          return this.ngRedux.dispatch({
            type: GET_PRODUCTS_SUCCESS,
            prods: { products: prods, nav: '', stack, preStack },
          });
        },
        (err) => {
          this.onGetProducts = false;
          this.getProductsStatus = 'error';
          this.onGetProductsStatusUpdated.next(true);

          return this.ngRedux.dispatch({
            type: REQ_PRODUCTS_ERROR,
            nav: category,
          });
        }
      );
    } else {
      this.http.get(url).subscribe(
        (response: any) => {
          let prods: any[] = [];
          if (
            response &&
            response.products &&
            Array.isArray(response.products) &&
            response.products.length
          ) {
            prods = response.products;
          }

          this.onGetProducts = false;
          this.getProductsStatus = 'success';
          this.onGetProductsStatusUpdated.next(true);

          //  this.gotAllProducts = prods.length < limit;

          this.setProductsFullId(prods);
          this.siteService.setProductsImages(prods);

          return this.ngRedux.dispatch({
            type: GET_PRODUCTS_SUCCESS,
            prods: { products: prods, nav: '', stack, preStack },
          });
        },
        (err) => {
          this.onGetProducts = false;
          this.getProductsStatus = 'error';
          this.onGetProductsStatusUpdated.next(true);

          return this.ngRedux.dispatch({
            type: REQ_PRODUCTS_ERROR,
            nav: category,
          });
        }
      );
    }
  }

  getProductsCount(params: {
    start?: number;
    limit?: number;
    brand?: string;
    priceFilter?: string;
    justArrivedFirst?: any;
    stack?: boolean; // on load more
    preStack?: boolean; // on load previous
  }) {
    const { url } = this.getUrlAndCategoryCountFromParams(params);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response && response.count ? response.count : 0;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getUrlAndCategoryCountFromParams(params: {
    start?: number;
    limit?: number;
    brand?: string;
    priceFilter?: string;
    justArrivedFirst?: any;
    stack?: boolean; // on load more
    preStack?: boolean; // on load previous
  }) {
    const category = this.router.url
      .replace('/nav/', '')
      .split('/brand/')[0]
      .split('?')[0];
    let { start, limit, brand, priceFilter, justArrivedFirst } = params;

    let url;
    let categoryCount;

    if (start === undefined) {
      start = 0;
    }

    if (limit === undefined) {
      limit = this.DEFAULT_GET_PRODUCTS_LIMIT;
    }

    if (priceFilter === undefined) {
      priceFilter = 'none';
    }

    if (justArrivedFirst) {
      justArrivedFirst = 1;
    } else {
      justArrivedFirst = 0;
    }

    if (brand) {
      url = `${this.API_PREFIX_SEARCH}/${this.siteService.country}/${this.siteService.currency}/${category}/brand/${brand}/${priceFilter}/${justArrivedFirst}/${start}/${limit}`;
    } else {
      categoryCount = category.split('/').length;
      if (categoryCount == 3) {
        url = `${this.API_PREFIX_MARKETPLACE_PRODUCTS_V2}/${this.siteService.country}/${this.siteService.currency}/${category}/${priceFilter}/${justArrivedFirst}/${start}/${limit}?skipChildCat=${this.skipChildCat}`;
      } else {
        url = `${this.API_PREFIX_MARKETPLACE_PRODUCTS_V2}/${this.siteService.country}/${this.siteService.currency}/${category}/${priceFilter}/${justArrivedFirst}/${start}/${limit}`;
      }
    }

    return {
      category: category,
      categoryCount: categoryCount,
      url: url,
    };
  }

  getProductMeta(title: string, id: string): Observable<any> {
    const url = `${this.API_PREFIX_MARKETPLACE_GET_PRODUCT_META}/${title}/${id}`;
    return this.http.get(url);
  }

  getProductVariations(id: string): Observable<any> {
    const url = `${this.API_PREFIX_MARKETPLACE_GET_PRODUCT_VARIATION}/${this.siteService.currency}/${id}`;
    return this.http.get(url);
  }

  setProductFullId(product: ICartItem): ICartItem {
    if (product.isVariation) {
      product.fullId = `${product.id}-${product.variationSku}`;
    } else {
      product.fullId = product.id;
    }

    return product;
  }

  setProductsFullId(products: ICartItem[]): ICartItem[] {
    if (products instanceof Array) {
      products = products.map((prod: ICartItem) => {
        return this.setProductFullId(prod);
      });
    }

    return products;
  }

  deleteProducts() {
    return this.ngRedux.dispatch({
      type: DELETE_PRODUCTS,
      nav: '',
    });
  }

  getCategoryDetails(category: string) {
    const mobileDesktopVersion = this.siteService.isMobileView
      ? 'mobile'
      : 'desktop';
    const url = `${this.API_PREFIX_CATEGORIES}/${category}/components/${environment.apiVersion}/${mobileDesktopVersion}/${this.siteService.domain}`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response && response.data ? response.data : null;
      }),
      catchError((error) => {
        throw error;
      })
    );
  }

  getCategoryFromUrl() {
    const split1 = this.router.url.split('/nav/');
    let name = 'nocategory';
    if (split1 && split1.length) {
      name = split1.length > 1 ? split1[1] : split1[0];
    }
    if (name.includes('/')) {
      name = name.split('/')[0];
    }
    if (name.includes('?')) {
      name = name.split('?')[0];
    }
    return name;
  }

  getCategoryDepthFromUrl() {
    const category = this.router.url
      .replace('/nav/', '')
      .split('/brand/')[0]
      .split('?')[0];

    const categoryCount = category.split('/').length;
    return categoryCount;
  }
}
