import { Injectable } from '@angular/core';
import { SiteService } from '../site/site.service';
import config from '../../../../assets/config.json';
import { CheckoutService } from '../checkout/checkout.service';
import { CookieService } from '../cookie/cookie.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

declare let Stripe: any;

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  stripe: any;
  elements: any;
  readonly STRIPE: any;
  stripeLink: boolean = true;
  stripePaymentIntent: string;

  private isStripeLinkEnabledSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  isStripeLinkEnabled$: Observable<boolean> =
    this.isStripeLinkEnabledSubject.asObservable();

  constructor(
    private router: Router,
    protected siteService: SiteService,
    protected checkoutService: CheckoutService,
    protected cookieService: CookieService
  ) {
    this.STRIPE = config.STRIPE;
  }

  async enableStripLink(
    items,
    total,
    totalShippingCost,
    tax,
    couponCode = null
  ) {
    let stripeTotal = Number(total + totalShippingCost + tax)
      .toFixed(2)
      .replace('.', '');
    let stripeTotalShipping = Number(totalShippingCost)
      .toFixed(2)
      .replace('.', '');

    let courierCookie = JSON.parse(this.cookieService.get('courier'));

    let paymentRequest = {
      items: items,
      total: stripeTotal,
      totalShippingCost: stripeTotalShipping,
      currency: this.siteService.currency,
      country: courierCookie.country,
      couponCode: couponCode,
    };

    await this.getStripeLink(paymentRequest);
  }

  async getStripeLink(payment) {
    let stripe_pk = this.STRIPE[`${this.siteService.domain}KEY`];
    this.stripe = Stripe(stripe_pk, {
      apiVersion: '2020-03-02',
    });

    //get stripeLink checkout here with below payment params
    //and stripe intent
    // make post call to get payment intent - by creating an order here
    const params: any = {
      siteId: this.siteService.domain,
      userId: this.siteService?.user?.id ?? this.siteService?.userId,
      orderId: this.siteService.order.id,
      couponCode: payment?.couponCode,
      country: payment.country,
    };

    let paymentRequest = null;
    this.checkoutService.stripeLink(params).subscribe((data: any) => {
      if (data.status !== 'Success') {
      } else {
        this.stripePaymentIntent = data.data.redirectUrl;
        this.cookieService.set('isLinkInit', 'true');

        let stripeTotalAmount = Number(data.data.payment.total)
          .toFixed(2)
          .replace('.', '');

        let stripeTotalShippingCost = Number(
          data.data.payment.totalShippingCost
        )
          .toFixed(2)
          .replace('.', '');

        let stripeAmounts = {
          total: stripeTotalAmount,
          totalShippingCost: stripeTotalShippingCost,
          currency: this.siteService.currency,
          country: payment.country,
        };

        paymentRequest = this.getPaymentRequest(stripeAmounts, payment.items);

        const elements = this.stripe.elements();
        const prButton = elements.create('paymentRequestButton', {
          paymentRequest,
        });

        (async () => {
          // Check the availability of the Payment Request API first.
          const result = await paymentRequest.canMakePayment();

          if (result) {
            this.updateStripeLinkStatus(true);
            prButton.mount('#payment-request-button');
          } else {
            this.updateStripeLinkStatus(false);
            document.getElementById('payment-request-button').style.display =
              'none';
          }
        })();

        prButton.on('click', (event) => {
          this.closeBuyNowPopUp();
        });

        paymentRequest.on('paymentmethod', async (ev) => {
          const { paymentIntent, error: confirmError } =
            await this.stripe.confirmCardPayment(
              this.stripePaymentIntent,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            );

          //complete the process
          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.

            ev.complete('fail');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await this.stripe.confirmCardPayment(
                this.stripePaymentIntent
              );
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
              } else {
                // The payment has succeeded.
              }
            } else {
              // The payment has succeeded.

              this.validatePayment(paymentIntent);
            }
          }
        });
      }
    });
  }

  validatePayment(paymentIntent: any) {
    //Make http call to verify the payment
    var params = {
      orderId: `${this.siteService.order.id}`,
      siteId: `${this.siteService.domain}`,
      token: `${this.stripePaymentIntent}`,
      payment_intent: `${paymentIntent.id}`,
      isLinkPayment: true,
    };

    this.router.navigate(['/checkout/stripe/validate'], {
      queryParams: params,
    });
  }

  getPaymentRequest(paymentData, items) {
    return this.stripe.paymentRequest({
      country: paymentData.country,
      currency: paymentData.currency.toLowerCase(),
      total: {
        label: items.length > 0 ? 'Multiple items' : items[0].title,
        amount: Number(paymentData.total),
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerAddress: true,
      requestPayerPhone: true,
      requestShipping: true,
      // `shippingOptions` is optional at this point:
      shippingOptions: [
        // The first shipping option in this list appears as the default
        // option in the browser payment interface.
        {
          id:
            Number(paymentData.totalShippingCost) > 0
              ? 'standard-shipping'
              : 'free-shipping',
          label:
            Number(paymentData.totalShippingCost) > 0
              ? 'Standard Shipping'
              : 'Free Shipping',
          detail: 'Arrives in 5 to 7 days',
          amount:
            Number(paymentData.totalShippingCost) > 0
              ? Number(paymentData.totalShippingCost)
              : 0,
        },
      ],
    });
  }

  closeBuyNowPopUp() {
    const event = new CustomEvent('buynowPopupClose', {
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(event);
  }

  updateStripeLinkStatus(status: boolean) {
    this.isStripeLinkEnabledSubject.next(status);
  }
}
