import { tassign } from 'tassign';
import * as actions from './carousel.actions';

export interface ICarouselState {
  items: {
    imageSrc: string;
    routerLink: string;
    alt: string;
    isEnabled: boolean;
  }[];
}

export const CAROUSEL_INITIAL_STATE: ICarouselState = {
  items: [],
};

class CarouselActions {
  constructor(private state, private action) {}

  getCarousel() {
    return tassign(this.state, {
      items: this.action.items,
    });
  }
}

export function carouselReducer(
  state: ICarouselState = CAROUSEL_INITIAL_STATE,
  action
): ICarouselState {
  const carouselActions = new CarouselActions(state, action);

  switch (action.type) {
    case actions.GET_CAROUSEL_SUCCESS:
      return carouselActions.getCarousel();
  }

  return state;
}
