<div class="navlinks">
  <ng-container *ngFor="let category of categories; let categoryIndex = index">
    <a
      *ngIf="category.routerLink === '/'"
      [routerLink]="[category.routerLink]"
      (click)="onCategoryChange(category.routerLink, $event)"
      class="font-weight-bold"
    >
      {{ category.name }}
    </a>

    <a
      *ngIf="category.routerLink !== '/' && category.children === undefined"
      [routerLink]="[category.routerLink]"
      (click)="onCategoryChange(category.routerLink, $event)"
      class="font-weight-bold"
    >
      {{ category.name }}
    </a>

    <div
      *ngIf="category.routerLink !== '/' && category.children"
      class="dropdown"
    >
      <a
        (click)="onCategoryClick(categoryIndex)"
        class="navlinks dropdown-toggle font-weight-bold"
        data-toggle="dropdown"
      >
        {{ category.name }}
      </a>

      <div class="dropdown-menu">
        <div *ngIf="activeCategory === categoryIndex">
          <div>
            <ng-container
              *ngFor="
                let subcategory of category.children;
                let subcategoryIndex = index
              "
            >
              <a
                *ngIf="subcategory.children === undefined"
                [routerLink]="[subcategory.routerLink]"
                (click)="
                  stopPropagation();
                  onCategoryChange(subcategory.routerLink, $event)
                "
                class="dropdown-item"
              >
                <span>
                  {{ subcategory.name }}
                </span>
              </a>

              <a
                *ngIf="subcategory.children"
                (click)="
                  stopPropagation(); onSubCategoryClick(subcategoryIndex)
                "
                [class.active]="activeSubcategory === subcategoryIndex"
                class="dropdown-item"
              >
                <span>
                  {{ subcategory.name }}
                </span>

                <span class="fa fa-caret-right"></span>
              </a>
            </ng-container>
          </div>

          <div *ngIf="activeSubcategory !== null && activeSubcategory >= 0">
            <a
              *ngFor="
                let subsubcategory of category.children[activeSubcategory]
                  .children
              "
              [routerLink]="[subsubcategory.routerLink]"
              (click)="
                stopPropagation();
                onCategoryChange(subsubcategory.routerLink, $event)
              "
              class="dropdown-item"
            >
              <span>
                {{ subsubcategory.name }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
