import { OnInit, Inject, Directive } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from '../../services/site/site.service';
import config from '../../../../assets/config.json';

declare let Stripe: any;

@Directive()
export class CheckoutPayStripeComponent implements OnInit {
  readonly STRIPE: any;
  orderId: string;
  redirectUrl: string;
  shipping: any;
  stripe: any;
  card: any;
  disabled: boolean;
  error: string;
  onPay: boolean;
  elements: any;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    public siteService: SiteService
  ) {
    this.STRIPE = config.STRIPE;
  }

  ngOnInit() {
    this.stripe = Stripe(this.STRIPE[`${this.siteService.domain}KEY`]);

    this.disabled = true;
    this.onPay = false;

    if (!this.orderId) {
      this.orderId = this.activatedRoute.snapshot.queryParams.orderId;
    }

    if (!this.redirectUrl) {
      this.redirectUrl = this.activatedRoute.snapshot.queryParams.redirectUrl;
    }

    let appearance = {
      theme: 'stripe',
    };

    let clientSecret = this.redirectUrl;
    const options = {
      clientSecret: clientSecret,
      appearance: appearance,
    };

    this.elements = this.stripe.elements(options);
    this.card = this.elements.create('payment');

    // TO CHECK
    setTimeout(() => {
      this.card.mount('#payment-element');
      this.card.on('change', (event: any) => this.onCardChange(event));
    }, 1000);
  }

  onCardChange(event: any) {
    this.onPay = false;
    //if its google pay then enable the button
    if (event?.complete) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }

    if (event.error) {
      this.error = event.error.message;
    } else {
      this.error = null;
    }
  }

  /// This function is not being used.
  /// Go to file - checkout-pay-stripe-simple.component.ts
  // which overrides this function
  onSubmit() {
    this.onPay = true;
    let elements = this.elements;
    this.stripe
      .confirmCardPayment({
        elements,
      })
      .then((result: any) => {
        this.onPay = false;

        if (result.error) {
          this.error = result.error.message;
        } else {
          this.error = null;

          this.document.location.href =
            `${location.origin}/checkout/stripe/validate` +
            `?siteId=${this.siteService.domain}&token=${result.paymentIntent.id}&orderId=${this.orderId}`;
        }
      });
  }
}
