import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-search-mobile',
  templateUrl: './search-mobile.component.html',
  styleUrls: ['./search-mobile.component.scss'],
})
export class SearchMobileComponent implements OnInit {
  @ViewChild('searchInputEl') searchInputEl: ElementRef<
    HTMLInputElement
  >;

  height: string;
  searchText: string;
  animation: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  ngOnInit() {}

  open() {
    if (this.height) {
      this.close();
    } else {
      this.searchText = '';
      this.height = `${
        this.window.innerHeight -
        this.document
          .querySelector('app-navbar-mobile #this')
          .getBoundingClientRect().height
      }px`;
      this.animation = 'animate__animated animate__slideInUp';

      this.document.querySelector('body').style.overflow = 'hidden';
      setTimeout(() => this.searchInputEl.nativeElement.focus());
    }
  }

  close() {
    this.animation = 'animate__animated animate__slideOutDown';

    setTimeout(() => {
      const { url } = this.router;

      this.height = null;

      if (url.startsWith('/login') === false) {
        this.document.querySelector('body').style.overflow = '';
      }
    }, 250);
  }

  onSearchSubmit() {
    this.router.navigate(['/search'], {
      queryParams: {
        query: this.searchText,
      },
    });
    this.close();
  }
}
