import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SiteService } from '../site/site.service';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  url: string;
  breadcrumb: any[];
  activeBreadcrumb: any;

  constructor(private router: Router, private siteService: SiteService) {}

  setBreadcrumb(): void {
    let url = this.router.url.split('?')[0];

    if (this.url) {
      url = this.url;
    }

    this.breadcrumb = [];

    const urlSegments = url.split('/');
    const urlSegmentsActive = [...urlSegments];

    let node: any = this.siteService.navigation;
    let prefix: string = '';

    urlSegmentsActive.pop();

    for (const segment of urlSegmentsActive) {
      if (node['*'] === undefined && node[segment] === undefined) {
        break;
      }

      if (node['*']) {
        node = node['*'];
      } else {
        node = node[segment];
      }

      prefix += segment + '/';

      if (node._name) {
        this.breadcrumb.push({
          route: prefix,
          name: node._name,
        });
      }
    }

    const lastRoute = urlSegments.pop();

    if (node[lastRoute]) {
      this.activeBreadcrumb = {
        route: `${prefix}${lastRoute}`,
        name: node[lastRoute]._name,
      };
    } else {
      this.breadcrumb.pop();
      this.activeBreadcrumb = {
        route: `${prefix}${lastRoute}`,
        name: node._name,
      };
    }
  }
}
