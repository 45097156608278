import { OnInit, Output, EventEmitter, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, AbstractControl } from '@angular/forms';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { OrdersService } from '../../ecommerce/services/orders/orders.service';
import { ItemsViewedService } from '../../ecommerce/services/items-viewed/items-viewed.service';
import { SocialLoginService } from '../../ecommerce/services/social-login/social-login.service';
import { WishListService } from '../../ecommerce/services/wish-list/wish-list.service';
import { SeoService } from '../../ecommerce/seo/seo.service';

@Directive()
export class LoginComponent implements OnInit {
  readonly MIN_PASSWORD_LENGTH: number;
  readonly MAX_PASSWORD_LENGTH: number;

  @Output() signup: EventEmitter<void>;

  loginForm: FormGroup;
  loginError: string;
  recaptchaValue: string;
  passwordType: string;

  instragramToken: string;
  instagramUserId: string;

  onLogin: string;
  onInstagramLogin: string;

  showModal: boolean;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected cartService: CartService,
    protected ordersService: OrdersService,
    protected itemsViewedService: ItemsViewedService,
    protected wishListService: WishListService,
    protected seoService: SeoService,

    public siteService: SiteService,
    public socialLoginService: SocialLoginService
  ) {
    this.MIN_PASSWORD_LENGTH = 8;
    this.MAX_PASSWORD_LENGTH = 20;

    this.signup = new EventEmitter<void>();
  }

  ngOnInit() {
    this.passwordType = 'password';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  get passwordTypeClass(): string {
    if (this.passwordType === 'password') {
      return 'fa fa-eye-slash';
    }

    return 'fa fa-eye';
  }

  get onSocialLogin(): boolean {
    return (
      this.socialLoginService.onFacebookLogin === 'true' ||
      this.socialLoginService.onGoogleLogin === 'true' ||
      this.socialLoginService.onUpdateuserId === 'true' ||
      this.onInstagramLogin === 'true'
    );
  }

  saveRedirectURL() {
    const { redirectTo } = this.activatedRoute.snapshot.queryParams;

    if (redirectTo) {
      this.cookieService.set('redirectURL', redirectTo);
    } else {
      this.cookieService.set('redirectURL', this.router.url);
    }
  }

  onSignUpClick() {
    this.signup.emit();
  }

  onRecaptchaChange(recaptcha: any) {
    this.recaptchaValue = null;
    this.recaptchaValue = recaptcha.recaptchaAPI.getResponse();
  }

  onGoogleLoginClick() {
    this.saveRedirectURL();
    setTimeout(() => this.socialLoginService.goToGoogleLogin());
  }

  onFacebookLoginClick() {
    if (this.siteService.isMobileView) {
      this.saveRedirectURL();
    }

    setTimeout(() => this.socialLoginService.loginFacebook());
  }

  onInstagramLoginClick() {
    this.saveRedirectURL();
    setTimeout(() => this.socialLoginService.goToInstagramLogin());
  }

  onPasswordForgottenClick() {
    setTimeout(() => {
      this.router.navigate(['/account/forgot']);
    }, 500);
  }

  onPasswordViewClick() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  onHide() {
    this.showModal = false;
  }
}
