import {
  Component,
  Output,
  EventEmitter,
  HostListener,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';

import { NavLinksComponent } from './nav-links.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';

import currencies from '../../../assets/currencies.json';

@Component({
  selector: 'app-nav-links-mobile',
  templateUrl: './nav-links-mobile.component.html',
  styleUrls: ['./nav-links-mobile.component.scss'],
})
export class NavLinksMobileComponent extends NavLinksComponent {
  @ViewChild('menuEl') menuEl: ElementRef<HTMLDivElement>;
  @ViewChild('titleEl') titleEl: ElementRef<HTMLDivElement>;
  @ViewChild('bottomLinksEl') bottomLinksEl: ElementRef<HTMLDivElement>;
  @Output() logout: EventEmitter<void>;

  currencyList: any[];

  height: string;
  categoriesHeight: string;
  maxBottomLinksHeight: string;
  paneAnimation: string;
  showLinks: boolean;
  showCompanyLinks: boolean;
  showSellLinks: boolean;
  showSupportLinks: boolean;
  showTrendingLinks: boolean;
  showSitesLinks: boolean;
  showViewAccount: boolean;
  canClose: boolean;

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    protected router: Router,
    protected siteService: SiteService,
    protected cookieService: CookieService
  ) {
    super(router, siteService);

    this.logout = new EventEmitter<void>();
    this.currencyList = currencies;
  }

  setHeights() {
    setTimeout(() => {
      const height =
        this.window.innerHeight -
        this.document
          .querySelector('app-navbar-mobile #this')
          .getBoundingClientRect().height;

      this.maxBottomLinksHeight = `${height / 2}px`;

      setTimeout(() => {
        const titleHeight =
          this.titleEl.nativeElement.getBoundingClientRect().height;
        const bottomLinksHeight =
          this.bottomLinksEl.nativeElement.getBoundingClientRect().height;

        this.height = `${height}px`;
        this.categoriesHeight = `${height - titleHeight - bottomLinksHeight}px`;
      });
    });
  }

  open() {
    if (this.height) {
      this.close();
    } else {
      this.document.querySelector('body').style.overflow = '';

      this.activeCategory = null;
      this.activeSubcategory = null;
      this.showLinks = false;
      this.showCompanyLinks = false;
      this.showSellLinks = false;
      this.showSupportLinks = false;
      this.showTrendingLinks = false;
      this.showSitesLinks = false;
      this.showViewAccount = false;
      this.paneAnimation = 'animate__animated animate__slideInLeft';
      this.height = '0px';

      this.document.querySelector('body').style.overflow = 'hidden';

      this.setHeights();
      setTimeout(() => (this.canClose = true), 250);
    }
  }

  close() {
    this.canClose = false;
    this.paneAnimation = 'animate__animated animate__slideOutLeft';

    setTimeout(() => {
      const { url } = this.router;
      this.height = null;

      this.height = null;

      if (url.startsWith('/login') === false) {
        this.document.querySelector('body').style.overflow = '';
      }
    }, 250);
  }

  onCategoryClick(categoryIndex: number) {
    this.activeSubcategory = null;

    if (this.activeCategory !== categoryIndex) {
      this.activeCategory = categoryIndex;
    } else {
      this.activeCategory = null;
    }
  }

  onSubCategoryClick(subcategoryIndex: number) {
    if (this.activeSubcategory && this.activeSubcategory === subcategoryIndex) {
      this.activeSubcategory = null;
    } else {
      this.activeSubcategory = subcategoryIndex;
    }
  }

  onCategoryChange(routerLink: string) {
    super.onCategoryChange(routerLink);
    this.close();
  }

  onCurrencyChange() {
    this.cookieService.set('currency', this.siteService.currency);

    document.dispatchEvent(
      new CustomEvent('currencyChange', {
        bubbles: true,
        cancelable: true,
      })
    );

    this.close();
  }

  onLoginClick() {
    this.router.navigate(['/login'], {
      queryParams: { redirectTo: this.router.url },
    });
    this.close();
  }

  onRegisterClick() {
    this.router.navigate(['/register'], {
      queryParams: { redirectTo: this.router.url },
    });
    this.close();
  }

  onLinksClick() {
    this.showLinks = !this.showLinks;
    this.setHeights();
  }

  onCompanyLinksClick() {
    this.showSellLinks = false;
    this.showSupportLinks = false;
    this.showSitesLinks = false;
    this.showTrendingLinks = false;
    this.showCompanyLinks = !this.showCompanyLinks;

    this.setHeights();
  }

  onSellLinksClick() {
    this.showCompanyLinks = false;
    this.showSupportLinks = false;
    this.showSitesLinks = false;
    this.showTrendingLinks = false;
    this.showSellLinks = !this.showSellLinks;

    this.setHeights();
  }

  onSupportLinksClick() {
    this.showCompanyLinks = false;
    this.showSellLinks = false;
    this.showSitesLinks = false;
    this.showTrendingLinks = false;
    this.showSupportLinks = !this.showSupportLinks;

    this.setHeights();
  }

  onTrendingLinksClick() {
    this.showCompanyLinks = false;
    this.showSellLinks = false;
    this.showSitesLinks = false;
    this.showSupportLinks = false;
    this.showTrendingLinks = !this.showTrendingLinks;

    this.setHeights();
  }

  onSitesLinksClick() {
    this.showCompanyLinks = false;
    this.showSellLinks = false;
    this.showSupportLinks = false;
    this.showTrendingLinks = false;
    this.showSitesLinks = !this.showSitesLinks;

    this.setHeights();
  }

  onViewAccountClick() {
    this.showViewAccount = !this.showViewAccount;
    this.setHeights();
  }

  onLogout() {
    this.close();
    setTimeout(() => this.logout.emit(), 500);
  }

  @HostListener('body:click', ['$event.target'])
  onBodyClick(target: HTMLElement) {
    if (
      this.menuEl &&
      this.menuEl.nativeElement.contains(target) === false &&
      target.className.includes('form-control') === false &&
      this.canClose
    ) {
      this.close();
    }
  }
}
