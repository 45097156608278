import { Component } from '@angular/core';
import { AdAreaComponent } from './ad-area.component';

@Component({
  selector: 'ad-area-desktop',
  templateUrl: './ad-area-desktop.component.html',
  styleUrls: ['./ad-area-desktop.component.scss'],
})
export class AdAreaDesktopComponent extends AdAreaComponent {
  constructor() {
    super();
  }

  ngOnInit() {}
}
