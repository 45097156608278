import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { MiscContentService } from '../../ecommerce/services/misc-content/misc-content.service';
import { SiteService } from '../../ecommerce/services/site/site.service';

import SSR_SHOW from '../../../assets/ssr.json';

@Component({
  selector: 'app-stripbar',
  templateUrl: './stripbar.component.html',
  styleUrls: ['./stripbar.component.scss'],
})
export class StripbarComponent implements OnInit {
  stripbars: any[];
  currentStripbarIndex: number;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private siteService: SiteService,
    private miscContentService: MiscContentService
  ) {}

  get stripbar(): any {
    if (this.stripbars) {
      return this.stripbars[this.currentStripbarIndex];
    }

    return null;
  }

  ngOnInit() {
    if (
      this.siteService.IS_BROWSER ||
      (this.siteService.IS_SSR && SSR_SHOW.global.stripbar)
    ) {
      this.miscContentService.getStripBar().subscribe((data: any) => {
        if (data.status === 'success' && data.data) {
          this.stripbars = data.data;
          this.currentStripbarIndex = 0;

          if (this.stripbars.length > 1) {
            setTimeout(() => this.animateStripbar());
          }
        }
      });
    }
  }

  animateStripbar() {
    const stripbar = this.document.querySelector('app-stripbar a');

    setInterval(() => {
      stripbar.classList.add('animate__fadeIn');
      setTimeout(() => stripbar.classList.remove('animate__fadeIn'), 1000);

      if (this.currentStripbarIndex < this.stripbars.length - 1) {
        this.currentStripbarIndex += 1;
      } else {
        this.currentStripbarIndex = 0;
      }
    }, 5000);
  }
}
