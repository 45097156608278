<app-carousel-items
  *ngIf="articles"
  [nbItemsDisplayed]="nbShowedBoxes"
  [width]="width"
  [items]="articles"
  [$item]="itemTemplate"
></app-carousel-items>

<ng-template let-article #itemTemplate>
  <div [ngStyle]="articleCardStyle" class="blog-item">
    <a
      *ngIf="article.href; else routerLinkArticle"
      [href]="article.href"
      target="_blank"
    >
      <ng-container
        *ngTemplateOutlet="articleBox; context: { article: article }"
      ></ng-container>
    </a>

    <ng-template #routerLinkArticle>
      <a *ngIf="article.routerLink" [routerLink]="article.routerLink">
        <ng-container
          *ngTemplateOutlet="articleBox; context: { article: article }"
        ></ng-container>
      </a>

      <a *ngIf="article.href" [href]="article.href">
        <ng-container
          *ngTemplateOutlet="articleBox; context: { article: article }"
        ></ng-container>
      </a>
    </ng-template>
  </div>
</ng-template>

<ng-template #articleBox let-article="article">
  <div class="blog-image">
    <img [src]="article.imageSrc" [alt]="article.title" class="w-100" />
  </div>

  <h5 [style.text-align]="textAlign" style="font-weight: 500">
    {{ article.title }}
  </h5>
  <h6
    *ngIf="article.subtitle"
    [style.text-align]="textAlign"
    class="text-secondary"
  >
    {{ article.subtitle }}
  </h6>
</ng-template>
