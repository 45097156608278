import { tassign } from 'tassign';
import * as productGridActions from './product-grid.actions';

export interface IProductGridState {
  ads: any;
  topSellings: any;
}

export const PRODUCT_GRID_STATE: IProductGridState = {
  ads: {},
  topSellings: {},
};

class ProductGridActions {
  constructor(private state, private action) {}

  getAds() {
    const { ads } = this.action;

    return tassign(this.state, {
      ads,
    });
  }

  getTopSellings() {
    const { topSellings } = this.action;

    return tassign(this.state, {
      topSellings,
    });
  }
}

export function productGridReducer(
  state: IProductGridState = PRODUCT_GRID_STATE,
  action
): IProductGridState {
  const actions = new ProductGridActions(state, action);

  switch (action.type) {
    case productGridActions.GET_ADS_SUCCESS:
      return actions.getAds();
    case productGridActions.GET_TRENDING_SUCCESS:
      return actions.getTopSellings();
  }

  return state;
}
