<table style="margin-top: 10%">
  <tr style="display: flex" *ngFor="let item of cartItems" class="tr-margin">
    <td style="min-width: 62%">
      <a
        [routerLink]="[
          '/nav',
          item.category,
          item.subCat,
          item.childCat,
          'item',
          siteService.getPathSegmentFromTitle(item.title),
          item.id
        ]"
        style="min-width: 25%"
      >
        <img
          class="cart-prod-image"
          [class.d-none]="onImageLoad"
          [src]="
            item.image[0].indexOf('http') == 0
              ? item.image[0]
              : siteService.IMAGE_BUCKET + item.image[0]
          "
          [alt]="item.title"
          (load)="onImageLoaded()"
        />

        <span *ngIf="onImageLoad" class="fa fa-spin fa-spinner fa-2x"></span>
      </a>

      <div>
        <a
          [routerLink]="[
            '/nav',
            item.category,
            item.subCat,
            item.childCat,
            'item',
            siteService.getPathSegmentFromTitle(item.title),
            item.id
          ]"
          class="font-weight-bold"
        >
          {{ item.brand }}
        </a>
        <a
          [routerLink]="[
            '/nav',
            item.category,
            item.subCat,
            item.childCat,
            'item',
            siteService.getPathSegmentFromTitle(item.title),
            item.id
          ]"
        >
          {{ item.title }}
        </a>
      </div>
    </td>
    <td>
      <span *ngIf="onChange" class="fa fa-spinner fa-spin fa-2x"></span>

      <div *ngIf="!onChange">
        <!-- <form [formGroup]="qtyForm" class="form-group"> -->
        <div class="input-group input-group-no-border">
          <input
            #qtyInput
            [ngClass]="{
              'border border-danger': qtyForm.controls.qty.invalid
            }"
            (change)="onQtyChange(item)"
            (blur)="onQtyBlur(item)"
            class="form-control input-simple"
            type="number"
            min="1"
            required
            [(ngModel)]="item.qty"
          />
        </div>
        <!-- </form> -->

        <a (click)="onDeleteClick(item)" href="javascript:void(0)"> Remove </a>
      </div>
    </td>
    <td>
      <p>
        x {{ siteService.currencyInfo.symbol }}
        {{ item.price | number : "1.2-2" }}
      </p>
    </td>
  </tr>
</table>

<div class="container">
  <div class="row">
    <p class="column-heading">
      <b>Subtotal</b>
    </p>
    <p class="column-value">
      {{ siteService.currencyInfo.symbol }}
      {{ subTotal | number : "1.2-2" }}
    </p>
  </div>

  <div class="row" *ngIf="couponPercent > 0">
    <p class="column-heading">
      <b>Code Applied (-{{ couponPercent }}%)</b>
    </p>
    <p class="column-value" style="color: green">
      -{{ siteService.currencyInfo.symbol }}
      {{ couponDiscount | number : "1.2-2" }}
    </p>
  </div>

  <div class="row">
    <p class="column-heading">
      <b>Shipping Fee</b>
    </p>
    <p class="column-value" *ngIf="isShipFeeLoaded">
      {{ siteService.currencyInfo.symbol }}
      {{ totalShippingCost | number : "1.2-2" }}
    </p>
    <span *ngIf="!isShipFeeLoaded" class="fa fa-spin fa-spinner fa-2x"></span>
  </div>

  <div class="row">
    <p class="column-heading">
      <b>Tax {{ taxRate }}%</b>
    </p>
    <p class="column-value" *ngIf="isShipFeeLoaded">
      {{ siteService.currencyInfo.symbol }}
      {{ tax | number : "1.2-2" }}
    </p>
    <span *ngIf="!isShipFeeLoaded" class="fa fa-spin fa-spinner fa-2x"></span>
  </div>

  <div class="row">
    <p class="column-heading">
      <b>Order Total</b>
    </p>
    <p class="column-value">
      {{ siteService.currencyInfo.symbol }}
      {{ cartTotal | number : "1.2-2" }}
    </p>
  </div>
</div>
