import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SiteService } from '../services/site/site.service';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from './blog.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';
import { MiscContentService } from '../../ecommerce/services/misc-content/misc-content.service';
import { SubscribeComponent } from '../../components/subscribe/subscribe.component';
import { SeoService } from '../seo/seo.service';

@Component({
  selector: 'app-blog-desktop',
  templateUrl: './blog-desktop.component.html',
  styleUrls: [
    './blog-desktop.component.scss',
    '../../../assets/blog/templates/AU/assets/css/style.css',
    '../../../assets/blog/templates/AU/plugins/tabler-icons/tabler-icons.min.css',
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class BlogDesktopComponent implements OnInit {
  @ViewChild(SubscribeComponent)
  subscribeComponent: SubscribeComponent;

  currentPage: number = 1;
  blogs: any[] = [];
  featuredBlogs: any[] = [];
  postoftheMonth: any;
  totalPages: number;

  constructor(
    private blogService: BlogService,
    private router: Router,
    private seoService: SeoService
  ) {
    this.getAllBlogs();
    this.getOtherData();
  }

  ngOnInit() {
    this.seoService.setTitle('Blog - Insights, Tips, and Trends');
    this.seoService.setMetaTitle('Blog - Insights, Tips, and Trends');
    this.seoService.setMetaDescription(
      'Explore the latest insights, tips, and trends in design and technology on the Qubyk AU blog. Stay updated with our expert articles on Product reviews, Product comparisons, Best product lists, Product features, Product specifications, Product benefits, Product pricing, Product recommendations, Product ratings, Product trends, Product categories, Product brands. and more..'
    );
    this.seoService.setMetaKeywords(
      'Product reviews, Product comparisons, Best product lists, Product features, Product specifications, Product benefits, Product pricing, Product recommendations, Product ratings, Product trends, Product categories, Product brands'
    );
  }

  redirectBlog(href: string) {
    this.router.navigateByUrl(`/blog/${href}`);
  }

  next() {
    this.currentPage++;
    this.getAllBlogs();
  }

  previous() {
    this.currentPage--;
    this.getAllBlogs();
  }

  getAllBlogs() {
    this.blogService.getAllBlogs(this.currentPage).subscribe((res: any) => {
      this.blogs = res.data.blogs;
      this.totalPages = Math.ceil(res.data.total / 6);
    });
  }

  onSubscribeClick() {
    this.subscribeComponent.open();
  }

  getOtherData() {
    this.blogService.getFeaturedBlogs().subscribe((res: any) => {
      this.featuredBlogs = res.data;
    });
    this.blogService.getPostOftheMonthBlog().subscribe((res: any) => {
      this.postoftheMonth = res.data;
    });
  }
}
