import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  styleUrls: ['./error.style.scss'],
  templateUrl: './error.template.html',
})
export class ErrorComponent implements OnInit {
  form: FormGroup;

  constructor(private router: Router) {}

  ngOnInit() {
    this.form = new FormGroup({
      query: new FormControl(null, [Validators.required]),
    });
  }

  onSearch(): void {
    this.router.navigate(['/search'], {
      queryParams: {
        query: this.form.controls.query.value,
      },
    });
  }
}
