import { tassign } from 'tassign';
import * as productMetaActions from './product-meta.actions';

export interface IProductMetaState {
  courierRate: number;
}

export const PRODUCT_META_INITIAL_STATE: IProductMetaState = {
  courierRate: null,
};

class ProductMetaActions {
  constructor(private state, private action) {}

  getCourierRate() {
    return tassign(this.state, {
      courierRate: this.action.courierRate,
    });
  }
}

export function productMetaReducer(
  state: IProductMetaState = PRODUCT_META_INITIAL_STATE,
  action
): IProductMetaState {
  const productMeta = new ProductMetaActions(state, action);

  switch (action.type) {
    case productMetaActions.GET_COURIER_RATES_SUCCESS:
      return productMeta.getCourierRate();
    case productMetaActions.GET_COURIER_RATES_ERROR:
      return productMeta.getCourierRate();
  }

  return state;
}
