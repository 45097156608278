<div id="this-layout" *ngIf="show">
  <app-stripbar></app-stripbar>

  <app-navbar-desktop
    (login)="onLoginClick()"
    (register)="onRegisterClick()"
    class="navbar navbar-dashboard navbar-centred"
  ></app-navbar-desktop>

  <app-nav-links-desktop></app-nav-links-desktop>

  <div class="content-wrap" id="content-wrap">
    <main id="content" class="content" role="main">
      <div class="router-component container" #routerComponent>
        <router-outlet></router-outlet>
      </div>
    </main>

    <footer [class.page-footer-border] class="page-footer">
      <ng-container>
        <div id="footer-links" class="container">
          <div class="row">
            <div class="col d-flex justify-content-center">
              <ul>
                <li>Company</li>
                <li>
                  <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
                </li>
                <li><a [routerLink]="['/cookie-policy']">Cookie Policy</a></li>
                <li><a [routerLink]="['/terms-of-use']">Terms of Use</a></li>
                <li>
                  <a href="/blog" target="_blank"> Blog </a>
                </li>
              </ul>
            </div>

            <div class="col d-flex justify-content-center">
              <ul>
                <li>Sell</li>
                <li>
                  <a href="https://portal.qubyk.com/#/register" target="_blank">
                    Register to sell
                  </a>
                </li>
                <li>
                  <a href="https://portal.qubyk.com/#/login" target="_blank">
                    Merchant Login
                  </a>
                </li>
                <li>
                  <a href="/docs/index.html#introduction" target="_blank">
                    API Documentation
                  </a>
                </li>
              </ul>
            </div>

            <div class="col d-flex justify-content-center">
              <ul>
                <li>Support</li>
                <li><a [routerLink]="['/faq']">FAQ's</a></li>
                <li><a [routerLink]="['/shipping']">Shipping</a></li>
                <li><a [routerLink]="['/returns']">Returns</a></li>
                <li>
                  <a href="mailto:{{ siteService.supportEmail }}">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div class="col d-flex justify-content-center">
              <ul>
                <li>Trending Categories</li>
                <li
                  *ngFor="let item of this.siteService.getTrendingCategories(this.siteService.domain)"
                >
                  <a [href]="item.link">{{ item.name }}</a>
                </li>
              </ul>
            </div>

            <div class="col d-flex justify-content-center">
              <ul>
                <li>Sites</li>
                <li><a href="https://qubyk.com.au">Australia</a></li>
                <li><a href="https://qubyk.com">USA</a></li>
                <li><a href="https://qubyk.co.uk">UK</a></li>
              </ul>
            </div>

            <div class="col d-flex justify-content-center">
              <ul>
                <li>Subscribe</li>

                <li>
                  <a (click)="onSubscribeClick()">
                    Subscribe to our Newsletter
                  </a>
                </li>

                <li class="this-label">
                  <div data-toggle="modal" data-target="#this-original-modal">
                    <img
                      [src]="'assets/img/100-original-dashed.png'"
                      [alt]="'Qubyk 100% Original'"
                    />
                  </div>

                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#this-original-modal"
                  >
                    100% Original
                  </a>
                </li>

                <li class="this-label">
                  <div data-toggle="modal" data-target="#this-returns-modal">
                    <img
                      [src]="'assets/img/30-days-money-back.png'"
                      [alt]="'qubyk 30 Days Money Back'"
                    />
                  </div>

                  <a
                    href="javascript:void(0)"
                    data-toggle="modal"
                    data-target="#this-returns-modal"
                  >
                    Easy Returns 30 days
                  </a>
                </li>
                <li>
                  <div>
                    <ul class="payments">
                      <a
                        class="payment"
                        style="
                          background-repeat: no-repeat;
                          background-image: url(./assets/img/payments/stripe_logo.png);
                        "
                        href="https://stripe.com/"
                        target="_blank"
                      ></a>

                      <a
                        class="payment"
                        style="
                          width: 57px;
                          background-repeat: no-repeat;
                          background-size: contain;
                          background-image: url(./assets/img/payments/afterpay_logo.png);
                        "
                        href="https://www.afterpay.com/"
                        target="_blank"
                      ></a>
                      <a
                        class="payment"
                        style="
                          background-repeat: no-repeat;
                          background-image: url(./assets/img/payments/gpay_logo.png);
                        "
                        href="https://pay.google.com/intl/en_au/about/"
                        target="_blank"
                      ></a>
                      <a
                        class="payment"
                        style="
                          background-repeat: no-repeat;
                          background-image: url(./assets/img/payments/applepay_white_logo.png);
                        "
                        href="https://www.apple.com/apple-pay/"
                        target="_blank"
                      ></a>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>
                    <ul class="payments">
                      <a
                        class="payment"
                        style="
                          background-image: url(./assets/img/payments/American_Express_logo.svg);
                        "
                        href="https://www.americanexpress.com/"
                        target="_blank"
                      ></a>
                      <a
                        class="payment"
                        style="
                          background-image: url(./assets/img/payments/mastercard.svg);
                        "
                        href="https://www.mastercard.com/"
                        target="_blank"
                      ></a>
                      <a
                        class="payment"
                        style="
                          background-image: url(./assets/img/payments/paypal.svg);
                        "
                        href="https://www.paypal.com/"
                        target="_blank"
                      ></a>
                      <a
                        class="payment"
                        style="
                          background-image: url(./assets/img/payments/visa.svg);
                        "
                        href="https://www.visa.com/"
                        target="_blank"
                      ></a>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>

      <div id="footer-copyright-social-links">
        <div class="container">
          <div class="row">
            <div class="col">
              <a [routerLink]="['/']" class="font-title">
                &copy; {{ currentYear }} qubyk marketplace</a
              >
            </div>

            <div class="col">
              <ul class="social-icons float-right">
                <li>
                  <a
                    [href]="this.siteService.facebook"
                    target="_blank"
                    class="fa fa-facebook"
                  ></a>
                </li>
                <li>
                  <a
                    [href]="this.siteService.instagram"
                    target="_blank"
                    class="fa fa-instagram"
                  ></a>
                </li>
                <li>
                  <a
                    [href]="this.siteService.pinterest"
                    target="_blank"
                    class="fa fa-pinterest"
                  ></a>
                </li>
                <li>
                  <a
                    [href]="this.siteService.twitter"
                    target="_blank"
                    class="fa fa-twitter"
                  ></a>
                </li>
                <li>
                  <a
                    [href]="this.siteService.linkedin"
                    target="_blank"
                    class="fa fa-linkedin"
                  ></a>
                </li>
                <li>
                  <a
                    [href]="this.siteService.medium"
                    target="_blank"
                    class="fa fa-medium"
                  ></a>
                </li>
                <!-- <li>
                <a href="" class="fa fa-youtube-play"></a>
              </li>
              <li>
                <a href="" class="fa fa-pinterest"></a>
              </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>

  <a
    *ngIf="showScrollToTopButton"
    (click)="onButtonScrollToTopClick()"
    id="button-scroll-up"
    class="d-flex justify-content-center align-items-center"
  >
    <span class="fa fa-chevron-up d-block"></span>
  </a>

  <div
    *ngIf="areCookiesAccepted !== true"
    id="cookie-banner"
    class="row align-items-center"
  >
    <div class="col-11 text-center">
      <p>
        We use cookies to improve your experience on our portal and provide you
        with relevant and personalised services. By continuing on
        <span class="font-title">qubyk marketplace</span> you agree to our use
        of cookies.
      </p>
    </div>

    <div class="col-1 align-self-start">
      <button (click)="onCloseCookieBannerClick()" class="btn btn-link">
        <span class="fa fa-close"></span>
      </button>
    </div>
  </div>

  <div id="this-original-modal" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <img
            src="https://maketplace-content.s3.ap-southeast-2.amazonaws.com/pop+ups/pop-up-no-fake.jpeg"
            [alt]="'Qubyk 100% Original'"
          />
        </div>
      </div>
    </div>
  </div>

  <div id="this-returns-modal" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <img
            src="https://maketplace-content.s3.ap-southeast-2.amazonaws.com/pop+ups/pop-up-return.jpeg"
            [alt]="'Qubyk 30 Days Money Back'"
          />
        </div>
      </div>
    </div>
  </div>

  <app-login-modal-desktop (signup)="onLoginSignup()"></app-login-modal-desktop>
  <app-register-modal-desktop
    (signin)="onRegisterSignin()"
  ></app-register-modal-desktop>
  <app-subscribe></app-subscribe>

  <app-buynow-modal-desktop></app-buynow-modal-desktop>
</div>
