<h3
  *ngIf="
    productService.getProductsStatus === 'success' &&
    onGetProducts === false &&
    products.length === 0
  "
>
  Sorry, no products available.
</h3>
<h6
  style="padding-left: 12px; margin-top: 0px; margin-bottom: 12px"
  *ngIf="showTotalItems && totalItems && totalItems > 0"
>
  Total Items {{ totalItems }}
</h6>
<div [ngStyle]="style" class="products">
  <ng-container *ngFor="let product of products">
    <div class="product-card">
      <a *ngIf="isOnWishListChange(product.id)" role="button" class="star">
        <span class="fa fa-spinner fa-spin"></span>
      </a>

      <a
        *ngIf="!isOnWishListChange(product.id)"
        (click)="onStarClick(product)"
        role="button"
        class="star"
      >
        <span
          class="fa"
          [ngClass]="product.inWishList ? 'fa-heart text-danger' : 'fa-heart-o'"
        ></span>
      </a>

      <a
        [routerLink]="[
          '/nav',
          product.category,
          product.subCat,
          product.childCat,
          'product',
          siteService.getPathSegmentFromTitle(product.title),
          product.id
        ]"
        style="text-decoration: none"
      >
        <div
          class="card-photo"
          (mouseenter)="onCardMouseEnter(product)"
          (mouseleave)="onCardMouseLeave(product)"
        >
          <div class="card-image">
            <span
              *ngIf="!isImageLoaded[product.id]"
              class="fa fa-spin fa-spinner fa-2x"
            ></span>
            <img
              [class.d-none]="!isImageLoaded[product.id]"
              [src]="product.currentImage"
              [alt]="product.title"
              (load)="onCardImageLoaded(product.id)"
            />
          </div>

          <div
            class="badge"
            *ngIf="product.label"
            [ngClass]="
              product.label === 'Sale' ? 'badge-danger' : 'badge-success'
            "
          >
            {{ product.label }}
          </div>
        </div>
        <h5 class="card-title">{{ product.brand }}</h5>
        <div class="card-description">{{ product.title }}</div>
        <div class="price" *ngIf="!product.isSale">
          {{ siteService.currencyInfo.symbol }}
          {{ product.price | number : "1.2-2" }}
        </div>
        <div class="price price-sale" *ngIf="product.isSale">
          <span class="old">${{ product.price.old }}</span>
          {{ product.price.percents }}% off
          <span class="new"> ${{ product.price.new }}</span>
        </div>
      </a>

      <button
        *ngIf="!cartService.onAddItem[product.fullId]"
        (click)="onAddProductCartClick(product)"
        class="btn btn-block btn-dark"
      >
        Add To Cart
      </button>

      <button
        *ngIf="cartService.onAddItem[product.fullId] === 'success'"
        (click)="onAddProductCartClick(product)"
        class="btn btn-block- btn-dark"
      >
        Add More
      </button>

      <button
        *ngIf="cartService.onAddItem[product.fullId] === 'true'"
        [disabled]="true"
        class="btn btn-block btn-dark"
      >
        <span class="fa fa-spinner fa-spin"></span> Adding
      </button>
    </div>
  </ng-container>
</div>
