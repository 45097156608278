import { tassign } from 'tassign';
import * as qubykQuickActions from './qubykQuick.actions';

import { IQubykQuickBox } from '../../models/qubyk-quick/IQubykQuickBox.model';

export interface IQubykQuickState {
  boxes: IQubykQuickBox[];
}

export const QUBYK_QUICK_INITIAL_STATE: IQubykQuickState = {
  boxes: [],
};

class QubykQuickActions {
  constructor(private state, private action) {}

  getBoxes() {
    return tassign(this.state, {
      boxes: this.action.boxes,
    });
  }
}

export function qubykQuickReducer(
  state: IQubykQuickState = QUBYK_QUICK_INITIAL_STATE,
  action
): IQubykQuickState {
  const actions = new QubykQuickActions(state, action);

  switch (action.type) {
    case qubykQuickActions.GET_QUBYK_QUICK_BOXES_SUCCESS:
      return actions.getBoxes();
  }

  return state;
}
