<div id="breadcrumb">
  <div class="container">
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li
            *ngFor="let item of breadcrumbService.breadcrumb"
            class="breadcrumb-item"
          >
            <a
              *ngIf="onProductGrid === false || item.name === 'Home'"
              [routerLink]="[item.route]"
            >
              {{ item.name }}
            </a>
            <a
              *ngIf="onProductGrid === true && item.name !== 'Home'"
              [routerLink]="[item.route]"
              [queryParams]="queryParams"
            >
              {{ item.name }}
            </a>
          </li>

          <li class="breadcrumb-item active font-weight-normal">
            {{ breadcrumbService.activeBreadcrumb.name }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>
