import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { CookieService } from '../cookie/cookie.service';
import { SiteService } from '../site/site.service';
import { AccountService } from '../account/account.service';
import { CartService } from '../cart/cart.service';
import { OrdersService } from '../orders/orders.service';
import { ItemsViewedService } from '../items-viewed/items-viewed.service';
import config from '../../../../assets/config.json';
import { environment } from '../../../../environments/environment';

declare let $: any;
declare let FB: any;

@Injectable({
  providedIn: 'root',
})
export class SocialLoginService {
  readonly API_FACEBOOK_PREFIX: string;
  readonly API_GOOGLE_PREFIX: string;
  readonly API_INSTAGRAM_PREFIX: string;
  readonly GOOGLE: any;
  readonly INSTAGRAM: any;

  onGoogleLogin: string;
  onFacebookLogin: string;
  onUpdateuserId: string;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private siteService: SiteService,
    private accountService: AccountService,
    private cartService: CartService,
    private ordersService: OrdersService,
    private itemsViewedService: ItemsViewedService
  ) {
    this.API_FACEBOOK_PREFIX = `${environment.apiUrl}/social-login/api/facebook`;
    this.API_GOOGLE_PREFIX = `${environment.apiUrl}/social-login/api/google`;
    this.API_INSTAGRAM_PREFIX = `${environment.apiUrl}/social-login/api/instagram`;

    this.GOOGLE = config.GOOGLE;
    this.INSTAGRAM = config.INSTAGRAM;
  }

  generateGoogleToken(params: any): Observable<any> {
    return this.http
      .post(`${this.API_GOOGLE_PREFIX}/generate-token`, params)
      .pipe(
        catchError((error: any) => {
          console.error('Error generating Google token:', error);
          return throwError(error);
        })
      );
  }

  validateGoogleUser(params: any): Observable<any> {
    return this.http
      .post(`${this.API_GOOGLE_PREFIX}/validate-user`, params)
      .pipe(
        catchError((error: any) => {
          console.error('Error validating Google user:', error);
          return throwError(error);
        })
      );
  }

  validateFacebookUser(params: any): Observable<any> {
    return this.http.post(`${this.API_FACEBOOK_PREFIX}/validate-user`, params);
  }

  validateFacebookToken(params: any): Observable<any> {
    return this.http.post(`${this.API_FACEBOOK_PREFIX}/validate-token`, params);
  }

  generateInstagramToken(params: any): Observable<any> {
    return this.http.post(
      `${this.API_INSTAGRAM_PREFIX}/generate-token`,
      params
    );
  }

  isInstagramRegistered(params: any): Observable<any> {
    return this.http.post(`${this.API_INSTAGRAM_PREFIX}/isRegistered`, params);
  }

  validateInstagramUser(params: any): Observable<any> {
    return this.http.post(`${this.API_INSTAGRAM_PREFIX}/validate-user`, params);
  }

  goToGoogleLogin() {
    this.document.location.href =
      `https://accounts.google.com/o/oauth2/auth?response_type=code&redirect_uri=${
        this.GOOGLE[`${this.siteService.domain}REDIRECTION_URL`]
      }` +
      `&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&client_id=${this.GOOGLE.CLIENT_ID}`;
  }

  goToInstagramLogin() {
    this.document.location.href =
      `https://api.instagram.com/oauth/authorize?app_id=${this.INSTAGRAM.CLIENT_ID}` +
      `&redirect_uri=${this.INSTAGRAM.REDIRECTION_URL}&scope=user_profile,user_media&response_type=code`;
  }

  login(user: any, hideModal: boolean = false) {
    const cartId = this.cookieService.get('cartId');
    const order = this.cookieService.get('order');

    this.siteService.user = user;
    this.cookieService.set('user', JSON.stringify(user));

    if (cartId) {
      this.siteService.cartId = cartId;
    }

    if (order) {
      this.siteService.order = JSON.parse(order);
    }

    if (this.siteService.cartId) {
      this.cartService.getAllItems();
    }

    if (this.siteService.order) {
      this.ordersService.getOrder();
    }

    // Update user id
    const params = {
      siteId: this.siteService.domain,
      tempId: this.siteService.userId,
      userId: this.siteService.user.id,
      currency: this.siteService.currency,
    };

    this.onUpdateuserId = 'true';

    this.itemsViewedService.updateUserId(params).subscribe(
      () => {
        const redirectURL = this.cookieService.get('redirectURL');

        if (redirectURL) {
          this.cookieService.delete('redirectURL');
        }

        if (hideModal) {
          this.accountService.getMember();
          this.itemsViewedService.clear();
          this.itemsViewedService.get();

          document.dispatchEvent(
            new CustomEvent('closeLogin', {
              bubbles: true,
              cancelable: true,
            })
          );
          document.dispatchEvent(
            new CustomEvent('closeRegister', {
              bubbles: true,
              cancelable: true,
            })
          );

          if (redirectURL) {
            this.router.navigate([redirectURL]);
          }
        } else {
          if (redirectURL) {
            location.href = `${location.protocol}//${location.host}${redirectURL}`;
          } else {
            location.href = `${location.protocol}//${location.host}`;
          }
        }

        this.onUpdateuserId = 'success';
      },
      () => {
        this.onUpdateuserId = 'error';
        this.siteService.unsetUserId();
        location.href = `${location.protocol}//${location.host}`;
      }
    );
  }

  loginGoogle(authCode: string) {
    const setErrorAndRedirect = () => {
      this.onGoogleLogin = 'error';
      setTimeout(() => this.router.navigate(['/']), 3000);
    };
    let params: any = {
      authCode,
      siteId: this.siteService.domain,
    };

    this.onGoogleLogin = 'true';

    this.generateGoogleToken(params).subscribe(
      (res: any) => {
        if (res.status === 'Success') {
          params = {
            accessToken: res.data.accessToken,
            siteId: this.siteService.domain,
          };

          this.validateGoogleUser(params).subscribe(
            (res2: any) => {
              if (res2.status === 'Success') {
                const { data } = res2;
                const user = {
                  id: data.memberId,
                  token: data.token,
                  tokenType: 'google',
                };

                this.onGoogleLogin = 'success';
                this.login(user);
              } else {
                setErrorAndRedirect();
              }
            },
            () => {
              setErrorAndRedirect();
            }
          );
        } else {
          setErrorAndRedirect();
        }
      },
      () => {
        setErrorAndRedirect();
      }
    );
  }

  loginFacebook() {
    FB.login(
      (res: any) => {
        const token = res.authResponse.accessToken;
        const params = {
          facebookToken: token,
          siteId: this.siteService.domain,
        };

        this.onFacebookLogin = 'true';

        this.validateFacebookUser(params).subscribe(
          (res: any) => {
            const { data } = res;

            this.validateFacebookToken(params).subscribe(
              () => {
                const user = {
                  id: data.memberId,
                  token,
                  tokenType: 'facebook',
                };

                this.onFacebookLogin = 'success';

                this.login(user, this.siteService.isMobileView === false);
              },
              () => {
                this.onFacebookLogin = 'error';
              }
            );
          },
          () => {
            this.onFacebookLogin = 'error';
          }
        );
      },
      { scope: 'public_profile,email' }
    );
  }
}
