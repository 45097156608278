<ul class="navbar-nav flex-row" (click)="$event.preventDefault()">
  <li class="nav-item">
    <a [routerLink]="['/']">
      <span
        style="
          font: 100 35px/1.3 'Caveat', Helvetica, sans-serif;
          color: #000;
          font-weight: 100;
          padding-left: 10px;
          text-decoration: none;
        "
      >
        qubyk
      </span>

      <span>{{ siteService.domainExtension }}</span>
    </a>
  </li>
</ul>

<!-- this part is hidden for xs screens -->
<!-- search form! link it to your search server -->
<form class="navbar-form mr-auto" role="search" (submit)="onDashboardSearch()">
  <div class="form-group">
    <div class="input-group input-group-no-border">
      <input
        class="form-control"
        type="text"
        placeholder="search here"
        name="searchText"
        [(ngModel)]="searchText"
      />
      <span class="input-group-append">
        <span class="input-group-text">
          <i class="fa fa-search"></i>
        </span>
      </span>
    </div>
  </div>
</form>
<ul class="navbar-nav mr-3 d-flex flex-row" (click)="$event.preventDefault()">
  <li class="nav-item">
    <a href="#" class="nav-link">
      <div class="input-group input-group-no-border app-custom-select">
        <select
          [(ngModel)]="siteService.currency"
          (change)="onCurrencyChange()"
          class="form-control"
        >
          <option
            *ngFor="let currency of currencyList"
            [ngValue]="currency.code"
          >
            {{ currency.name }} - {{ currency.symbol }}
          </option>
        </select>
      </div>
    </a>
  </li>

  <ng-container *ngIf="!siteService.user && !hideLogin">
    <li class="nav-item dropdown">
      <a (click)="onLoginClick()" href="javascript:void()" class="nav-link">
        Login
      </a>
    </li>
    <li class="nav-item dropdown">
      <a (click)="onRegisterClick()" href="javascript:void()" class="nav-link">
        Register
      </a>
    </li>
  </ng-container>

  <ng-container *ngIf="siteService.user">
    <li class="nav-item dropdown">
      <a
        [routerLink]="['/account/orders-history']"
        class="nav-link font-weight-bold"
      >
        View Account
      </a>
    </li>

    <li class="nav-item dropdown">
      <a (click)="onLogoutClick()" class="nav-link font-weight-bold">
        Logout
      </a>
    </li>
  </ng-container>

  <!-- <li
    class="nav-item dropdown"
    dropdown
    placement="bottom right"
    [autoClose]="false"
  >
    <a
      href="#"
      dropdownToggle
      class="nav-link dropdown-toggle dropdown-toggle-notifications"
      id="notifications-dropdown-toggle"
    >
      <i class="fa fa-history fa-lg"></i>
    </a>

    <app-notifications
      *dropdownMenu
      class="dropdown-menu dropdown-menu-right animated animated-fast fadeInUp"
    ></app-notifications>
  </li> -->

  <li *ngIf="!siteService.user">
    <a (click)="onWishListClick()" class="nav-link">
      <span class="fa fa-heart fa-lg"></span>
    </a>
  </li>

  <ng-container>
    <li>
      <a *ngIf="siteService.user" [routerLink]="['/wishlist']" class="nav-link">
        <span class="fa fa-heart fa-lg"></span>

        <span
          *ngIf="nbItemsInWishList"
          class="fw-normal badge badge-pill badge-danger p-1 text-white"
        >
          {{ nbItemsInWishList }}
        </span>
      </a>
    </li>

    <li class="nav-item" dropdown placement="bottom right">
      <a [routerLink]="['/cart']" class="nav-link">
        <i class="fa fa-shopping-cart fa-lg"></i>

        <span
          *ngIf="nbItemsInCart && nbItemsInCart > 0"
          class="fw-normal badge badge-pill badge-success p-1 text-white"
        >
          {{ nbItemsInCart }}
        </span>
      </a>
    </li>
  </ng-container>
</ul>
