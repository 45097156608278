<div id="this">
  <div
    id="this-wrapper"
    [style.width]="width + 'px'"
    [style.overflow-x]="showScrollBar ? 'auto' : 'hidden'"
    [style.margin-bottom]="marginBotton + 'px'"
    (mousedown)="onMousedown($event)"
    (touchstart)="onTouchdown($event)"
    (touchmove)="onTouchmove($event, list.scrollWidth)"
    (touchend)="onTouchup($event, list)"
  >
    <div
      id="this-carousel"
      [attr.startPress]="startPress"
      [style.transition]="startPress > 0 ? 'none' : '-webkit-transform 1s'"
      [style.webkitTransition]="
        startPress > 0 ? 'none' : '-webkit-transform 1s'
      "
      [style.transform]="'translateX(' + amount + 'px)'"
      [style.webkitTransform]="'translateX(' + amount + 'px)'"
      #list
    >
      <ng-template
        *ngFor="let item of items; let i = index"
        [ngTemplateOutlet]="$item"
        [ngTemplateOutletContext]="{
          $implicit: item,
          index: i,
          selectedIndex: childIndex
        }"
      ></ng-template>
    </div>

    <br />
  </div>

  <div *ngIf="!hideControls && showControls" id="this-controls">
    <button
      [disabled]="amount >= 0"
      (click)="scroll(false, list)"
      id="this-button-left"
      type="button"
    >
      <i class="fa fa-angle-left fa-2x"></i>
    </button>

    <button
      [disabled]="amount <= -(list.scrollWidth - width)"
      (click)="scroll(true, list)"
      id="this-button-right"
      type="button"
    >
      <i class="fa fa-angle-right fa-2x"></i>
    </button>
  </div>
</div>
