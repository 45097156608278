import {
  Component,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { SubCategoryAreaComponent } from './subcategory-area.component';

@Component({
  selector: 'subcategory-area-mobile',
  templateUrl: './subcategory-area-mobile.component.html',
  styleUrls: ['./subcategory-area-mobile.component.scss'],
})
export class SubCategoryAreaMobileComponent
  extends SubCategoryAreaComponent
  implements AfterViewInit
{
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
    this.DEFAULT_NB_SHOWED_BOXES = 1;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.view) {
      this.view = 'slider';
    }

    this.setConfiguration();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  setConfiguration() {
    if (this.width === undefined) {
      this.width = window.innerWidth;
    }

    if (this.nbShowedBoxes === undefined) {
      if (window.innerWidth <= 500) {
        this.nbShowedBoxes = 2;
      } else {
        this.nbShowedBoxes = this.DEFAULT_NB_SHOWED_BOXES;
      }
    }

    const width = `${100 / this.nbShowedBoxes}%`;

    this.imageStyle = {
      flex: `0 0 ${width}`,
      'max-width': width,
    };
  }
}
