import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgRedux } from '@angular-redux2/store';
import { Observable } from 'rxjs';

import {
  GET_ORDER_SUCCESS,
  CREATE_ORDER_SUCCESS,
  CLEAR_ORDER_SUCCESS,
  REQ_ORDER_ERROR,
} from '../../store/orders.action';

import { IOrdersState } from '../../store/orders.store';
import { SiteService } from '../site/site.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  readonly API_PREFIX: string;

  formChanged: boolean;
  onCreate: string;
  onGet: string;
  onUpdate: string;
  onUpdateUser: string;

  constructor(
    private http: HttpClient,
    private ngRedux: NgRedux<IOrdersState>,
    private siteService: SiteService
  ) {
    this.API_PREFIX = `${environment.apiUrl}/marketplace-orders/orders`;

    this.onUpdate = 'false';
  }

  async getOrder() {
    this.onGet = 'true';

    let userId = this.siteService?.user?.id ?? this.siteService?.userId;
    this.http
      .get(`${this.API_PREFIX}/${userId}/${this.siteService.order.id}`, {
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        (data: any) => {
          if (data.status === 'error') {
            this.siteService.unsetOrder();
            this.onGet = 'error';
          } else if (data && data.data) {
            const { cartId, shippingCost, shippingAddress, billingAddress } =
              data.data;
            const order = {
              id: this.siteService.order.id,
              cartId,
              shippingCost,
              shippingAddress,
              billingAddress,
            };

            this.onGet = 'success';

            document.dispatchEvent(
              new CustomEvent('gotOrder', {
                bubbles: true,
                cancelable: true,
                detail: order,
              })
            );

            return this.ngRedux.dispatch({
              type: GET_ORDER_SUCCESS,
              ...order,
            });
          }
        },
        () => {
          this.onGet = 'error';

          this.siteService.unsetOrder();

          return this.ngRedux.dispatch({
            type: REQ_ORDER_ERROR,
          });
        }
      );
  }

  getOrderById(id: string): Observable<any> {
    let userId = this.siteService?.user?.id ?? this.siteService?.userId;

    return this.http.get(`${this.API_PREFIX}/${userId}/${id}`, {
      headers: this.siteService.getAuthorizationHeader(),
    });
  }

  getAllOrders(start: number = 0, limit: number = 12): Observable<any> {
    return this.http.get(
      `${this.API_PREFIX}/all/${this.siteService.user.id}/${start}/${limit}`,
      { headers: this.siteService.getAuthorizationHeader() }
    );
  }

  getOrderItems(orderId: string): Observable<any> {
    return this.http.get(
      `${this.API_PREFIX}/items/${
        this.siteService?.user?.id ?? this.siteService.userId
      }/${orderId}`,
      { headers: this.siteService.getAuthorizationHeader() }
    );
  }

  createOrder(params: any) {
    this.onCreate = 'true';

    this.http
      .post(`${this.API_PREFIX}/v2/create`, params, {
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        (data: any) => {
          const { status } = data;

          if (data.status === 'Success') {
            const { id, shippingCost, shippingDetails } = data.data;

            //write your logic here
            // 1. simply dispatch the shippingDetails obj here
            this.onCreate = 'success';
            this.formChanged = false;
            this.siteService.setOrder(id);

            if (shippingDetails) {
              return this.ngRedux.dispatch({
                type: CREATE_ORDER_SUCCESS,
                ...params,
                status,
                id,
                shippingCost,
                shippingDetails,
              });
            } else {
              this.onCreate = 'error';

              return this.ngRedux.dispatch({
                type: REQ_ORDER_ERROR,
                status: 'Failed',
              });
            }
          } else {
            this.onCreate = 'error';

            return this.ngRedux.dispatch({
              type: REQ_ORDER_ERROR,
              status,
            });
          }
        },
        () => {
          this.onCreate = 'error';

          return this.ngRedux.dispatch({
            type: REQ_ORDER_ERROR,
            status: 'Failed',
          });
        }
      );
  }

  //We created this async one.
  //For all future createOrder requests use the below one
  createOrderAsync(params: any): Promise<any> {
    this.onCreate = 'true';

    return new Promise<any>((resolve) => {
      this.http
        .post(`${this.API_PREFIX}/v2/create`, params, {
          headers: this.siteService.getAuthorizationHeader(),
        })
        .subscribe(
          (data: any) => {
            const { status } = data;

            if (data.status === 'Success') {
              const { id, shippingCost, shippingDetails } = data.data;

              //write your logic here
              // 1. simply dispatch the shippingDetails obj here
              this.onCreate = 'success';
              this.formChanged = false;
              this.siteService.setOrder(id);

              if (shippingDetails) {
                this.ngRedux.dispatch({
                  type: CREATE_ORDER_SUCCESS,
                  ...params,
                  status,
                  id,
                  shippingCost,
                  shippingDetails,
                });

                resolve(data.data);
              } else {
                this.onCreate = 'error';

                this.ngRedux.dispatch({
                  type: REQ_ORDER_ERROR,
                  status: 'Failed',
                });

                resolve(null);
              }
            } else {
              this.onCreate = 'error';

              this.ngRedux.dispatch({
                type: REQ_ORDER_ERROR,
                status,
              });

              resolve(null);
            }
          },
          () => {
            this.onCreate = 'error';

            this.ngRedux.dispatch({
              type: REQ_ORDER_ERROR,
              status: 'Failed',
            });

            resolve(null);
          }
        );
    });
  }

  updateOrder(params: any) {
    this.onUpdate = 'true';
    this.formChanged = true;

    this.http
      .put(`${this.API_PREFIX}/v2/update`, params, {
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        (data: any) => {
          const { status } = data;

          if (data.status === 'Success') {
            const { id, shippingCost, shippingDetails } = data.data;

            this.onUpdate = 'success';
            this.formChanged = false;
            this.siteService.setOrder(id);

            if (shippingDetails) {
              return this.ngRedux.dispatch({
                type: CREATE_ORDER_SUCCESS,
                ...params,
                status,
                id,
                shippingCost,
                shippingDetails,
              });
            } else {
              this.onUpdate = 'error';
              this.siteService.unsetOrder();

              return this.ngRedux.dispatch({
                type: REQ_ORDER_ERROR,
                status: 'Failed',
              });
            }
          } else {
            this.onUpdate = 'error';
            this.siteService.unsetOrder();

            return this.ngRedux.dispatch({
              type: REQ_ORDER_ERROR,
              status,
            });
          }
        },
        () => {
          this.onUpdate = 'error';
          this.siteService.unsetOrder();

          return this.ngRedux.dispatch({
            type: REQ_ORDER_ERROR,
            status: 'Failed',
          });
        }
      );
  }

  //We created this async one.
  //For all future createOrder requests use the below one
  updateOrderAsync(params: any): Promise<any> {
    this.onUpdate = 'true';
    this.formChanged = true;

    return new Promise<number>((resolve) => {
      this.http
        .put(`${this.API_PREFIX}/v2/update`, params, {
          headers: this.siteService.getAuthorizationHeader(),
        })
        .subscribe(
          (data: any) => {
            const { status } = data;

            if (data.status === 'Success') {
              const { id, shippingCost, shippingDetails } = data.data;

              this.onUpdate = 'success';
              this.formChanged = false;
              this.siteService.setOrder(id);

              if (shippingDetails) {
                this.ngRedux.dispatch({
                  type: CREATE_ORDER_SUCCESS,
                  ...params,
                  status,
                  id,
                  shippingCost,
                  shippingDetails,
                });

                resolve(data.data);
              } else {
                this.onUpdate = 'error';
                this.siteService.unsetOrder();

                this.ngRedux.dispatch({
                  type: REQ_ORDER_ERROR,
                  status: 'Failed',
                });

                resolve(null);
              }
            } else {
              this.onUpdate = 'error';
              this.siteService.unsetOrder();

              this.ngRedux.dispatch({
                type: REQ_ORDER_ERROR,
                status,
              });

              resolve(null);
            }
          },
          () => {
            this.onUpdate = 'error';
            this.siteService.unsetOrder();

            this.ngRedux.dispatch({
              type: REQ_ORDER_ERROR,
              status: 'Failed',
            });

            resolve(null);
          }
        );
    });
  }

  async updateUser(updateCartParams: any) {
    this.http
      .put(`${this.API_PREFIX}/update-user`, updateCartParams, {
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        () => {
          this.onUpdateUser = 'success';
        },
        () => {
          this.onUpdateUser = 'error';
        }
      );
  }

  confirmReceivedOrderItem(orderId: string, itemId: string): Observable<any> {
    const params = {
      userId: this.siteService.user.id,
      orderId,
      itemId,
    };

    return this.http.put(`${this.API_PREFIX}/items/confirm-received`, params, {
      headers: this.siteService.getAuthorizationHeader(),
    });
  }

  confirmReviewed(params: any) {
    return this.http.put(`${this.API_PREFIX}/items/confirm-reviewed`, params, {
      headers: this.siteService.getAuthorizationHeader(),
    });
  }

  getTotalShippingCostAndProducts(
    shippingDetails: IOrdersState['shippingDetails']
  ) {
    let totalDeliveryCost = 0;
    let products = [];

    shippingDetails?.forEach((x) => {
      //Add all products to local array
      x.products.forEach((p) => products.push(p));
      //re-order courier cost by asc n show the first one
      x.courier.sort((c, d) => c.cost - d.cost);

      totalDeliveryCost += x.courier[0].cost;
    });

    return { totalDeliveryCost, products };
  }

  clear() {
    return this.ngRedux.dispatch({
      type: CLEAR_ORDER_SUCCESS,
    });
  }
}
