import { IProduct } from '../models/products/IProduct';
import { ICartItem } from '../models/carts/ICartItem';
import { IQubykQuickBox } from '../models/qubyk-quick/IQubykQuickBox.model';

export interface IProductsState {
  products: IProduct[];
  nav: string;
  stack: boolean;
}

export const PRODUCTS_INITIAL_STATE: IProductsState = {
  products: [],
  nav: '',
  stack: false,
};

export interface ICategoriesState {
  categories: string[];
}

export const CATEGORIES_INITIAL_STATE: ICategoriesState = {
  categories: [],
};

export interface IBrandsState {
  brands: string[];
}

export const BRANDS_INITIAL_STATE: IBrandsState = {
  brands: [],
};

export interface ICarouselState {
  items: {
    imageSrc: string;
    routerLink: string;
    alt: string;
    isEnabled: boolean;
  }[];
}

export const CAROUSEL_INITIAL_STATE: ICarouselState = {
  items: [],
};

export interface ICartState {
  items: ICartItem[];
  total: number;
  totalShippingCost: number;
  tax: number;
}

export const CART_INITIAL_STATE: ICartState = {
  items: [],
  total: 0,
  totalShippingCost: 0,
  tax: 0,
};

export interface IWishListState {
  items: ICartItem[];
  ids: string[];
}

export const WISH_LIST_INITIAL_STATE: IWishListState = {
  items: [],
  ids: [],
};

export interface IOrdersState {
  id: string;
  cartId: string;
  shippingAddress: {
    title: string;
    firstName: string;
    lastName: string;
    street: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    phone: string;
  };
  billingAddress: {
    title: string;
    firstName: string;
    lastName: string;
    street: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    phone: string;
  };
  status: string;
  shippingCost: number;
}

export const ORDERS_INITIAL_STATE: IOrdersState = {
  id: '',
  cartId: '',
  shippingAddress: {
    title: '',
    firstName: '',
    lastName: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    phone: '',
  },
  billingAddress: {
    title: '',
    firstName: '',
    lastName: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    phone: '',
  },
  status: '',
  shippingCost: 0,
};

export interface IItemsViewedState {
  products: any[];
}

export const ITEMS_VIEWED_INITIAL_STATE: IItemsViewedState = {
  products: [],
};

export interface IMemberState {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  address2: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export const MEMBER_INITIAL_STATE: IMemberState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  address2: '',
  suburb: '',
  state: '',
  postcode: '',
  country: '',
};

export interface IQubykQuickState {
  boxes: IQubykQuickBox[];
}

export const QUBYK_QUICK_INITIAL_STATE: IQubykQuickState = {
  boxes: [],
};

export interface IProductGridState {
  ads: any;
  topSellings: any;
}

export const PRODUCT_GRID_STATE: IProductGridState = {
  ads: {},
  topSellings: {},
};
