<div id="this-coupon" class="form-group">
  <form [formGroup]="couponForm">
    <div class="row" style="width: 80%">
      <div class="col">
        <div class="input-group input-group-no-border">
          <input
            (input)="onCouponCodeInput()"
            formControlName="coupon"
            type="text"
            class="form-control form-control-lg input-simple"
            placeholder="Coupon Code"
            required
          />

          <span
            *ngIf="cf.coupon.value && discount !== null"
            class="input-group-append"
          >
            <span class="input-group-text">
              <span
                *ngIf="discount > 0"
                class="fa fa-check text-success"
              ></span>
              <span
                *ngIf="discount === 0"
                class="fa fa-times text-danger"
              ></span>
            </span>
          </span>
        </div>
      </div>
      <div class="col">
        <button
          *ngIf="!onCouponCheck"
          (click)="onCouponApplyClick()"
          class="btn btn-dark"
        >
          Apply
        </button>

        <button *ngIf="onCouponCheck" class="btn btn-dark">
          <span class="fa fa-spinner fa-spin"></span>
        </button>
      </div>
    </div>
  </form>
</div>
