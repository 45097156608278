import { Component, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NavbarComponent } from './navbar.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { ItemsViewedService } from '../../ecommerce/services/items-viewed/items-viewed.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { WishListService } from '../../ecommerce/services/wish-list/wish-list.service';

@Component({
  selector: 'app-navbar-mobile',
  templateUrl: './navbar-mobile.template.html',
  styleUrls: ['./navbar-mobile.style.scss'],
})
export class NavbarMobileComponent extends NavbarComponent {
  @Output() menuClick: EventEmitter<void>;
  @Output() searchClick: EventEmitter<void>;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected itemsViewedService: ItemsViewedService,
    protected cartService: CartService,
    protected wishListService: WishListService,

    public router: Router,
    public siteService: SiteService
  ) {
    super(
      router,
      activatedRoute,
      cookieService,
      accountService,
      itemsViewedService,
      cartService,
      wishListService,
      siteService
    );

    this.menuClick = new EventEmitter<void>();
    this.searchClick = new EventEmitter<void>();
  }

  get showFilters(): boolean {
    const { url } = this.router;

    return (
      (url.startsWith('/nav') &&
        url.includes('/product/') === false &&
        url.endsWith('qubyk-quick') === false) ||
      url.startsWith('/search')
    );
  }

  onMenuClick() {
    this.menuClick.emit();
  }

  onSearchClick() {
    this.searchClick.emit();
  }

  onFiltersClick() {
    document.dispatchEvent(
      new CustomEvent('filters', {
        cancelable: true,
        bubbles: true,
      })
    );
  }
}
