import { tassign } from 'tassign';
import { ICartItem } from '../../models/carts/ICartItem';
import * as cartActions from './cart.actions';

declare let $: any;

export interface ICartState {
  items: ICartItem[];
  total: number;
  totalShippingCost: number;
  tax: number;
  shippingCountry: string;
  couponCode: string;
  couponDiscount: number;
  couponDiscountAmount: number;
}

export const CART_INITIAL_STATE: ICartState = {
  items: [],
  total: 0,
  totalShippingCost: 0,
  tax: 0,
  shippingCountry: null,
  couponCode: null,
  couponDiscount: 0,
  couponDiscountAmount: 0,
};

class CartActions {
  constructor(private state, private action) {}

  getCartState(): ICartState {
    return this.state;
  }

  getCart() {
    return tassign(this.state, {
      items: this.action.items,
      total: this.action.total,
      totalShippingCost: this.action.totalShippingCost,
      tax: this.action.tax,
      shippingCountry: this.action.shippingCountry,
    });
  }

  addItem() {
    const {
      item,
      shippingCost,
      total,
      totalShippingCost,
      qty,
      tax,
      shippingCountry,
      couponCode,
      couponDiscount,
      couponDiscountAmount,
    } = this.action;

    const product = item;

    const stateItems = JSON.parse(JSON.stringify(this.state.items));
    let foundProduct = stateItems.find(
      (item: any) => item.fullId === product.fullId
    );

    if (foundProduct) {
      foundProduct.stock = qty; //TODO: remove stock in cartItem
      foundProduct.qty = qty;
      foundProduct.shippingFee = shippingCost;
    } else {
      let {
        id,
        fullId,
        brand,
        title,
        size,
        currency,
        price,
        rrp,
        qty,
        discount,
        image,
        category,
        subCat,
        childCat,
        isVariation,
        variationSku,
        isQubyk,
        hasCourier,
      } = product;

      qty = product.qty = 1;

      if (discount === undefined) {
        discount = 0;
      }

      stateItems.push({
        id,
        fullId,
        brand,
        title,
        size,
        qty,
        currency,
        price,
        rrp,
        discount,
        image,
        category,
        subCat,
        childCat,
        isVariation,
        variationSku,
        isQubyk,
        hasCourier,
        shippingCost: shippingCost,
      });
    }

    return tassign(this.state, {
      items: stateItems,
      total,
      totalShippingCost: totalShippingCost,
      tax: tax,
      shippingCountry: shippingCountry,
    });
  }

  addCoupon() {
    const {
      total,
      totalShippingCost,
      tax,
      shippingCountry,
      couponCode,
      couponDiscount,
      couponDiscountAmount,
    } = this.action;
    const stateItems = JSON.parse(JSON.stringify(this.state.items));

    return tassign(this.state, {
      items: stateItems,
      total,
      totalShippingCost,
      tax,
      shippingCountry,
      couponCode,
      couponDiscount,
      couponDiscountAmount,
    });
  }

  updateItem() {
    const { item, total, totalShippingCost, tax, shippingCountry } =
      this.action;
    const stateItems = JSON.parse(JSON.stringify(this.state.items));
    let product;
    let itemsTotal = 0;

    if (item.isVariation) {
      product = stateItems.find(
        (product: any) =>
          product.id === item.prodId &&
          product.variationSku === item.variationSku
      );
    } else {
      product = stateItems.find((product: any) => product.id === item.prodId);
    }

    product.qty = item.qty;
    product.stock = item.qty; //TODO: Remove this after backend services are updated
    // product.shippingCost = shippingCost;

    for (const item of stateItems) {
      itemsTotal += (item.price - item.discount) * item.stock;
    }

    return tassign(this.state, {
      items: stateItems,
      total: itemsTotal,
      totalShippingCost: totalShippingCost,
      tax: tax,
      shippingCountry: shippingCountry,
    });
  }

  deleteItem() {
    let items = JSON.parse(JSON.stringify(this.state.items));
    let total = this.action.total;
    let totalShippingCost = this.action.totalShippingCost;
    let tax = this.action.tax;

    items = items.filter((item) => item.fullId !== this.action.fullId);

    return tassign(this.state, {
      items,
      total,
      totalShippingCost,
      tax: tax,
    });
  }

  clearCart() {
    if (this.action.message != null) return this.state;

    return tassign(this.state, CART_INITIAL_STATE);
  }

  convertCart() {
    const {
      total,
      totalShippingCost,
      tax,
      shippingCountry,
      couponCode,
      couponDiscount,
      couponDiscountAmount,
    } = this.action;
    const stateItems = JSON.parse(JSON.stringify(this.state.items));

    return tassign(this.state, {
      items: stateItems,
      total,
      totalShippingCost,
      tax,
      shippingCountry,
      couponCode,
      couponDiscount,
      couponDiscountAmount,
    });
  }
}

export function cartReducer(
  state: ICartState = CART_INITIAL_STATE,
  action
): ICartState {
  const actions = new CartActions(state, action);

  switch (action.type) {
    case cartActions.GET_CART_SUCCESS:
      return actions.getCart();
    case cartActions.ADD_CART_SUCCESS:
      return actions.addItem();
    case cartActions.ADD_CART_COUPON_SUCCESS:
      return actions.addCoupon();
    case cartActions.UPDATE_CART_SUCCESS:
      return actions.updateItem();
    case cartActions.CLEAR_CART:
      return actions.clearCart();
    case cartActions.DELETE_ITEM_SUCCESS:
      return actions.deleteItem();
    case cartActions.REQ_CART_ERROR:
      return actions.clearCart();
    case cartActions.CART_CONVERT_SUCCESS:
      return actions.convertCart();
    case cartActions.CART_CONVERT_ERROR:
      return actions.clearCart();
  }

  return state;
}
