import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgRedux } from '@angular-redux2/store';
import { Observable } from 'rxjs';

import {
  GET_MEMBER_SUCCESS,
  UPDATE_MEMBER_SUCCESS,
  REQ_MEMBER_ERROR,
} from '../../store/member.action';

import { IMemberState } from '../../store/member.store';

import { SiteService } from '../site/site.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  readonly API_PREFIX: string;

  onUpdateMember: string;

  constructor(
    private http: HttpClient,
    private ngRedux: NgRedux<IMemberState>,
    private siteService: SiteService
  ) {
    this.API_PREFIX = `${environment.apiUrl}/account/api`;
  }

  getMember() {
    const { id, token } = this.siteService.user;
    const url = `${this.API_PREFIX}/account/member/${id}`;

    this.http
      .get(url, { headers: this.siteService.getAuthorizationHeader() })
      .subscribe(
        (data: any) => {
          return this.ngRedux.dispatch({
            type: GET_MEMBER_SUCCESS,
            member: data,
          });
        },
        () => {
          return this.ngRedux.dispatch({
            type: REQ_MEMBER_ERROR,
          });
        }
      );
  }

  updateMember(params: any) {
    const { id, token } = this.siteService.user;
    const url = `${this.API_PREFIX}/account/member/${id}`;
    const headers = {
      Authorization: this.siteService.getAuthorizationHeader().Authorization,
      'Content-Type': 'application/json',
    };

    this.onUpdateMember = 'true';

    this.http.put(url, params, { headers }).subscribe(
      (data: any) => {
        this.onUpdateMember = 'success';

        return this.ngRedux.dispatch({
          type: UPDATE_MEMBER_SUCCESS,
          member: params,
        });
      },
      () => {
        this.onUpdateMember = 'error';

        return this.ngRedux.dispatch({
          type: REQ_MEMBER_ERROR,
        });
      }
    );
  }

  register(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}/register/member`, params);
  }

  login(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}/login/member`, params);
  }

  logout(params: any): Observable<any> {
    const headers = { Authorization: params.token };
    return this.http.post(`${this.API_PREFIX}/logout`, params, { headers });
  }

  forgot(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}/forgot/member`, params);
  }

  reset(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}/reset/member`, params);
  }

  activate(token: string): Observable<any> {
    return this.http.post(
      `${this.API_PREFIX}/account/activate/member/${token}`,
      {},
      { responseType: 'text' }
    );
  }

  validate(): Observable<any> {
    const { token } = this.siteService.user;

    return this.http.get(`${this.API_PREFIX}/validate/${token}`, {
      headers: this.siteService.getAuthorizationHeader(),
    });
  }
}
