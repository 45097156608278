<app-breadcrumb [url]="'/blog'"></app-breadcrumb>
<section *ngIf="blogData" class="bg-body blog-detail">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-9 col-lg-10">
        <div class="section">
          <p class="mb-4 text-muted">{{ blogData?.readTime }} min reading in</p>
          <h1 class="mb-3">{{ blogData?.title }}</h1>
          <p class="mb-4 pb-1">
            {{ blogData?.subTitle }}
          </p>
          <div class="post-author d-flex">
            <div class="flex-shrink-0">
              <a
                href="author-single.html"
                class="is-hoverable"
                title="Read all posts of - Robert Britt"
              >
                <img
                  loading="lazy"
                  class="rounded-circle w-auto"
                  src="../../../assets/blog/templates/AU/assets/images/author/qubyk-logo.png"
                  [alt]="blogData?.editor"
                  width="50"
                  height="50"
                />
              </a>
            </div>
            <div class="flex-grow-1 ms-3">
              by
              <a class="text-link" title="Read all posts by - Robert Britt">{{
                blogData?.editor
              }}</a>
              <p class="mb-0 lh-base">
                Published at {{ blogData?.updated | date }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <img
          loading="lazy"
          class="w-100 h-auto"
          [src]="blogData?.imageSrc"
          [alt]="blogData?.title"
          width="1020"
          height="660"
        />
      </div>
      <div class="col-xl-9 col-lg-10">
        <div class="section inner-html">
          <div class="content">
            <div [innerHTML]="blogData?.details"></div>
          </div>
          <div class="row justify-content-between align-items-center mt-5 pt-3">
            <div class="col-xl-9">
              <ul class="taxonomy-lists list-unstyled list-inline">
                <li
                  *ngFor="let tag of blogData?.tags"
                  class="list-inline-item me-2 mt-2"
                >
                  <p class="bg-white">{{ tag }}</p>
                </li>
              </ul>
            </div>
            <div class="col-xl-3">
              <ul
                class="list-inline social-share text-start text-left mt-4 mt-sm-0"
              >
                <li class="list-inline-item d-block mb-2 text-start">Share:</li>
                <li class="list-inline-item lead text-center is-hoverable">
                  <a
                    [href]="this.siteService.instagram"
                    target="_blank"
                    class="ti ti-brand-instagram"
                  ></a>
                </li>
                <li class="list-inline-item lead text-center is-hoverable ms-3">
                  <a
                    [href]="this.siteService.twitter"
                    target="_blank"
                    class="ti ti-brand-twitter"
                  ></a>
                </li>

                <li class="list-inline-item lead text-center is-hoverable ms-3">
                  <a
                    [href]="this.siteService.facebook"
                    target="_blank"
                    class="ti ti-brand-facebook"
                  ></a>
                </li>
                <li class="list-inline-item lead text-center is-hoverable ms-3">
                  <a
                    [href]="this.siteService.linkedin"
                    target="_blank"
                    class="ti ti-brand-linkedin"
                  ></a>
                </li>
                <li class="list-inline-item lead text-center is-hoverable ms-3">
                  <a
                    [href]="this.siteService.pinterest"
                    target="_blank"
                    class="ti ti-brand-pinterest"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section" *ngIf="blogData?.relatedPosts.length > 0">
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="section-title text-center">
            <h2 class="h3 mb-0 title">Related Posts</h2>
          </div>
          <div class="row gy-5 gx-4 g-xl-5">
            <div
              *ngFor="let related of blogData?.relatedPosts"
              class="col-lg-4 col-md-6"
            >
              <article class="bg-white d-flex flex-column h-100">
                <div class="p-4 pb-0">
                  <ul class="post-meta list-inline mb-3">
                    <li class="list-inline-item">
                      <i class="ti ti-calendar-event me-1"></i>2nd Jul, 2022
                    </li>
                    <li class="list-inline-item">•</li>
                    <li class="list-inline-item">
                      <i class="ti ti-clock-2 me-1"></i
                      >{{ blogData?.readTime }} min read
                    </li>
                  </ul>
                  <div class="position-relative">
                    <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                      <a
                        class="text-link stretched-link"
                        (click)="redirectBlog(related)"
                        title="{{ related }}"
                        >{{ related }}</a
                      >
                    </h3>
                    <p class="mb-0 line-clamp clamp-2"></p>
                  </div>
                </div>
                <div class="post-author mt-auto p-4 pt-3">
                  <a
                    href="author-single.html"
                    class="is-hoverable"
                    title="Read all posts of - Robert Britt"
                  >
                    <img
                      loading="lazy"
                      class="w-auto rounded-circle me-2"
                      src="../../../assets/blog/templates/AU/assets/images/author/qubyk-logo.png"
                      alt="Robert Britt"
                      width="26"
                      height="26"
                    />
                  </a>
                  by
                  <a
                    href="author-single.html"
                    class="text-link"
                    title="Read all posts of - Robert Britt"
                    >Robert Britt</a
                  >
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- start newsletter -->
<section class="line-bg bg-white">
  <div class="newsletter-block border-bottom">
    <div class="container">
      <div
        class="row gy-5 align-items-center justify-content-center text-center text-md-start"
      >
        <div class="col-xl-5 col-lg-5 col-md-6 col-sm-10">
          <div class="pe-0 pe-xl-4">
            <h2 class="mb-3 lh-sm">Subscribe to our monthly newsletter</h2>
            <p class="mb-0">
              Stay up-to-date about latest tech and new world. Unsubscribe at
              anytime!
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-6">
          <div class="ps-0 ps-xl-4">
            <div id="mc_embed_signup">
              <div id="mc_embed_signup_scroll" class="input-group">
                <div class="input-group-append w-100">
                  <button
                    type="button"
                    name="subscribe"
                    (click)="onSubscribeClick()"
                    id="mc-embedded-subscribe"
                    class="input-group-text w-100 mb-0"
                    aria-label="Subscription Button"
                  >
                    Subscribe Now <i class="ti ti-arrow-up-right ms-auto"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- end newsletter -->

<app-subscribe></app-subscribe>
