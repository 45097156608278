import {
  Component,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
} from '@angular/core';
// import isEqual from 'lodash.isequal';
import { isEqual } from 'lodash';
import { WINDOW } from '@ng-toolkit/universal';

export interface SelectedItemInterface {
  item: any;
  index: number;
  first: boolean;
}

@Component({
  selector: 'app-carousel-items',
  styleUrls: ['./carousel-items.component.scss'],
  templateUrl: './carousel-items.component.html',
})
/*
 *
 * @param() startAt - index of slide to render first. Default to 0.
 * @param() items - List of items to belong in carousel
 * @param() width - Size of window(view) to show
 * @param() $item - Template for the item
 */
export class CarouselItemsComponent implements AfterViewInit {
  childIndex: number = 0;
  amount: number = 0;
  startPress: number = 0;
  lastX: number = 0;

  @Input()
  startAt = 0;

  @Input()
  nbItemsDisplayed: number;

  @Input()
  items: Array<any> = [];

  @Input()
  width: number = 500;

  @Input()
  marginBotton: number = 0;

  @Input()
  hideControls: boolean;

  @Input()
  showScrollBar: boolean;

  @Input()
  $item: TemplateRef<any>;

  @Output()
  onSelectedItem: EventEmitter<SelectedItemInterface> = new EventEmitter();

  @ViewChild('list', { static: true })
  list: ElementRef;

  get showControls() {
    if (this.nbItemsDisplayed == undefined) {
      return true;
    }

    return this.items.length > this.nbItemsDisplayed;
  }
  constructor(@Inject(WINDOW) private window: Window) {}

  onMousedown(e: MouseEvent) {
    if (e.which === 1) {
      this.startPress = e.clientX;
      this.lastX = this.amount;
    }
  }

  onTouchdown(e: TouchEvent) {
    this.startPress = e.targetTouches[0].clientX;
    this.lastX = this.amount;
  }

  onTouchmove(e: TouchEvent, maxWidth: number) {
    const amount = this.lastX - (this.startPress - e.targetTouches[0].clientX);
    if (amount > 0 || amount < -(maxWidth - this.width)) return;
    this.amount = amount;
  }

  onTouchup(e: TouchEvent, elem: any) {
    this.startPress = 0;
    this.snap(elem);
  }

  snap(elem: any) {
    let counter = 0;
    let lastVal = 0;

    for (let i = 0; i < this.items.length; i++) {
      const el = elem.children[i];
      const style = el.currentStyle || this.window.getComputedStyle(el);

      counter +=
        el.offsetWidth +
        (parseFloat(style.marginLeft) + parseFloat(style.marginRight));

      if (this.amount <= lastVal && this.amount >= -counter) {
        this.childIndex = i;

        this.onSelectedItem.emit({
          item: this.items[this.childIndex],
          index: this.childIndex,
          first: false,
        });
        return;
      }

      lastVal = counter;
    }

    return counter;
  }

  scroll(forward: boolean, elem: any, qty = 1) {
    this.childIndex += forward ? qty : -qty;
    this.onSelectedItem.emit({
      item: this.items[this.childIndex],
      index: this.childIndex,
      first: false,
    });
    this.amount = -this.calcScroll(elem);
  }

  calcScroll(elem: any) {
    let counter = 0;
    for (let i = this.childIndex - 1; i >= 0; i--) {
      const el = elem.children[i];
      const style = el.currentStyle || this.window.getComputedStyle(el);
      counter +=
        el.offsetWidth +
        (parseFloat(style.marginLeft) + parseFloat(style.marginRight));
    }
    return counter;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.items &&
      !isEqual(changes.items.previousValue, changes.items.currentValue)
    ) {
      if (changes.items.firstChange) {
        this.onSelectedItem.emit({
          item: this.items[this.childIndex],
          index: this.childIndex,
          first: true,
        });
      }
    }
  }

  ngAfterViewInit() {
    this.startPress = 1;
    this.scroll(true, this.list.nativeElement, this.startAt);
    setTimeout(() => (this.startPress = 0));
  }
}
