// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  siteUrl: 'https://site.qubyk.com',
  productsUrl: 'https://products.qubyk.com',
  productsMetaUrl: 'https://products-meta.qubyk.com',
  apiUrl: 'https://api.qubyk.com',
  apiVersion: 'v1',
  reCaptchaSiteKey: '6Lf0w2wUAAAAAOipiNp05O0Pp1vceFY6ICdB1_cM',
};
