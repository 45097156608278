<div *ngIf="height" [style.height]="height" [class]="animation" id="this">
  <div class="app-mobile-modal-title">
    <h1>Search</h1>
    <h1 (click)="close()">
      <span class="fa fa-times"></span>
    </h1>
  </div>

  <form (submit)="onSearchSubmit()">
    <div class="form-group">
      <div class="input-group input-group-no-border">
        <input
          #searchInputEl
          [(ngModel)]="searchText"
          id="this-search-input"
          class="form-control form-control-lg input-simple"
          type="text"
          placeholder="search here"
          name="searchText"
          autofocus
        />
      </div>
    </div>

    <div>
      <button [disabled]="!searchText.trim()" class="btn btn-dark btn-block">
        <span class="fa fa-search"></span>
        Search
      </button>
    </div>
  </form>
</div>
