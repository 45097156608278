import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CouponService } from '../../ecommerce/services/coupon/coupon.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.comonent.scss'],
})
export class CouponComponent implements OnInit {
  couponForm: FormGroup;
  discount: any;
  onCouponCheck: boolean;
  coupon: any;
  @Output() couponPercent = new EventEmitter<number>();
  @Output() couponCode = new EventEmitter<string>();

  constructor(private couponService: CouponService) {
    this.couponForm = new FormGroup({
      coupon: new FormControl(''),
    });
  }

  ngOnInit() {
    this.couponPercent.emit(null);
    this.couponCode.emit(null);
    //Remove couponParams cookie as well
    this.couponService.removeCouponParams();
  }

  get cf() {
    return this.couponForm.controls;
  }

  onCouponCodeInput() {
    if (!this.cf.coupon.value) {
      this.discount = null;
    }
  }

  onCouponApplyClick() {
    const coupon = this.cf.coupon.value;
    this.onCouponCheck = true;

    this.couponService.checkCoupon(coupon).subscribe((discount: number) => {
      this.couponPercent.emit(discount);
      this.couponCode.emit(coupon);
      this.discount = discount;
      this.onCouponCheck = false;
    });
  }
}
