<div id="this">
  <h3
    [routerLink]="['/']"
    style="
      font: 100 52px/1.3 'Caveat', Helvetica, sans-serif;
      color: #232323;
      font-weight: 100;
    "
  >
    qubyk
  </h3>

  <h2 class="error-code">404</h2>

  <p class="error-info">This page does not exist.</p>
  <p class="error-help mb">You can search for a product instead.</p>

  <br />

  <form [formGroup]="form">
    <div class="form-group">
      <input
        formControlName="query"
        class="form-control form-control-lg input-no-border"
        type="text"
        placeholder="Search Products"
      />
    </div>

    <button
      [disabled]="form.invalid"
      (click)="onSearch()"
      class="btn btn-lg btn-dark"
    >
      Search <i class="fa fa-search"></i>
    </button>
  </form>
</div>
