import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgRedux } from '@angular-redux2/store';

import {
  GET_CART_SUCCESS,
  ADD_CART_SUCCESS,
  UPDATE_CART_SUCCESS,
  DELETE_ITEM_SUCCESS,
  CLEAR_CART,
  REQ_CART_ERROR,
  ADD_CART_COUPON_SUCCESS,
  CART_CONVERT_SUCCESS,
  CART_CONVERT_ERROR,
} from '../../store/cart.actions';

import { ICartState } from '../../store';
import { SiteService } from '../site/site.service';
import { ProductsService } from '../products.service';
import { environment } from '../../../../environments/environment';
import { ShippingService } from '../shipping/shipping.service';
import { CookieService } from '../cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  readonly API_PREFIX: string;

  savedAddParams: any[];
  onGetAllItems: string;
  onUpdateCurrency: string;
  onUpdateUser: string;
  onAddItem: string[];
  onUpdateItem: boolean[];
  onDeleteItem: boolean[];

  constructor(
    private router: Router,
    private http: HttpClient,
    private ngRedux: NgRedux<ICartState>,
    private siteService: SiteService,
    private productsService: ProductsService,
    private shippingService: ShippingService,
    private cookieService: CookieService
  ) {
    this.API_PREFIX = `${environment.apiUrl}/cart-service/cart`;
    this.savedAddParams = [];
    this.onGetAllItems = 'false';
    this.onUpdateCurrency = 'false';
    this.onUpdateUser = 'false';
    this.onAddItem = [];
    this.onUpdateItem = [];
    this.onDeleteItem = [];
  }

  getAllItems(): Promise<any> {
    let userId = this.siteService?.user?.id ?? this.siteService.userId;
    let cartId = this.siteService?.cartId;

    if (userId === undefined || cartId === undefined) return;

    const url = `${this.API_PREFIX}/all/${this.siteService.currency}/${cartId}/${userId}`;

    this.onGetAllItems = 'true';

    return new Promise<any>((resolve) => {
      this.http
        .get(url, { headers: this.siteService.getAuthorizationHeader() })
        .subscribe(
          (data: any) => {
            data.data.items = data.data.items.filter(
              (item) => item.image !== undefined
            );

            this.productsService.setProductsFullId(data.data.items);
            this.onGetAllItems = 'success';

            this.ngRedux.dispatch({
              type: GET_CART_SUCCESS,
              items: data.data.items,
              total: data.data.total,
              totalShippingCost: data.data.totalShippingCost,
              tax: data.data.tax,
              shippingCountry: data.data.shippingCountry,
            });

            resolve(data.data);
          },
          (error) => {
            this.siteService.unsetCartId();

            this.onGetAllItems = 'error';

            this.ngRedux.dispatch({
              type: REQ_CART_ERROR,
              items: [],
              total: 0,
              totalShippingCost: 0,
              tax: 0,
            });

            resolve(null);
          }
        );
    });
  }

  setCartIdCookie(cartId: string) {
    this.siteService.setCartId(cartId);

    const event = new CustomEvent('cartCreated', {
      bubbles: true,
      cancelable: true,
    });

    document.dispatchEvent(event);
  }

  async exeAddRequest(
    product: any,
    currentQty: number = 1,
    shippingCost: number = 0,
    shippingCountry: string = null,
    shippingGroup: any = null,
    hasCourier: boolean = false,
    notifyAddToCart: boolean = true
  ): Promise<any> {
    const { cartId, user, userId, currency, domain } = this.siteService;
    let courierCookie = this.cookieService.get('courier');
    let courier = courierCookie ? JSON.parse(courierCookie) : null;

    const params: any = {
      userId: user?.id ?? userId,
      currency,
      qty: 1,
      shippingCost: shippingCost,
      prodId: product.id,
      siteId: domain,
      country: shippingCountry ?? courier?.country ?? null, //To support traditional AddToCart pass country from cookie
      shippingGroup: shippingGroup,
      hasCourier: hasCourier,
    };

    if (cartId) {
      params.cartId = cartId;
    }

    if (product.isVariation) {
      params.isVariation = true;
      params.variationSku = product.variationSku;
    }
    return new Promise<any>((resolve) => {
      this.http
        .post(`${this.API_PREFIX}/add`, params, {
          headers: this.siteService.getAuthorizationHeader(),
        })
        .subscribe(
          (data: any) => {
            if (!this.siteService.cartId) {
              this.setCartIdCookie(data.data.id);

              if (this.siteService.order) {
                document.dispatchEvent(
                  new CustomEvent('updateOrder', {
                    bubbles: true,
                    cancelable: true,
                  })
                );
              }
            }

            if (notifyAddToCart) {
              const event = new CustomEvent('cartNewItem', {
                bubbles: true,
                cancelable: true,
              });

              document.dispatchEvent(event);
            }

            this.onAddItem[product.fullId] = 'success';

            //TODO: Also dispatch event to update CART (GET ALL items)
            //Update currentCart now -- DONT DO IT HERE
            this.getAllItems();

            this.ngRedux.dispatch({
              type: ADD_CART_SUCCESS,
              item: product,
              qty: currentQty,
              shippingCost: shippingCost, //this is for product
              total: data.data.total,
              totalShippingCost: data.data.totalShippingCost,
              tax: data.data.tax,
              shippingCountry: data.data.shippingCountry,
            });

            resolve(data.data);
          },
          (error: any) => {
            delete this.onAddItem[product.fullId];

            const event = new CustomEvent('cartNewItemError', {
              bubbles: true,
              cancelable: true,
              detail: { message: error?.error?.message ?? null },
            });

            document.dispatchEvent(event);

            this.ngRedux.dispatch({
              type: REQ_CART_ERROR,
              message: error?.error?.message ?? null,
            });

            resolve(null);
          }
        );
    });
  }

  //ShippingFee is null param here (applies for link checkout only)
  async addItem(
    product: any,
    currentQty: number = 1,
    shippingCost: number = 0,
    shippingCountry: string = null,
    shippingGroup: any = null,
    hasCourier: boolean = false,
    notifyAddToCart: boolean = true
  ): Promise<any> {
    return new Promise<any>(async (resolve) => {
      product = JSON.parse(JSON.stringify(product));

      //if (this.siteService.user) {
      let onAdd: boolean = false;

      for (const key in this.onAddItem) {
        if (this.onAddItem[key] === 'true') {
          onAdd = true;
          break;
        }
      }

      this.productsService.setProductsFullId(product);
      this.onAddItem[product.fullId] = 'true';

      if (!this.siteService.cartId && onAdd) {
        this.savedAddParams.push(product);
      } else {
        await this.exeAddRequest(
          product,
          currentQty,
          shippingCost,
          shippingCountry,
          shippingGroup,
          hasCourier,
          notifyAddToCart
        );

        resolve('Item added to cart');
      }
    });
  }

  updateItem(params: any): Promise<any> {
    const { fullId } = params;

    this.onUpdateItem[fullId] = true;

    return new Promise<any>((resolve) => {
      this.http
        .put(`${this.API_PREFIX}/update`, params, {
          headers: this.siteService.getAuthorizationHeader(),
        })
        .subscribe(
          (data: any) => {
            delete this.onUpdateItem[fullId];

            this.ngRedux.dispatch({
              type: UPDATE_CART_SUCCESS,
              item: params,
              total: data.data.total,
              tax: data.data.tax,
              totalShippingCost: data.data.totalShippingCost,
              shippingCountry: data.data.shippingCountry,
            });

            resolve(data.data);
          },
          () => {
            delete this.onUpdateItem[fullId];

            this.ngRedux.dispatch({
              type: REQ_CART_ERROR,
            });

            resolve(null);
          }
        );
    });
  }

  updateCurrency() {
    const params = {
      cartId: this.siteService.cartId,
      userId: this.siteService?.user?.id ?? this.siteService?.userId,
      currency: this.siteService.currency,
    };

    this.onUpdateCurrency = 'true';

    this.http
      .post(`${this.API_PREFIX}/update-currency`, params, {
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        () => {
          document.dispatchEvent(
            new CustomEvent('currencyUpdated', {
              bubbles: true,
              cancelable: true,
            })
          );

          this.onUpdateCurrency = 'success';
        },
        () => {
          this.onUpdateCurrency = 'error';
        }
      );
  }

  async updateUser(updateCartParams: any) {
    this.onUpdateUser = 'true';

    await this.http
      .put(`${this.API_PREFIX}/update-user`, updateCartParams, {
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        () => {
          this.onUpdateUser = 'success';
        },
        () => {
          this.onUpdateUser = 'error';
        }
      );
  }

  async updateStatus(
    updateCartParams: any,
    isQtyIncrease: boolean
  ): Promise<any> {
    return new Promise<any>((resolve) => {
      this.http
        .put(`${this.API_PREFIX}/update-cart-status`, updateCartParams, {
          headers: this.siteService.getAuthorizationHeader(),
        })
        .subscribe(() => {
          resolve(null);
        });
    });
  }

  deleteItem(params: any): Promise<any> {
    const { fullId } = params;

    this.onDeleteItem[fullId] = true;

    return new Promise<any>((resolve) => {
      this.http
        .request('delete', `${this.API_PREFIX}/remove`, {
          body: params,
          headers: this.siteService.getAuthorizationHeader(),
        })
        .subscribe(
          (data: any) => {
            delete this.onDeleteItem[fullId];

            const event = new CustomEvent('cartDeleteItem', {
              bubbles: true,
              cancelable: true,
              detail: {
                id: fullId,
              },
            });

            document.dispatchEvent(event);

            this.ngRedux.dispatch({
              type: DELETE_ITEM_SUCCESS,
              fullId: fullId,
              total: data.data.total,
              totalShippingCost: data.data.totalShippingCost,
              tax: data.data.tax,
              shippingCountry: data.data.shippingCountry,
            });

            resolve(data.data);
          },
          () => {
            delete this.onDeleteItem[fullId];

            this.ngRedux.dispatch({
              type: REQ_CART_ERROR,
            });

            resolve(null);
          }
        );
    });
  }

  clear() {
    this.onAddItem = [];
    return this.ngRedux.dispatch({
      type: CLEAR_CART,
    });
  }

  applyCoupon(params): Promise<any> {
    return new Promise<any>((resolve) => {
      this.http
        .put(`${this.API_PREFIX}/apply-coupon`, params, {
          headers: this.siteService.getAuthorizationHeader(),
        })
        .subscribe(
          (data: any) => {
            this.ngRedux.dispatch({
              type: ADD_CART_COUPON_SUCCESS,
              total: data.data.total,
              shippingCountry: data.data.shippingCountry,
              totalShippingCost: data.data.totalShippingCost,
              tax: data.data.tax,
              couponCode: data.data.couponCode,
              couponDiscount: data.data.couponDiscount,
              couponDiscountAmount: data.data.couponDiscountAmount,
            });

            resolve(data.data);
          },
          (error: any) => {
            resolve(null);
          }
        );
    });
  }

  async resetCartStatus(isQtyIncrease: boolean = false) {
    let isLinkInit = this.cookieService.get('isLinkInit');
    let addToCartUsed = this.cookieService.get('addToCartUsed');

    if (
      (isLinkInit == 'true' || addToCartUsed == 'true') &&
      this.siteService?.cartId
    ) {
      const updateCartParams = {
        cartId: this.siteService.cartId,
        userId: this.siteService?.user?.id ?? this.siteService?.userId,
        status: 'Pending',
      };

      await this.updateStatus(updateCartParams, isQtyIncrease);
    }
  }
  /**
   * Below are all cart supporting functions
   * getAddCartParams()
   */
  getNonCourierCartParams(
    nonCourierShippingFee: number,
    nonCourierShippingAddlItemFee: number,
    country: string,
    currentCartItem: any,
    cartItems: any
  ) {
    if (nonCourierShippingFee >= 0) {
      //This is for non-Courier
      let qty = currentCartItem?.qty ? currentCartItem?.qty + 1 : 1;
      //In non-Courier shipping cost is added for each addl item
      //check if this.infos.products shippingGroup of countryCode is same as any of the items in cartItems
      //if yes then dont add shippingCost to item
      let { isShippingGroupSame, isQtyIncrease } =
        this.shippingService.validateNonCourierShippingGroup(
          currentCartItem,
          cartItems,
          country
        );

      if (isShippingGroupSame && !isQtyIncrease) {
        nonCourierShippingFee = 0;
        nonCourierShippingAddlItemFee = 0;
      }

      let shippingCost = 0;
      if (isQtyIncrease) {
        shippingCost =
          currentCartItem.shippingCost +
          (qty - 1) * nonCourierShippingAddlItemFee;
      } else {
        shippingCost =
          nonCourierShippingFee + (qty - 1) * nonCourierShippingAddlItemFee;
      }

      //get shippingGroup from this.infos.product of selected country
      //NOTE: this currentItem can be this.infos.product or current cart item (update qty request or re-added)
      let shippingGroup = this.shippingService.getShippingGroupForItem(
        currentCartItem,
        country
      );

      return {
        product: currentCartItem,
        qty: qty,
        shippingCost: shippingCost,
        country: country,
        shippingGroup: shippingGroup,
      };
    }

    return null;
  }

  async convertCartItemsToBuyNowType(courier: any): Promise<any> {
    //1. check if cookies hasAddToCartUsed
    //2. if yes then convert cart items to buynow type
    //3. if no then do nothing
    //4. set cookies hasAddToCartUsed to true
    let addToCartUsed = this.cookieService.get('addToCartUsed');

    if (addToCartUsed == 'true') {
      //make a call to cartService - convert-to-buynow
      await this.resetCartStatus();

      return new Promise<any>((resolve) => {
        var params = {
          cartId: this.siteService.cartId,
          userId: this.siteService?.user?.id ?? this.siteService?.userId,
          currency: this.siteService.currency,
          country: courier.country,
          state: courier.state,
          postcode: courier.postcode,
        };

        this.http
          .post(`${this.API_PREFIX}/convert-to-buynow`, params, {
            headers: this.siteService.getAuthorizationHeader(),
          })
          .subscribe(
            (data: any) => {
              this.ngRedux.dispatch({
                type: CART_CONVERT_SUCCESS,
                total: data.data.total,
                shippingCountry: data.data.shippingCountry,
                totalShippingCost: data.data.totalShippingCost,
                tax: data.data.tax,
                couponCode: data.data.couponCode,
                couponDiscount: data.data.couponDiscount,
                couponDiscountAmount: data.data.couponDiscountAmount,
              });

              this.cookieService.delete('addToCartUsed');
              resolve(data.data);
            },
            (error: any) => {
              const event = new CustomEvent('cartNewItemError', {
                bubbles: true,
                cancelable: true,
                detail: { message: error?.error?.message ?? null },
              });

              document.dispatchEvent(event);

              //NOTE: At server side the items in the cart will be deleted.
              //Current BuyNow (add) will continue
              this.ngRedux.dispatch({
                type: CART_CONVERT_ERROR,
                message: error?.error?.message,
              });

              resolve(null);
            }
          );
      });
    }
  }
}
