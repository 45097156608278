import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { NavLinksComponent } from './nav-links.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-links-desktop',
  templateUrl: './nav-links-desktop.component.html',
  styleUrls: ['./nav-links-desktop.component.scss'],
})
export class NavLinksDesktopComponent extends NavLinksComponent {
  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected router: Router,
    protected siteService: SiteService
  ) {
    super(router, siteService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const dropdownEl = this.document.querySelector('.dropdown-menu.show');

        if (dropdownEl) {
          dropdownEl.classList.remove('show');

          this.activeCategory = null;
          this.activeSubcategory = null;
        }
      }
    });
  }

  stopPropagation() {
    event.stopPropagation();
  }

  onCategoryChange(routerLink: string, event: MouseEvent) {
    if (routerLink === this.siteService.currentPage && !event.ctrlKey) {
      location.reload();
    }
  }

  onSubCategoryClick(subcategoryIndex: number) {
    if (
      !(this.activeSubcategory && this.activeSubcategory === subcategoryIndex)
    ) {
      this.activeSubcategory = subcategoryIndex;
      this.document.querySelector('.dropdown-menu.show').scrollTo(0, 0);
    }
  }
}
