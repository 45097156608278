<ng-template #buynowModalTemplate *ngIf="showModal">
  <main class="widget-buynow-desktop-container">
    <h1 class="font-title text-center pb-4">Secure Checkout</h1>

    <section *ngIf="showCourierOpts && !showCheckoutSection">
      <h4 class="mb-0">1. Enter Postcode</h4>
      <div class="row justify-content-center">
        <form [formGroup]="courierForm" style="width: 80%">
          <ng-container
            *ngTemplateOutlet="
              courierFormContainer;
              context: { formGroup: f.courier, form: 'courier' }
            "
          >
          </ng-container>

          <div class="row mt-4">
            <div class="col text-right">
              <button
                *ngIf="onCourier === false"
                [disabled]="isCourierFormValid == false"
                (click)="onCourierSubmitClick()"
                type="button"
                class="btn btn-dark btn-lg"
              >
                Submit
              </button>
              <button
                *ngIf="onCourier === true"
                [disabled]="true"
                class="btn btn-dark btn-lg"
              >
                <span class="fa fa-spinner fa-spin"></span> Loading
              </button>
            </div>
          </div>
          <ul class="list-unstyled">
            <li *ngIf="courierRate < 0">
              <br />

              <b>Cannot ship to selected country or postcode</b>
            </li>
          </ul>
        </form>

        <ng-template
          #courierFormContainer
          let-form="form"
          let-formGroup="formGroup"
          let-controls="formGroup.controls"
        >
          <div [formGroup]="formGroup">
            <div class="form-group">
              <div
                [class.success]="
                  controls.country.pristine === false && controls.country.valid
                "
                [class.error]="
                  controls.country.pristine === false &&
                  controls.country.invalid
                "
                class="input-group input-group-no-border app-custom-select-lg"
              >
                <select
                  formControlName="country"
                  class="form-control form-control-lg"
                  (change)="onCountryChange($event)"
                >
                  <option value="">Select a country</option>
                  <option
                    *ngFor="let country of fullCountriesList"
                    [ngValue]="country.code"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group" *ngIf="infos.courier.state">
              <div
                [class.success]="
                  controls.state.pristine === false && controls.state.valid
                "
                [class.error]="
                  controls.state.pristine === false && controls.state.invalid
                "
                class="input-group input-group-no-border"
              >
                <input
                  formControlName="state"
                  type="text"
                  class="form-control form-control-lg input-simple"
                  placeholder="State"
                  required
                />
              </div>
            </div>

            <div class="form-group" *ngIf="infos.courier.postcode">
              <div
                [class.success]="
                  controls.postcode.pristine === false &&
                  controls.postcode.valid
                "
                [class.error]="
                  controls.postcode.pristine === false &&
                  controls.postcode.invalid
                "
                class="input-group input-group-no-border"
              >
                <input
                  formControlName="postcode"
                  type="text"
                  class="form-control form-control-lg input-simple"
                  placeholder="Postcode"
                  required
                />
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </section>

    <section *ngIf="showCheckoutSection">
      <section>
        <h4 class="mb-0">2. Confirm Payment</h4>

        <app-coupon
          (couponPercent)="getCouponPercent($event)"
          (couponCode)="getCouponCode($event)"
        ></app-coupon>

        <app-cart-items-desktop
          [cartItems]="products"
          [shippingFee]="totalShippingCost"
          [isShipFeeLoaded]="shipFeeLoaded"
          [selectedCurrency]="AUD"
          [subTotal]="subTotal"
          [couponPercent]="couponPercent"
          [couponDiscount]="couponDiscount"
        >
        </app-cart-items-desktop>
      </section>
      <br />
      <section class="row justify-content-center" *ngIf="isStripeLinkEnabled">
        <button
          id="payment-request-button"
          class="btn btn-lg btn-block-md btn-link"
        >
          <!-- A Stripe Element will be inserted here. -->
        </button>
      </section>

      <section class="row justify-content-center" *ngIf="!isStripeLinkEnabled">
        <button
          class="btn btn-block-md btn-dark"
          (click)="onDefaultCheckoutClick()"
        >
          Checkout
        </button>
      </section>
    </section>
  </main>
</ng-template>
