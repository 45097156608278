import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as uuid from 'uuid';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ICartItem } from '../../../models/carts/ICartItem';
import { CookieService } from '../cookie/cookie.service';
import { environment } from '../../../../environments/environment';
import config from '../../../../assets/config.json';
import routes from '../../../../assets/routes.json';
import currencies from '../../../../assets/currencies.json';
import categoriesAU from '../../../../assets/categories-au.json';
import categoriesUK from '../../../../assets/categories-uk.json';
import categoriesUS from '../../../../assets/categories-us.json';
import categoriesEU from '../../../../assets/categories-eu.json';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  readonly IMAGE_BUCKET: string;
  readonly IS_SSR: boolean;
  readonly IS_BROWSER: boolean;

  argvs: string[];

  h1: string;
  ip: string;
  siteId: string;
  user: any;
  userId: string; // Use temporarily when user is unavailable
  cartId: string;
  order: any;

  domain: string;
  domainExtension: string;
  country: string;
  countryCode: string;
  countryName: string;
  currency: string;
  supportEmail: string;
  legalEmail: string;

  facebook: string;
  instagram: string;
  twitter: string;
  pinterest: string;
  youtube: string;
  linkedin: string;
  medium: string;

  navigation: any;
  currentPageName: string;
  currentPage: string;
  previousPage: string;
  previousPreviousPage: string;

  minHeight: string;
  currentScrollY: number;
  previousScrollY: number;
  previousPreviousScrollY: number;
  scrollBackData: any;
  canScroll: boolean;
  isMobileView: boolean;

  get currencyInfo(): any {
    return currencies.find((currency: any) => currency.code === this.currency);
  }

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    @Inject(WINDOW) protected window: Window,
    @Inject(PLATFORM_ID) protected platformId: object,
    private router: Router,
    private http: HttpClient,
    private transferState: TransferState,
    private cookieService: CookieService
  ) {
    const { host } = this.document.location;
    const currency = this.cookieService.get('currency');
    const cartId = this.cookieService.get('cartId');
    const order = this.cookieService.get('order');
    let user: any = this.cookieService.get('user');
    const userId = this.cookieService.get('userId');
    let siteId: string;

    this.IMAGE_BUCKET = config.IMAGE_BUCKET;
    // this.IS_BROWSER = isPlatformBrowser(this.platformId); //this.platformId === 'server';
    // this.IS_SSR = !this.IS_BROWSER;

    this.IS_SSR = isPlatformServer(platformId);
    this.IS_BROWSER = isPlatformBrowser(platformId);

    if (this.IS_SSR) {
      this.setArgvs();
    } else {
      this.getArgvs();
    }

    siteId = this.getArgv('--siteId');
    this.scrollBackData = {};

    if (environment.production) {
      if (host.endsWith('.com.au') || siteId === 'AU') {
        this.domain = 'AU';
        this.domainExtension = '.com.au';
        this.country = 'all';
        this.countryCode = 'AU';
        this.countryName = 'Australia';

        this.facebook = 'https://www.facebook.com/qubyk.com.au';
        this.instagram = 'https://www.instagram.com/qubyk.com.au/';
        this.twitter = 'https://twitter.com/qubyk_australia';
        this.pinterest = 'https://www.pinterest.com.au/qubyk_au/';
        this.linkedin = 'https://au.linkedin.com/company/qubyk-marketplace';
        this.medium = 'https://qubyk-marketplace.medium.com/';
      } else if (host.endsWith('.com') || siteId === 'US') {
        this.domain = 'US';
        this.domainExtension = '.com';
        this.country = 'all';
        this.countryCode = 'US';
        this.countryName = 'USA';

        this.facebook = 'https://www.facebook.com/qubyk.co';
        this.instagram = 'https://www.instagram.com/qubyk_usa/';
        this.twitter = 'https://twitter.com/qubyk_australia';
        this.pinterest = 'https://www.pinterest.com.au/qubyk_us/';
        this.linkedin = 'https://au.linkedin.com/company/qubyk-marketplace';
        this.medium = 'https://qubyk-marketplace.medium.com/';
      } else if (host.endsWith('.co.uk') || siteId === 'UK') {
        this.domain = 'UK';
        this.domainExtension = '.co.uk';
        this.country = 'all';
        this.countryCode = 'GB';
        this.countryName = 'UK';

        this.facebook = 'https://www.facebook.com/qubyk.co.uk';
        this.instagram = 'https://www.instagram.com/qubyk.co.uk/';
        this.twitter = 'https://twitter.com/qubyk_australia';
        this.pinterest = 'https://www.pinterest.com.au/qubyk_uk/';
        this.linkedin = 'https://au.linkedin.com/company/qubyk-marketplace';
        this.medium = 'https://qubyk-marketplace.medium.com/';
      } else if (host.endsWith('.eu') || siteId === 'EU') {
        this.domain = 'EU';
        this.domainExtension = '.eu';
        this.country = 'all';
        this.countryCode = 'GB';
        this.countryName = 'UK';

        this.facebook = 'https://www.facebook.com/qubyk.com.au';
        this.instagram = 'https://www.instagram.com/qubyk.com.au/';
        this.twitter = 'https://twitter.com/qubyk_australia';
        this.pinterest = 'https://www.pinterest.com.au/qubyk_au/';
        this.linkedin = 'https://au.linkedin.com/company/qubyk-marketplace';
        this.medium = 'https://qubyk-marketplace.medium.com/';
      } else {
        this.domain = 'AU';
        this.domainExtension = '.com.au';
        this.country = 'all';
        this.countryCode = 'AU';
        this.countryName = 'Australia';

        this.facebook = 'https://www.facebook.com/qubyk.com.au';
        this.instagram = 'https://www.instagram.com/qubyk.com.au/';
        this.twitter = 'https://twitter.com/qubyk_australia';
        this.pinterest = 'https://www.pinterest.com.au/qubyk_au/';
        this.linkedin = 'https://au.linkedin.com/company/qubyk-marketplace';
        this.medium = 'https://qubyk-marketplace.medium.com/';
      }
    } else {
      this.domain = 'UK';
      this.domainExtension = '.co.uk';
      this.country = 'all';
      this.countryCode = 'GB';
      this.countryName = 'UK';

      this.facebook = 'https://www.facebook.com/qubyk.co.uk';
      this.instagram = 'https://www.instagram.com/qubyk.co.uk/';
      this.twitter = 'https://twitter.com/qubyk_australia';
      this.pinterest = 'https://www.pinterest.com.au/qubyk_uk/';
      this.linkedin = 'https://au.linkedin.com/company/qubyk-marketplace';
      this.medium = 'https://qubyk-marketplace.medium.com/';
    }

    this.supportEmail = `support@qubyk${this.domainExtension}`;
    this.legalEmail = `legal@qubyk${this.domainExtension}`;

    if (!this.ip && this.IS_BROWSER) {
      this.http
        .get('https://api.ipify.org/?format=json')
        .subscribe((data: any) => {
          this.ip = data.ip;
        });
    }

    if (user) {
      user = JSON.parse(user);
      this.user = user;
    } else if (userId) {
      this.userId = userId;
    } else {
      this.setFakeUserId();
    }

    if (cartId) {
      this.cartId = cartId;
    }

    if (order) {
      this.order = JSON.parse(order);
    }

    if (currency) {
      this.currency = currency;
    } else {
      switch (this.domain) {
        case 'AU':
          this.currency = 'aud';
          break;
        case 'US':
          this.currency = 'usd';
          break;
        case 'UK':
          this.currency = 'gbp';
          break;
        case 'EU':
          this.currency = 'eur';
          break;
        default:
          this.currency = 'aud';
          break;
      }
    }
  }

  /**
   * Find if an argument is passed and return its name or value.
   *
   * @param name - The argument name.
   * @returns - The argument name, value, or null if not found.
   */
  getArgv(name: string) {
    if (this.argvs) {
      const arg = this.argvs.find((argv: string) => argv.includes(name));

      if (arg) {
        if (arg.includes('=')) {
          return arg.split('=')[1];
        }

        return arg;
      }
    }

    return null;
  }

  getArgvs() {
    const key = makeStateKey<number>('argvs');
    this.argvs = this.transferState.get(key, null) as any;
  }

  isURIEncoded(uri: string): boolean {
    let encoded = true;

    try {
      encoded = uri !== decodeURIComponent(uri || '');
    } catch {
      encoded = false;
    }

    return encoded;
  }

  getTitleFromPathSegment(path: string): string {
    if (this.isURIEncoded(path) === false) {
      path = encodeURIComponent(path);
    }

    return decodeURIComponent(path)
      .replace(/-/g, ' ')
      .replace(/ and /g, ' & ')
      .split(' ')
      .map(
        (segment: string) => segment.charAt(0).toUpperCase() + segment.slice(1)
      )
      .join(' ');
  }

  getPathSegmentFromTitle(name: string) {
    if (name) {
      return name.replace(/ /g, '-').replace(/&/g, 'and').toLowerCase();
    }

    return name;
  }

  getCategoryObject(_name: string, _isEnabled: boolean = true) {
    _isEnabled = _isEnabled ? true : false;

    if (_name !== 'qubyk quick') {
      _name = this.getTitleFromPathSegment(_name);
    }

    return {
      _name,
      _isEnabled,
    };
  }

  getAuthorizationHeader(): { Authorization: string } {
    if (this.user) {
      return {
        Authorization: this.user.token,
      };
    }

    return {
      Authorization: '',
    };
  }

  getCurrencySymbol(currency: string): string {
    currency = currency.toLowerCase();
    return currencies.find((cur: any) => cur.code === currency).symbol;
  }

  setArgvs() {
    const key = makeStateKey<number>('argvs');

    this.argvs = process.argv.slice(2);

    this.transferState.set(key, this.argvs as any);
  }

  setFakeUserId() {
    this.userId = uuid.v4(); // uuidv5(this.document.location.href, uuidv5.URL);

    this.cookieService.set('userId', this.userId);
  }

  setNavigation(categoryArray?: any[]) {
    // Load categories from file if they aren't specified
    if (!categoryArray) {
      switch (this.domain) {
        case 'US':
          categoryArray = categoriesUS;
          break;
        case 'UK':
          categoryArray = categoriesUK;
          break;
        case 'EU':
          categoryArray = categoriesEU;
          break;
        default:
          categoryArray = categoriesAU;
          break;
      }
    }

    // Format the categories
    const categoryList = [
      ...new Set(categoryArray.map((category: any) => category.category)),
    ].filter((category: any) => category !== undefined);
    const categoriesFormatted = {};

    for (const categoryName of categoryList) {
      // Add main categories
      const categoryRoute = this.getPathSegmentFromTitle(categoryName);
      categoriesFormatted[categoryRoute] = this.getCategoryObject(categoryName);

      if (categoriesFormatted[categoryRoute]._isEnabled) {
        // Add sub categories in main categories
        const categoryItems = categoryArray.filter(
          (category: any) => category.category === categoryName
        );
        const subCategoryList = [
          ...new Set(categoryItems.map((category: any) => category.subCat)),
        ].filter((subCategory: any) => subCategory !== undefined);

        if (subCategoryList.length) {
          for (const subCategoryName of subCategoryList) {
            const subCategoryRoute =
              this.getPathSegmentFromTitle(subCategoryName);
            categoriesFormatted[categoryRoute][subCategoryRoute] =
              this.getCategoryObject(subCategoryName);

            if (
              categoriesFormatted[categoryRoute][subCategoryRoute]._isEnabled
            ) {
              // Add child categories in sub categories
              const subCategoryItems = categoryItems.filter(
                (category) => category.subCat === subCategoryName
              );
              const childCategoryList = [
                ...new Set(
                  subCategoryItems.map(
                    (subCategory: any) => subCategory.childCat
                  )
                ),
              ].filter((childCategory: any) => childCategory !== undefined);

              if (childCategoryList.length) {
                for (const childCategoryName of childCategoryList) {
                  const childCategoryRoute =
                    this.getPathSegmentFromTitle(childCategoryName);
                  const childCategory =
                    this.getCategoryObject(childCategoryName);

                  if (childCategory._isEnabled) {
                    categoriesFormatted[categoryRoute][subCategoryRoute][
                      childCategoryRoute
                    ] = childCategory;
                  }
                }
              }
            }
          }
        }
      }
    }

    this.navigation = routes;
    this.navigation['']['nav'] = categoriesFormatted;
  }

  setProductsImages(products: any[]) {
    if (products && products.length > 0) {
      for (const product of products) {
        let images;

        if (product.image) {
          images = product.image;
        } else {
          images = product.images;
        }

        for (let i = 0; i < images.length; i += 1) {
          if (images[i].includes(this.IMAGE_BUCKET) === false) {
            images[i] = `${this.IMAGE_BUCKET}${images[i]}`;
          }
        }

        product.currentImage = images[0];
      }
    }
  }

  setCartId(cartId: string) {
    this.cartId = cartId;
    this.cookieService.set('cartId', cartId);
  }

  setOrder(id: string) {
    this.order = {
      id,
    };

    this.cookieService.set('order', JSON.stringify(this.order));
  }

  unsetProductsImage(products: ICartItem[]) {
    for (const product of products) {
      product.image = product.image.map((image: string) =>
        image.replace(this.IMAGE_BUCKET, '')
      );
    }
  }

  unsetUserId() {
    this.userId = null;
    this.user = null;
    this.cookieService.delete('userId');
    this.cookieService.delete('user');
  }

  unsetCartId() {
    this.cartId = null;
    this.cookieService.delete('cartId');
  }

  unsetOrder() {
    this.order = null;
    this.cookieService.delete('order');
  }

  logout(redirect: boolean = true) {
    this.user = null;
    this.userId = null;
    this.cartId = null;
    this.order = null;
    this.cartId = null;

    this.cookieService.delete('user');
    this.cookieService.delete('order');
    this.cookieService.delete('cartId');
    this.cookieService.delete('userId');
    this.setFakeUserId();

    if (redirect) {
      this.router.navigate(['/']);
    }
  }

  scroll() {
    if (this.canScroll) {
      $(this.window).scrollTop(this.previousPreviousScrollY);

      this.canScroll = false;
      this.scrollBackData = {};
    }
  }

  taxRate(country): number {
    switch (country) {
      case 'AU':
        return 0;
      case 'GB':
        return 20;
      case 'AT':
        return 20;
      case 'BE':
        return 20;
      case 'BG':
        return 20;
      case 'HR':
        return 20;
      case 'CY':
        return 20;
      case 'CZ':
        return 20;
      case 'DK':
        return 20;
      case 'EE':
        return 20;
      case 'FI':
        return 20;
      case 'FR':
        return 20;
      case 'DE':
        return 20;
      case 'GR':
        return 20;
      case 'HU':
        return 20;
      case 'IE':
        return 20;
      case 'IT':
        return 20;
      case 'LV':
        return 20;
      case 'LT':
        return 20;
      case 'LU':
        return 20;
      case 'MT':
        return 20;
      case 'NL':
        return 20;
      case 'PL':
        return 20;
      case 'PT':
        return 20;
      case 'RO':
        return 20;
      case 'SK':
        return 20;
      case 'SI':
        return 20;
      case 'ES':
        return 20;
      case 'SE':
        return 20;
      default:
        return 0;
    }
  }

  getTrendingCategories(siteId) {
    let categories = [];
    switch (siteId) {
      case 'AU':
        return (categories = [
          { name: 'iPhone', link: '/search?query=iphone' },
          { name: 'Bed frame', link: '/search?query=bed%2Bframe' },
          { name: 'Mattress', link: '/search?query=mattress' },
          {
            name: 'Kids Book Shelf',
            link: '/search?query=kids%2Bbook%20shelf',
          },
          { name: 'Board Games', link: '/search?query=board%2Bgame' },
          { name: 'Treadmill', link: '/search?query=Treadmill' },
          { name: 'Trampoline', link: '/search?query=trampoline' },
          { name: 'Washing Machine', link: '/search?query=washing%2Bmachine' },
          { name: 'Sofa Bed', link: '/search?query=Sofa%2Bbed' },
          { name: 'Vacuum cleaner', link: '/search?query=vacuum%2Bcleaner' },
          {
            name: 'Electric Blankets',
            link: '/search?query=electric%2Bblanket',
          },
          { name: 'Air Fryer', link: '/search?query=Air%2Bfryer' },
          {
            name: 'Hair Straightener',
            link: '/search?query=Hair%2Bstraightener',
          },
          { name: 'Ice Maker', link: '/search?query=ice%2Bmaker' },
          { name: 'Christmas Sale', link: '/search?query=christmas' },
          { name: 'Shop Halloooween', link: '/search?query=halloween' },
        ]);
      case 'UK':
        return (categories = [
          { name: 'iPhone', link: '/search?query=iphone' },
          { name: 'Air Fryer', link: '/search?query=air%2Bfryer' },
          { name: 'Trampoline', link: '/search?query=Trampoline' },
          { name: 'Lipstick', link: '/search?query=lipstick' },
          { name: 'Eyeshadow', link: '/search?query=Eye%2Bshadow' },
          { name: 'Treadmill', link: '/search?query=Treadmill' },
          { name: 'Mattress', link: '/search?query=Mattress' },
          { name: 'Double Bed', link: '/search?query=Double%2Bbed' },
          { name: 'Dressing Table', link: '/search?query=dressing%2Btable' },
          { name: 'Shoe Rack', link: '/search?query=shoe%2Brack' },
          { name: 'Quilt Cover', link: '/search?query=Quilt%2Bcover' },
          { name: 'Bio Oil', link: '/search?query=bio%2Boil' },
          { name: 'Headphone', link: '/search?query=headphone' },
          { name: 'Hair Dryer', link: '/search?query=Hair%2BDryer' },
          { name: 'Shop Halloween', link: '/search?query=Halloween' },
          { name: 'Christmas Sale', link: '/search?query=christmas' },
        ]);
      case 'US':
        return (categories = [
          { name: 'Mattress', link: '/search?query=Mattress' },
          { name: 'iPhone', link: '/search?query=iphone' },
          { name: 'Airpods', link: '/search?query=Airpods' },
          { name: 'Bed frame', link: '/search?query=Bed%2Bframe' },
          { name: 'Coffee Maker', link: '/search?query=Coffee%2BMaker' },
          { name: 'Toaster', link: '/search?query=Toaster' },
          { name: 'Hair Dryer', link: '/search?query=Hair%2BDryer' },
          { name: 'Vacuum cleaner', link: '/search?query=vacuum%2Bcleaner' },
          { name: 'Backacks', link: '/search?query=Backpacks' },
          { name: 'Pet Supplies', link: '/search?query=pet' },
          { name: 'Treadmill', link: '/search?query=Treadmill' },
          { name: 'Air Fryer', link: '/search?query=Air%2BFryer' },
          { name: 'Lipstick', link: '/search?query=Lipstick' },
          { name: 'Eyeshadow', link: '/search?query=Eye%2Bshadow' },
          { name: 'Shop Halloween', link: '/search?query=Halloween' },
          { name: 'Christmas Sale', link: '/search?query=christmas' },
        ]);
    }
  }
}
