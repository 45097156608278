import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';

import { ProductCardComponent } from './product-card.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { ProductsService } from '../../ecommerce/services/products.service';
import { SearchService } from '../../ecommerce/services/search/search.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { WishListService } from '../../ecommerce/services/wish-list/wish-list.service';

@Component({
  selector: 'app-product-card-desktop',
  templateUrl: './product-card-desktop.component.html',
  styleUrls: [
    './product-card-desktop.component.scss',
    '../../ecommerce/styles/product-card.style.scss',
  ],
})
export class ProductCardDesktopComponent extends ProductCardComponent {
  constructor(
    @Inject(WINDOW) protected window: Window,
    protected router: Router,
    public productService: ProductsService,
    protected searchService: SearchService,

    public siteService: SiteService,
    public cartService: CartService,
    public wishListService: WishListService
  ) {
    super(
      window,
      router,
      productService,
      searchService,
      siteService,
      cartService,
      wishListService
    );
  }
}
