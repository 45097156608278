import { NgModule } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule } from 'angular-notifier';
import {
  NgRedux,
  DevToolsExtension,
  NgReduxModule,
} from '@angular-redux2/store';

import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { ErrorComponent } from './error/error.component';

import { CookieService } from 'ngx-cookie-service';

import { ROUTES } from './app.routes';

import { AppConfig } from './app.config';

import { IAppState, rootReducer, INITIAL_STATE } from './store';
import { AppModule } from './app.module';

const APP_PROVIDERS = [AppConfig];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    NgReduxModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12,
        },
        vertical: {
          position: 'bottom',
          distance: 64,
          gap: 10,
        },
      },
      behaviour: {
        stacking: false,
      },
    }),
    RouterModule.forRoot(ROUTES, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top',
    }),
    AppModule,
    BrowserTransferStateModule,
  ],
  bootstrap: [AppComponent],
  providers: [APP_PROVIDERS, CookieService],
})
export class AppBrowserModule {
  constructor(ngRedux: NgRedux<IAppState>, devTools: DevToolsExtension) {
    // Note: DevTools are not working here and
    // I belive there's a differnet way to manage it
    // console.log('is dev ' + isDevMode());
    // const enhancers = isDevMode() ? [devTools.enhancer()] : [];
    // ngRedux.configureStore(rootReducer, INITIAL_STATE);
  }
}
