import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ApplicationRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Select } from '@angular-redux2/store';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { SiteService } from './ecommerce/services/site/site.service';
import { AccountService } from './ecommerce/services/account/account.service';
import { MiscContentService } from './ecommerce/services/misc-content/misc-content.service';
import { ItemsViewedService } from './ecommerce/services/items-viewed/items-viewed.service';
import { CartService } from './ecommerce/services/cart/cart.service';
import { OrdersService } from './ecommerce/services/orders/orders.service';
import { QubykQuickService } from './ecommerce/services/qubyk-quick/qubyk-quick.service';
import { WishListService } from './ecommerce/services/wish-list/wish-list.service';
import { CookieService } from './ecommerce/services/cookie/cookie.service';
import { SeoService } from './ecommerce/seo/seo.service';

@Component({
  selector: 'app-root',
  template: `
    <div *ngIf="!isLoaded" id="this-loading-logo">
      <img src="assets/videos/qubyk-logo-animation.gif" />
    </div>

    <notifier-container></notifier-container>
    <app-session-expired></app-session-expired>

    <div [class.d-none]="siteService.IS_SSR">
      <router-outlet
        *ngIf="onGetCategories === 'success'"
        (activate)="onRouterOutletActivate($event)"
      ></router-outlet>
    </div>

    <!-- Success Notification -->
    <ng-template #successNotification let-notificationData="notification">
      <div class="this-success-notification">
        <h6 class="font-weight-bold">
          <span class="fa fa-check-circle"></span>
          {{ notificationData.message }}
        </h6>
      </div>
    </ng-template>

    <!-- Cart Notifications -->
    <ng-template #cartNotification let-notificationData="notification">
      <div class="this-cart-notification">
        <h6 class="font-weight-bold">
          <span class="fa fa-check-circle"></span>
          {{ notificationData.message }}
        </h6>

        <button [routerLink]="['/cart']" class="btn btn-block btn-dark">
          View Cart & Checkout
        </button>
      </div>
    </ng-template>

    <ng-template #cartNotificationError let-notificationData="notification">
      <div class="this-cart-notification">
        <h6 class="font-weight-bold">
          <span class="fa fa-times-circle"></span>
          {{ notificationData.message }}
        </h6>
      </div>
    </ng-template>

    <ng-template
      #buynowCancelledNotification
      let-notificationData="notification"
    >
      <div class="this-cart-notification">
        <h6>
          <span class="fa fa-exclamation"></span>
          {{ notificationData.message }}
        </h6>
      </div>
    </ng-template>

    <ng-template
      #buynowCannotShipNotification
      let-notificationData="notification"
    >
      <div class="this-cart-notification">
        <h6>
          <span class="fa fa-exclamation"></span>
          {{ notificationData.message }}
        </h6>
      </div>
    </ng-template>
  `,
  styles: [
    `
      #this-loading-logo {
        padding-top: 25vh;
      }
    `,
    `
      #this-loading-logo img {
        display: block;
        margin: auto;
        width: 300px;
        height: 300px;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  @ViewChild(SessionExpiredComponent, { static: true })
  sessionExpiredComponent: SessionExpiredComponent;
  @ViewChild('successNotification', { static: true }) successNotification: any;
  @ViewChild('cartNotification', { static: true }) cartNotification: any;
  @ViewChild('cartNotificationError', { static: true })
  cartNotificationError: any;
  @ViewChild('buynowCancelledNotification', { static: true })
  buynowCancelled: any;
  @ViewChild('buynowCannotShipNotification', { static: true })
  buynowCannotShip: any;
  @Select((s) => s.orders) order: Observable<any>;

  onGetCategories: string;
  isLoaded: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) protected platformId: object,
    private appRef: ApplicationRef,
    private router: Router,
    private notifierService: NotifierService,
    private cookieService: CookieService,
    private accountService: AccountService,
    private miscContentService: MiscContentService,
    private itemsViewedService: ItemsViewedService,
    private cartService: CartService,
    private ordersService: OrdersService,
    private qubykQuickService: QubykQuickService,
    private wishListService: WishListService,
    private seoService: SeoService,
    public siteService: SiteService
  ) {}

  ngOnInit() {
    this.onGetCategories = 'true';

    if (this.siteService.IS_BROWSER) {
      this.seoService.addGtmTagToHead();
      this.seoService.addGtmNoScriptTag();
    }

    if (this.siteService.IS_BROWSER) {
      this.miscContentService.getCategories().subscribe((data: any) => {
        if (data.status === 'success') {
          this.siteService.setNavigation(data.data);
          this.setNavigation();

          this.onGetCategories = 'success';
        }
      });
    } else {
      this.siteService.setNavigation();
      this.onGetCategories = 'success';
    }

    if (this.siteService.IS_BROWSER) {
      this.itemsViewedService.get();

      if (this.siteService.user) {
        this.accountService.validate().subscribe(
          () => {
            this.accountService.getMember();
            this.wishListService.getAll();

            if (this.router.url.startsWith('/checkout/cancel') === false) {
              this.getCartAndOrder();
            }
          },
          () => {
            this.siteService.logout();
            this.cartService.clear();
          }
        );
      } else {
        //Allow non-auth users to get their cart / order details
        this.getCartAndOrder();
      }

      /**
       * - Determine if it's possible to scroll a certain position on the page
       * - If the user is logged in, verify if its token is still valid
       */
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          this.siteService.previousPreviousScrollY =
            this.siteService.previousScrollY;
          this.siteService.previousScrollY = this.siteService.currentScrollY;
        }

        if (event instanceof NavigationEnd && this.siteService.navigation) {
          this.setNavigation();
        }
      });

      /** Remove redirectURL if useless */
      setTimeout(() => {
        const redirectURL = this.cookieService.get('redirectURL');

        if (
          redirectURL &&
          this.router.url.startsWith('/account/login/') === false
        ) {
          this.cookieService.delete('redirectURL');
        }
      }, 10000);
    }
  }

  setNavigation() {
    if (this.siteService.IS_BROWSER) {
      const { url } = this.router;

      this.siteService.previousPreviousPage = this.siteService.previousPage;
      this.siteService.previousPage = this.siteService.currentPage;
      this.siteService.currentPage = url;
      this.siteService.canScroll =
        url === this.siteService.previousPreviousPage;

      const urlSegments = this.siteService.currentPage.split('?')[0].split('/');
      let node = this.siteService.navigation;
      let previousNode;

      for (const segment of urlSegments) {
        if (node === undefined) {
          break;
        }

        previousNode = node;
        node = node[segment];
      }

      if (node) {
        this.siteService.currentPageName = node._name;
      } else {
        this.siteService.currentPageName = previousNode._name;
      }

      if (this.siteService.user) {
        this.accountService.validate().subscribe(
          () => {},
          () => {
            this.sessionExpiredComponent.open();

            this.siteService.logout();
            this.cartService.clear();
            this.itemsViewedService.clear();
            this.itemsViewedService.get();
          }
        );
      }
    }
  }

  getCartAndOrder() {
    if (this.siteService.cartId) {
      this.cartService.getAllItems();
    }

    if (this.siteService.order) {
      this.ordersService.getOrder();
    }
  }

  onRouterOutletActivate(event: any) {
    setTimeout(() => {
      if (!event.el || event.el.nativeElement.firstElementChild) {
        this.isLoaded = true;
      }
    });
  }

  @HostListener('document:cartCreated')
  onCartCreated() {
    const savedAddParams = JSON.parse(
      JSON.stringify(this.cartService.savedAddParams)
    );

    this.cartService.savedAddParams = [];

    for (const addParams of savedAddParams) {
      this.cartService.exeAddRequest(addParams);
    }
  }

  @HostListener('document:cartNewItem')
  onCartNewItem() {
    this.notifierService.show({
      message: 'Product added to cart',
      type: 'default',
      template: this.cartNotification,
    });
  }

  @HostListener('document:cartNewItemError', ['$event.detail'])
  onCartNewItemError(message) {
    this.notifierService.show({
      message: message?.message ?? 'Failed to add to cart',
      type: 'error',
      template: this.cartNotificationError,
    });
  }

  @HostListener('document:gotOrder', ['$event.detail'])
  onGotOrder(order: any) {
    //Dispatch this event to update checkout page infoForm
    document.dispatchEvent(
      new CustomEvent('memberlogin', {
        bubbles: true,
        cancelable: true,
      })
    );

    if ([undefined, null].includes(this.siteService.cartId)) {
      this.siteService.setCartId(order.cartId);
      this.cartService.getAllItems();
    }
  }

  @HostListener('document:updateOrder')
  onUpdateOrder() {
    const subscription = this.order.subscribe((order: any) => {
      const { id, email, shippingAddress, billingAddress } = order;
      const params = {
        id,
        email,
        userId: this.siteService?.user?.id ?? this.siteService?.userId,
        cartId: this.siteService.cartId,
        shippingAddress,
        billingAddress,
      };

      this.ordersService.updateOrder(params);
      setTimeout(() => subscription.unsubscribe());
    });
  }

  @HostListener('document:orderCancelled')
  onOrderCancelled() {
    this.notifierService.show({
      message: 'Order cancelled',
      type: 'default',
      template: this.successNotification,
    });
  }

  @HostListener('document:currencyChange')
  onCurrencyChange() {
    this.qubykQuickService.getBoxes();

    this.itemsViewedService.clear();
    this.itemsViewedService.get();

    if (this.siteService.user) {
      this.cartService.getAllItems();
      this.cartService.updateCurrency();
    }
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.siteService.currentScrollY = window.scrollY;
  }

  @HostListener('document:buynowCancelled')
  onBuynowCancelled() {
    this.notifierService.show({
      message: 'Buynow payment cancelled',
      type: 'default',
      template: this.buynowCancelled,
    });
  }

  @HostListener('document:buynowCannotShip')
  onBuynowCannotShip() {
    this.notifierService.show({
      message: 'Cannot ship to selected country or postcode',
      type: 'default',
      template: this.buynowCancelled,
    });
  }
}
