import { OnInit, Directive, ViewChild } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SiteService } from '../services/site/site.service';
import { SeoService } from '../seo/seo.service';
import { HttpClient } from '@angular/common/http';
import { BlogService } from '../blog/blog.service';
import { MiscContentService } from '../../ecommerce/services/misc-content/misc-content.service';
import { BreadcrumbService } from '../services/breadcrumb/breadcrumb.service';
import { SubscribeComponent } from '../../components/subscribe/subscribe.component';

@Component({
  selector: 'app-blog-details-desktop',
  templateUrl: './blog-details-desktop.component.html',
  styleUrls: [
    './blog-details-desktop.component.scss',
    '../../../assets/blog/templates/AU/assets/css/style.css',
    '../../../assets/blog/templates/AU/plugins/tabler-icons/tabler-icons.min.css',
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class BlogDetailsDesktopComponent implements OnInit {
  @ViewChild(SubscribeComponent)
  subscribeComponent: SubscribeComponent;

  blogData: any;

  constructor(
    protected router: Router,
    private blogService: BlogService,
    private route: ActivatedRoute,
    private siteService: SiteService,
    protected breadcrumbService: BreadcrumbService,
    private miscContentService: MiscContentService,
    protected seoService: SeoService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((para) => {
      if (para['id']) this.getBlogData(para['id']);
    });

    setTimeout(() => {
      if (this.breadcrumbService.breadcrumb) {
        const category = this.siteService.getTitleFromPathSegment(
          this.router.url.split('/')[2]
        );

        this.breadcrumbService.breadcrumb.push({
          name: 'Blog',
          route: '../',
        });

        this.breadcrumbService.activeBreadcrumb = {
          name: category,
          route: this.router.url,
        };
      }
    });
  }

  onSubscribeClick() {
    this.subscribeComponent.open();
  }

  redirectBlog(href: string) {
    this.router.navigateByUrl(`/blog/${href}`);

    this.breadcrumbService.breadcrumb = [];
    this.breadcrumbService.breadcrumb.push({
      name: 'Blog',
      route: '../',
    });

    this.breadcrumbService.activeBreadcrumb = {
      name: href,
      route: this.router.url,
    };
  }
  getBlogData(href: string) {
    this.blogService.getDetailPosts(href).subscribe((res: any) => {
      this.blogData = res.data;

      this.seoService.setTitle(this.blogData.meta.title);
      this.seoService.setMetaTitle(this.blogData.meta.title);
      this.seoService.setMetaDescription(this.blogData.meta.description);
      this.seoService.setMetaKeywords(this.blogData.meta.keywords);
      this.seoService.setMetaAuthor(this.blogData.meta.author);
      this.seoService.setMetaViewPort();
      this.seoService.setIndexTag();
      this.seoService.setMetaCanonical(this.blogData.meta.canonical);

      this.seoService.setTwitterTag(this.blogData?.meta?.twitter ?? null);
      this.seoService.setOpenGraphTag(this.blogData?.meta?.og ?? null);
    });
  }
}
