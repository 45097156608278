import { OnInit, Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { SiteService } from '../../ecommerce/services/site/site.service';

@Directive()
export class NavLinksComponent implements OnInit {
  categories = [];
  activeCategory: number;
  activeSubcategory: number;
  hideLogin: boolean = false;

  constructor(protected router: Router, protected siteService: SiteService) {}

  ngOnInit() {
    this.categories = [
      {
        name: this.siteService.navigation['']._name,
        routerLink: '/',
      },
    ];

    if (this.siteService.navigation['']['qubyk-quick']) {
      this.categories.push({
        name: this.siteService.navigation['']['qubyk-quick']._name,
        routerLink: '/qubyk-quick',
      });
    }

    const nav = this.siteService.navigation['']['nav'];

    for (const categoryKey in nav) {
      const categoryNode = nav[categoryKey];
      const category: any = {
        name: categoryNode._name,
        routerLink: `/nav/${categoryKey}`,
      };

      if (Object.keys(categoryNode).length > 2) {
        category.children = [
          {
            name: `All ${category.name}`,
            routerLink: category.routerLink,
          },
        ];

        for (const subcategoryKey in categoryNode) {
          const subcategoryNode = categoryNode[subcategoryKey];

          if (['_name', '_isEnabled'].includes(subcategoryKey) === false) {
            const subcategory: any = {
              name: subcategoryNode._name,
              routerLink: `${category.routerLink}/${subcategoryKey}`,
            };

            if (Object.keys(subcategoryNode).length > 1) {
              subcategory.children = [
                {
                  name: `All ${subcategory.name}`,
                  routerLink: subcategory.routerLink,
                },
              ];

              for (const subsubcategoryKey in subcategoryNode) {
                const subsubcategoryNode = subcategoryNode[subsubcategoryKey];

                if (
                  ['_name', '_isEnabled'].includes(subsubcategoryKey) === false
                ) {
                  const subsubcategory: any = {
                    name: subsubcategoryNode._name,
                    routerLink: `${subcategory.routerLink}/${subsubcategoryKey}`,
                  };

                  subcategory.children.push(subsubcategory);
                }
              }
            }

            category.children.push(subcategory);
          }
        }
      }

      this.categories.push(category);
    }
  }

  onCategoryClick(categoryIndex: number) {
    this.activeSubcategory = null;
    this.activeCategory = categoryIndex;
  }

  onCategoryChange(routerLink: string, event?: any) {
    if (routerLink === this.siteService.currentPage) {
      location.reload();
    } else {
      this.router.navigate([routerLink]);
    }
  }

  onSubCategoryClick(subcategoryIndex: number) {
    if (this.activeSubcategory && this.activeSubcategory === subcategoryIndex) {
      this.activeSubcategory = null;
    } else {
      this.activeSubcategory = subcategoryIndex;
    }
  }

  @HostListener('document:hideLogins')
  onHideLogins() {
    this.hideLogin = true;
  }

  @HostListener('document:showLogins')
  onShowLogins() {
    this.hideLogin = false;
  }
}
