import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SiteService } from '../site/site.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  readonly API_PREFIX: string;
  readonly API_PREFIX2: string;

  constructor(private http: HttpClient, private siteService: SiteService) {
    this.API_PREFIX = `${environment.apiUrl}/marketplace-payments/checkout`;
    this.API_PREFIX2 = `${environment.apiUrl}/marketplace-payments`;
  }

  checkout(params, paymentMethod: string): Observable<any> {
    return this.http.post(`${this.API_PREFIX}/${paymentMethod}`, params);
  }

  checkout2(params: any, paymentMethod: string): Observable<any> {
    return this.http.post(
      `${this.API_PREFIX2}/${paymentMethod}/checkout`,
      params
    );
  }

  stripeLink(params: any): Observable<any> {
    return this.http.post(
      `${this.API_PREFIX2}/stripe/link-payment-intent`,
      params
    );
  }

  validatePayment(paymentMethod: string, params: any): Observable<any> {
    return this.http.post(
      `${this.API_PREFIX}/validate/${paymentMethod}`,
      params
    );
  }

  validatePayment2(paymentMethod: string, params: any): Observable<any> {
    return this.http.post(
      `${this.API_PREFIX2}/${paymentMethod}/validate`,
      params
    );
  }

  cancelPayment(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}/cancel`, params);
  }

  cancelPaymentType(params: any, paymentMethod: string): Observable<any> {
    return this.http.post(
      `${this.API_PREFIX2}/${paymentMethod}/cancel`,
      params
    );
  }
}
