<ng-template #sessionExpiredModalTemplate *ngIf="show">
  <div class="modal-body">
    <h1 class="font-title text-center pb-4">Session Expired</h1>

    <div class="pb-3 pt-3">
      <p>
        <span class="fa fa-3x fa-bell"></span>
      </p>

      <p>Your session has expired</p>

      <button (click)="onOKClick()" class="btn btn-lg btn-dark mt-4">OK</button>
    </div>
  </div>
</ng-template>
