import { OnInit, Output, EventEmitter, Directive } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { SocialLoginService } from '../../ecommerce/services/social-login/social-login.service';
import { SeoService } from '../../ecommerce/seo/seo.service';

declare let FB: any;

@Directive()
export class RegisterComponent implements OnInit {
  readonly MIN_PASSWORD_LENGTH: number;
  readonly MAX_PASSWORD_LENGTH: number;

  @Output() signin: EventEmitter<void>;

  registerForm: FormGroup;
  recaptchaValue: string;
  passwordType: string;

  instragramToken: string;
  instagramUserId: string;

  onRegister: string;
  onInstagramLogin: string;

  showModal: boolean;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected seoService: SeoService,

    public siteService: SiteService,
    public socialLoginService: SocialLoginService
  ) {
    this.MIN_PASSWORD_LENGTH = 8;
    this.MAX_PASSWORD_LENGTH = 20;

    this.signin = new EventEmitter<void>();
  }

  ngOnInit() {
    this.seoService.setTitle('Login');
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  get passwordTypeClass(): string {
    if (this.passwordType === 'password') {
      return 'fa fa-eye-slash';
    }

    return 'fa fa-eye';
  }

  get onSocialLogin(): boolean {
    return (
      this.socialLoginService.onFacebookLogin === 'true' ||
      this.socialLoginService.onGoogleLogin === 'true' ||
      this.socialLoginService.onUpdateuserId === 'true' ||
      this.onInstagramLogin === 'true'
    );
  }

  saveRedirectURL() {
    const { redirectTo } = this.activatedRoute.snapshot.queryParams;

    if (redirectTo) {
      this.cookieService.set('redirectURL', redirectTo);
    } else {
      this.cookieService.set('redirectURL', this.router.url);
    }
  }

  onRecaptchaChange(recaptcha: any) {
    this.recaptchaValue = null;
    this.recaptchaValue = recaptcha.recaptchaAPI.getResponse();
  }

  onSignInClick() {
    this.signin.emit();
  }

  onGoogleRegisterClick() {
    this.saveRedirectURL();
    setTimeout(() => this.socialLoginService.goToGoogleLogin());
  }

  onFacebookRegisterClick() {
    if (this.siteService.isMobileView) {
      this.saveRedirectURL();
    }

    this.socialLoginService.loginFacebook();
  }

  onInstagramRegisterClick() {
    this.saveRedirectURL();
    setTimeout(() => this.socialLoginService.goToInstagramLogin());
  }

  onPasswordViewClick() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  onHide() {
    this.showModal = false;
  }
}
