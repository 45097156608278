import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RegisterComponent } from './register.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { SocialLoginService } from '../../ecommerce/services/social-login/social-login.service';
import { SeoService } from '../../ecommerce/seo/seo.service';

@Component({
  selector: 'app-register-modal-desktop',
  templateUrl: './register-modal-desktop.component.html',
  styleUrls: ['./register-modal-desktop.component.scss'],
})
export class RegisterModalDesktopComponent extends RegisterComponent {
  @ViewChild('registerModalTemplate')
  registerModalTemplate: ElementRef;

  modalRef: BsModalRef;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected seoService: SeoService,

    private modalService: BsModalService,

    public siteService: SiteService,
    public socialLoginService: SocialLoginService
  ) {
    super(
      router,
      activatedRoute,
      cookieService,
      accountService,
      seoService,
      siteService,
      socialLoginService
    );
  }

  open() {
    const getPasswordControl = () => {
      return new FormControl('', [
        Validators.required,
        Validators.minLength(this.MIN_PASSWORD_LENGTH),
        Validators.maxLength(this.MAX_PASSWORD_LENGTH),
        Validators.pattern(/^.*(?=.*?[0-9])(?=.*?[^\w]).*$/),
      ]);
    };

    this.registerForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      ]),
      password: getPasswordControl(),
      recaptcha: new FormControl(false, [Validators.required]),
    });

    this.passwordType = 'password';

    this.onRegister = 'false';
    this.onInstagramLogin = 'false';
    this.showModal = false;

    document.dispatchEvent(
      new CustomEvent('modalOpened', {
        bubbles: true,
        cancelable: true,
      })
    );

    setTimeout(() => {
      this.showModal = true;

      setTimeout(() => {
        this.modalRef = this.modalService.show(
          this.registerModalTemplate as any,
          {
            class: 'app-modal-width-450px',
          }
        );
        this.modalService.onHide.subscribe(() => {
          this.onHide();

          setTimeout(() => {
            document.dispatchEvent(
              new CustomEvent('modalClosed', {
                bubbles: true,
                cancelable: true,
              })
            );
          }, 100);
        });
      });
    }, 100);
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  onSignInClick() {
    this.hide();
    super.onSignInClick();
  }

  onRegisterClick(recaptcha: any) {
    const params: any = {
      email: this.f.email.value,
      password: this.f.password.value,
      captchaResponse: this.recaptchaValue,
      siteId: this.siteService.domain,
    };

    this.onRegister = 'true';

    if (recaptcha) recaptcha.recaptchaAPI.reset();

    this.accountService.register(params).subscribe(
      () => {
        this.onRegister = 'success';
        this.hide();
      },
      () => {
        this.onRegister = 'error';
      }
    );
  }

  @HostListener('document:openRegister')
  onOpenRegister() {
    this.open();
  }

  @HostListener('document:closeRegister')
  onCloseRegisterEvent() {
    this.hide();
  }
}
