import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgRedux } from '@angular-redux2/store';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';

import {
  GET_PRODUCTS_SUCCESS,
  REQ_PRODUCTS_ERROR,
} from '../../store/products.actions';

import { IProductsState } from '../../store';

import { SiteService } from './../site/site.service';

import { environment } from '../../../../environments/environment';
import { ProductsService } from '../products.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  readonly API_PREFIX: string;

  onSearch: boolean;

  gotAllProducts: boolean;
  gotAllProductsCategories: boolean[];

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private ngRedux: NgRedux<IProductsState>,
    private siteService: SiteService,
    private productsService: ProductsService
  ) {
    this.API_PREFIX = `${environment.productsUrl}/api`;
    this.gotAllProductsCategories = [];
  }

  getCategories(params: {
    query?: string;
    start?: number;
    limit?: number;
    priceFilter?: string;
  }): Observable<any> {
    const { query } = params;
    let { start, limit, priceFilter } = params;

    if (!start) {
      start = 0;
    }

    if (!limit) {
      limit = 10;
    }

    if (!priceFilter) {
      priceFilter = 'none';
    }

    return this.http.get(
      `${this.API_PREFIX}/filter/categories/${this.siteService.country}/${query}/${priceFilter}/${start}/${limit}`
    );
  }

  getBrands(params: {
    query?: string;
    start?: number;
    limit?: number;
    priceFilter?: string;
  }): Observable<any> {
    const { query } = params;
    let { start, limit, priceFilter } = params;

    if (!start) {
      start = 0;
    }

    if (!limit) {
      limit = 10;
    }

    if (!priceFilter) {
      priceFilter = 'none';
    }

    return this.http.get(
      `${this.API_PREFIX}/filter/brands/${this.siteService.country}/${query}/${priceFilter}/${start}/${limit}`
    );
  }

  getBaseProduct(title: string, id: string): Observable<any> {
    const url = `${this.API_PREFIX}/product-base/${this.siteService.currency}/${title}/${id}`;
    return this.http.get(url);
  }

  getSimilarProducts(
    title: string,
    start: number = 0,
    limit: number = 10
  ): Observable<any> {
    const url = `${this.API_PREFIX}/similar-products/${this.siteService.currency}/${title}/${start}/${limit}`;
    return this.http.get(url);
  }

  getAlternativeProducts(
    category: string,
    start: number = 0,
    limit: number = 10
  ): Observable<any> {
    const url = `${this.API_PREFIX}/alternative-products/${this.siteService.currency}/${category}/${start}/${limit}`;
    return this.http.get(url);
  }

  searchWithoutFilters(params: {
    query: string;
    start?: number;
    limit?: number;
    priceFilter?: string;
    justArrivedFirst?: boolean | number;
    stack?: boolean;
  }) {
    const { query, start, limit, priceFilter, stack } = params;
    const url = `${this.API_PREFIX}/search/v2/${this.siteService.country}/${this.siteService.currency}/${query}/${priceFilter}/${start}/${limit}`;

    this.onSearch = true;

    if (stack === false) {
      this.ngRedux.dispatch({
        type: GET_PRODUCTS_SUCCESS,
        prods: { products: [], nav: '', stack },
      });
    }

    this.http.get(url).subscribe(
      (prods: any[]) => {
        this.onSearch = false;
        this.gotAllProducts = prods.length < limit;

        this.siteService.setProductsImages(prods);
        this.productsService.setProductsFullId(prods);

        return this.ngRedux.dispatch({
          type: GET_PRODUCTS_SUCCESS,
          prods: { products: prods, nav: '', stack },
        });
      },
      (err) => {
        this.onSearch = false;

        return this.ngRedux.dispatch({
          type: REQ_PRODUCTS_ERROR,
          nav: '',
        });
      }
    );
  }

  async searchWithFilters(params: {
    query: string;
    limit?: number;
    priceFilter?: string;
    justArrivedFirst?: boolean | number;
    stack?: boolean;
  }) {
    let { categories, brands } = this.activatedRoute.snapshot.queryParams;
    const { query, limit, priceFilter, stack } = params;
    const body: any = {
      country: this.siteService.country,
      term: query,
      priceSort: priceFilter,
      skip: 0,
      limit,
    };
    const getCategoryIndex = (category: any) => {
      return `${category.category}/${category.subCat}/${category.childCat}`;
    };

    if (brands) {
      body.brand = brands.split(',');
    }

    if (stack === false) {
      this.gotAllProductsCategories = [];
      this.ngRedux.dispatch({
        type: GET_PRODUCTS_SUCCESS,
        prods: { products: [], nav: '', stack },
      });
    }

    if (categories) {
      const categoryList = JSON.parse(categories);
      const products = [];
      const indexes = [];
      const bodies = [];
      const requests = [];

      for (const category of categoryList) {
        const reqBody = JSON.parse(JSON.stringify(body));
        reqBody.category = {
          category: this.siteService.getPathSegmentFromTitle(category.category),
          subCat: this.siteService.getPathSegmentFromTitle(category.subCat),
          childCat: this.siteService.getPathSegmentFromTitle(category.childCat),
        };

        const index = getCategoryIndex(reqBody.category);

        if (stack) {
          if (this.gotAllProductsCategories[index].status === false) {
            reqBody.skip = this.gotAllProductsCategories[index].length;

            indexes.push(index);
            bodies.push(reqBody);
            requests.push(
              this.http.post(
                `${this.API_PREFIX}/filter/category-brand`,
                reqBody
              )
            );
          }
        } else {
          indexes.push(index);
          bodies.push(reqBody);
          requests.push(
            this.http.post(`${this.API_PREFIX}/filter/category-brand`, reqBody)
          );
        }
      }

      if (requests.length > 0) {
        this.onSearch = true;
      }

      forkJoin(requests).subscribe((results: any) => {
        for (let i = 0; i < results.length; i += 1) {
          const prods = results[i];
          const index = getCategoryIndex(bodies[i].category);

          products.push(...prods);

          if (this.gotAllProductsCategories[index] === undefined) {
            this.gotAllProductsCategories[index] = {
              length: prods.length,
            };
          } else {
            this.gotAllProductsCategories[index].length += prods.length;
          }

          this.gotAllProductsCategories[index].status = prods.length < limit;

          this.siteService.setProductsImages(prods);
        }

        switch (priceFilter) {
          case 'low':
            products.sort(
              (product1: any, product2: any) => product1.price - product2.price
            );
            break;
          case 'high':
            products.sort(
              (product1: any, product2: any) => product2.price - product1.price
            );
            break;
        }

        this.productsService.setProductsFullId(products);

        this.onSearch = false;

        this.ngRedux.dispatch({
          type: GET_PRODUCTS_SUCCESS,
          prods: { products, nav: '', stack },
        });
      });
    } else {
      this.onSearch = true;

      this.http
        .post(`${this.API_PREFIX}/filter/category-brand`, body)
        .subscribe(
          (prods: any[]) => {
            this.onSearch = false;
            this.gotAllProducts = prods.length < limit;

            this.siteService.setProductsImages(prods);
            this.productsService.setProductsFullId(prods);

            return this.ngRedux.dispatch({
              type: GET_PRODUCTS_SUCCESS,
              prods: { products: prods, nav: '', stack },
            });
          },
          () => {
            this.onSearch = false;

            return this.ngRedux.dispatch({
              type: REQ_PRODUCTS_ERROR,
              nav: '',
            });
          }
        );
    }
  }

  search(params: {
    start?: number;
    limit?: number;
    priceFilter?: string;
    justArrivedFirst?: boolean | number;
    stack?: boolean;
  }) {
    const { query } = this.activatedRoute.snapshot.queryParams;
    let { start, limit, priceFilter, justArrivedFirst, stack } = params;

    if (start === undefined) {
      start = 0;
    }

    if (limit === undefined) {
      limit = 48;
    }

    if (priceFilter === undefined) {
      priceFilter = 'none';
    }

    if (justArrivedFirst) {
      justArrivedFirst = 1;
    } else {
      justArrivedFirst = 0;
    }

    if (stack === undefined) {
      stack = false;
    }

    params = {
      start,
      limit,
      priceFilter,
      justArrivedFirst,
      stack,
    };

    const { categories, brands } = this.activatedRoute.snapshot.queryParams;

    if (categories || brands) {
      this.searchWithFilters({ query, ...params });
    } else {
      this.searchWithoutFilters({ query, ...params });
    }
  }
}
