import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'p-section-desktop',
  templateUrl: './p-section-desktop.template.html',
  styleUrls: ['./p-section-desktop.style.scss'],
})
export class PSectionDesktopComponent implements OnInit {
  @Input() title: string = '';
  @Input() link: any;
  @Input() textAlign: string = 'center';
  @Input() isMobileTitleHidden: boolean = false;

  titleClass: string;

  ngOnInit() {
    if (this.isMobileTitleHidden) {
      this.titleClass = 'hidden-sm-down';
    }

    if (this.textAlign && this.link === undefined) {
      this.titleClass += ` text-${this.textAlign}`;
    }
  }
}
