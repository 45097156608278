<form [formGroup]="instagramLoginForm">
  <div class="row">
    <div class="col text-center">
      <span>
        Please enter the following informations to continue with Instagram.
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-group">
        <div
          [class.success]="f.email.pristine === false && f.email.valid"
          [class.error]="f.email.pristine === false && f.email.invalid"
          class="input-group input-group-no-border"
        >
          <span class="input-group-prepend">
            <span class="input-group-text">
              <span class="fa fa-at"></span>
            </span>
          </span>

          <input
            formControlName="email"
            type="text"
            class="form-control form-control-lg"
            placeholder="E-Mail"
            required
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col text-center">
      <ng-container *ngIf="!register">
        <button
          *ngIf="onLogin === false"
          (click)="onLoginClick()"
          [disabled]="instagramLoginForm.invalid"
          class="btn btn-dark btn-lg"
        >
          Login with Instagram
        </button>

        <button
          *ngIf="onLogin === true"
          [disabled]="true"
          class="btn btn-dark btn-lg"
        >
          <span class="fa fa-spinner fa-spin"></span> Logging with Instagram...
        </button>
      </ng-container>

      <ng-container *ngIf="register === true">
        <button
          *ngIf="onLogin === false"
          (click)="onLoginClick()"
          [disabled]="instagramLoginForm.invalid"
          class="btn btn-dark btn-lg"
        >
          Register with Instagram
        </button>

        <button
          *ngIf="onLogin === true"
          [disabled]="true"
          class="btn btn-dark btn-lg"
        >
          <span class="fa fa-spinner fa-spin"></span>
          Registering with Instagram...
        </button>
      </ng-container>
    </div>
  </div>

  <div class="mt-3">
    <a [routerLink]="['/']"> Cancel and return to Qubyk Marketplace </a>
  </div>
</form>
