<div id="this">
  <app-carousel-items
    *ngIf="cards"
    [width]="width"
    [marginBotton]="marginBotton"
    [nbItemsDisplayed]="nbShowedBoxes"
    [hideControls]="true"
    [showScrollBar]="true"
    [items]="cards"
    [$item]="itemTemplate"
    (onSelectedItem)="onSelectedItem($event)"
  ></app-carousel-items>
</div>

<ng-template let-card let-i="index" #itemTemplate>
  <div [ngStyle]="productCardStyle" class="product-card">
    <a
      [routerLink]="[
      '/nav',
      card.category,
      card.subCat,
      card.childCat,
      'product',
      siteService.getPathSegmentFromTitle(card.title),
      card.id
      ]"
      (click)="onProductClick(card)"
      style="text-decoration: none"
    >
      <div
        class="card-photo"
        (mouseenter)="onCardMouseEnter(i)"
        (mouseleave)="onCardMouseLeave(i)"
      >
        <div class="card-image">
          <span
            *ngIf="!isImageLoaded[card.id]"
            class="fa fa-spin fa-spinner fa-2x"
          ></span>
          <img
            [class.d-none]="!isImageLoaded[card.id]"
            [src]="card.currentImage"
            [alt]="card.title"
            (load)="onCardImageLoaded(card.id)"
          />
        </div>
      </div>

      <h5 class="card-title">{{ card.brand }}</h5>

      <div class="card-description">{{ card.title }}</div>
      <div class="price">{{ getCurrency(i) }}{{ card.price }}</div>
    </a>
  </div>

  <div
    *ngIf="onLoadMore && (i === cards.length - 1)"
    [ngStyle]="productCardStyle"
    class="product-card"
  >
    <div class="card-photo">
      <div class="card-image">
        <span class="fa fa-spin fa-spinner fa-2x"></span>
      </div>
    </div>
  </div>

  <div
    *ngIf="showMoreButton && !gotAllItems && !onLoadMore && i === cards.length - 1"
    [ngStyle]="productCardStyle"
    (click)="onMoreClick()"
    class="product-card"
  >
    <div class="card-photo">
      <div class="card-image">
        <h3>More</h3>
      </div>
    </div>
  </div>
</ng-template>
