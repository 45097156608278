<ng-template #loginModalTemplate *ngIf="showModal">
  <div class="modal-body">
    <h1 class="font-title text-center pb-4">Login</h1>

    <div [class.d-none]="onSocialLogin || onInstagramLogin === 'form'">
      <form [formGroup]="loginForm" class="pb-3 pt-3">
        <div class="form-group">
          <div
            [class.success]="f.email.pristine === false && f.email.valid"
            [class.error]="f.email.pristine === false && f.email.invalid"
            class="input-group input-group-no-border"
          >
            <span class="input-group-prepend">
              <span class="input-group-text">
                <span class="fa fa-at"></span>
              </span>
            </span>

            <input
              formControlName="email"
              type="text"
              class="form-control form-control-lg"
              placeholder="E-Mail"
              required
            />
          </div>
        </div>

        <div class="form-group">
          <div
            [class.success]="f.password.pristine === false && f.password.valid"
            [class.error]="f.password.pristine === false && f.password.invalid"
            class="input-group input-group-no-border"
          >
            <span class="input-group-prepend">
              <span class="input-group-text">
                <span class="fa fa-lock"></span>
              </span>
            </span>

            <input
              [type]="passwordType"
              formControlName="password"
              class="form-control form-control-lg"
              placeholder="Password"
              required
            />

            <div
              (click)="onPasswordViewClick()"
              class="input-group-append app-password-view"
            >
              <span class="input-group-text">
                <span [ngClass]="passwordTypeClass"></span>
              </span>
            </div>
          </div>

          <ng-container *ngIf="f.password.errors">
            <ng-container
              *ngTemplateOutlet="
                passwordError;
                context: { errors: f.password.errors }
              "
            ></ng-container>
          </ng-container>
        </div>

        <div class="row">
          <div class="col"></div>
          <div class="col">
            <recaptcha
              #recaptcha
              (ngModelChange)="onRecaptchaChange(recaptcha)"
              formControlName="recaptcha"
              *ngIf="siteService.IS_BROWSER"
              name="recaptcha"
              class="g-recaptcha"
            >
            </recaptcha>
          </div>
          <div class="col"></div>
        </div>

        <div class="row mt-4">
          <div class="col text-center">
            <button
              *ngIf="onLogin !== 'true' && onLogin !== 'success'"
              (click)="onLoginClick(recaptcha)"
              [disabled]="loginForm.invalid || !recaptchaValue"
              class="btn btn-dark btn-lg"
            >
              Login
            </button>

            <button
              *ngIf="onLogin === 'true'"
              [disabled]="true"
              class="btn btn-dark btn-lg"
            >
              <span class="fa fa-spinner fa-spin"></span> Logging...
            </button>
          </div>
        </div>

        <div *ngIf="onLogin === 'error'" class="row mt-3">
          <div class="col text-danger text-center">
            <small class="form-text font-weight-bolder">
              {{ loginError }}
            </small>
          </div>
        </div>

        <!--
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="checkbox-stay-connected"
        />
        <label class="form-check-label" for="checkbox-stay-connected">
          Stay connected?
        </label>
      </div> -->

        <div class="row form-group font-weight-normal mt-5">
          <div class="col">
            <a (click)="onSignUpClick()">Sign Up</a>
          </div>

          <div class="col text-right">
            <a (click)="onPasswordForgottenClick()">Password Forgotten?</a>
          </div>
        </div>
      </form>

      <hr />

      <div id="this-social-logins" class="pt-4">
        <div
          *ngIf="socialLoginService.onGoogleLogin === 'error'"
          class="row mb-3"
        >
          <div class="col text-danger text-center">
            <small class="form-text font-weight-bolder">
              Cannot login with Google.
            </small>
          </div>
        </div>

        <div
          *ngIf="socialLoginService.onFacebookLogin === 'error'"
          class="row mb-3"
        >
          <div class="col text-danger text-center">
            <small class="form-text font-weight-bolder">
              Cannot login with Facebook.
            </small>
          </div>
        </div>

        <div *ngIf="onInstagramLogin === 'error'" class="row mb-3">
          <div class="col text-danger text-center">
            <small class="form-text font-weight-bolder">
              Cannot login with Instagram.
            </small>
          </div>
        </div>

        <p class="text-center font-weight-normal">or</p>

        <ul class="app-social-login-list text-center">
          <li>
            <div
              (click)="onGoogleLoginClick()"
              class="app-social-login app-google-login"
            ></div>
          </li>
          <li>
            <div
              (click)="onFacebookLoginClick()"
              class="app-social-login app-facebook-login"
            ></div>
          </li>
          <!-- <li>
              <div
                (click)="onInstagramLoginClick()"
                class="app-social-login app-instagram-login"
              ></div>
            </li> -->
        </ul>
      </div>
    </div>

    <!-- <div *ngIf="onInstagramLogin === 'form'">
      <app-instagram-login-form
        [(onInstagramLogin)]="onInstagramLogin"
        [token]="instragramToken"
        [userId]="instagramUserId"
      ></app-instagram-login-form>
    </div> -->

    <div *ngIf="onSocialLogin" class="text-center pt-5 pb-5">
      <span class="fa fa-spinner fa-spin fa-3x"></span>
    </div>
  </div>
</ng-template>

<ng-template #passwordError let-errors="errors">
  <div class="text-danger">
    <small *ngIf="errors.minlength" class="form-text font-weight-bolder">
      Please enter at least {{ MIN_PASSWORD_LENGTH }} characters.
    </small>

    <small *ngIf="errors.maxlength" class="form-text font-weight-bolder">
      Please enter no more than {{ MAX_PASSWORD_LENGTH }} characters.
    </small>

    <small *ngIf="errors.pattern" class="form-text font-weight-bolder">
      Please enter at least one number and one special character.
    </small>
  </div>
</ng-template>
