import {
  Component,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { SubCategoryAreaComponent } from './subcategory-area.component';

@Component({
  selector: 'subcategory-area-desktop',
  templateUrl: './subcategory-area-desktop.component.html',
  styleUrls: ['./subcategory-area-desktop.component.scss'],
})
export class SubCategoryAreaDesktopComponent
  extends SubCategoryAreaComponent
  implements AfterViewInit
{
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
    this.DEFAULT_NB_SHOWED_BOXES = 7;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.view) {
      this.view = 'slider';
    }

    this.setConfiguration();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  setConfiguration() {
    if (this.nbShowedBoxes === undefined) {
      if (window.innerWidth <= 500) {
        this.nbShowedBoxes = 1;
      } else {
        this.nbShowedBoxes = this.DEFAULT_NB_SHOWED_BOXES;
      }
    }

    if (this.width === undefined) {
      this.width = this.nbShowedBoxes * 158 + this.nbShowedBoxes * 5; // number of boxes to display at any time * width of item
    }
  }
}
