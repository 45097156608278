import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Renderer2,
  NgZone,
  ViewChild,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';

import { LayoutComponent } from './layout.component';
import { NavbarMobileComponent } from './navbar/navbar-mobile.component';

import { SiteService } from '../ecommerce/services/site/site.service';
import { CookieService } from '../ecommerce/services/cookie/cookie.service';

import { AppConfig } from '../app.config';

@Component({
  selector: 'app-layout-mobile',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './layout-mobile.template.html',
  styleUrls: ['./layout-mobile.style.scss'],
})
export class LayoutMobileComponent extends LayoutComponent {
  @ViewChild(NavbarMobileComponent)
  navbarMobileComponent: NavbarMobileComponent;

  show: boolean;

  constructor(
    @Inject(WINDOW) protected window: Window,
    @Inject(DOCUMENT) protected document: Document,
    config: AppConfig,
    el: ElementRef,
    router: Router,
    protected renderer: Renderer2,
    protected ngZone: NgZone,
    protected cookieService: CookieService,

    public siteService: SiteService
  ) {
    super(
      window,
      config,
      el,
      router,
      renderer,
      ngZone,
      cookieService,
      siteService
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.siteService.minHeight = '0px';
    this.show = true;

    if (this.siteService.IS_BROWSER) {
      setTimeout(() => this.setHeight(), 250);
    }
  }

  setHeight() {
    const navbarHeight = this.document
      .querySelector('app-navbar-mobile #this')
      .getBoundingClientRect().height;
    const headerHeight = this.document
      .querySelector('app-header-mobile')
      .getBoundingClientRect().height;

    this.siteService.minHeight = `${
      this.window.innerHeight - (navbarHeight + headerHeight)
    }px`;
  }

  onLogoutClick() {
    this.navbarMobileComponent.onLogoutClick();
  }
}
