import { Injectable } from '@angular/core';
import { CookieService } from '../cookie/cookie.service';
import { SiteService } from '../site/site.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CourierService {
  readonly API_PREFIX: string;

  constructor(
    protected cookieService: CookieService,
    protected siteService: SiteService,
    private http: HttpClient
  ) {
    this.API_PREFIX = `${environment.siteUrl}`;
  }

  getCourierRates(siteId: string, courierObj: any): Promise<number> {
    let url = `${this.API_PREFIX}/calculate-shipping/v1/product/${siteId}`;

    return new Promise<number>((resolve) => {
      this.http.post(url, courierObj).subscribe(
        (resp: any) => {
          let totalDeliveryCost = 0;

          // Get cheapest courier rate and calculate total delivery cost
          if (resp?.shippingCost?.length > 0) {
            resp.shippingCost.forEach((x: any) => {
              x.courier.sort((c: any, d: any) => c.cost - d.cost);
              totalDeliveryCost += x.courier[0].cost;
            });
          } else {
            totalDeliveryCost = -1; // Cannot be shipped or some internal error
          }

          resolve(totalDeliveryCost);
        },
        (error: any) => {
          console.error(error); // Handle or log the error
          resolve(-2); // Return -2 for error or exception
        }
      );
    });
  }

  getCourierFormGroup(courier: any, loadFromCookie: boolean = true) {
    const courierCookie =
      loadFromCookie && this.cookieService.get('courier')
        ? JSON.parse(this.cookieService.get('courier'))
        : null;
    let country =
      courierCookie != null
        ? courierCookie.country
        : loadFromCookie
        ? this.siteService.countryCode
        : '';
    let state = courierCookie != null ? courierCookie.state : '';
    let postcode = courierCookie != null ? courierCookie.postcode : '';

    const getCourierFormGroup = (): FormGroup => {
      return new FormGroup({
        country: new FormControl(country, [Validators.required]),
        state: new FormControl(
          state,
          courier?.state ? [Validators.required] : []
        ),
        postcode: new FormControl(
          postcode,
          courier?.postcode ? [Validators.required] : []
        ),
      });
    };

    return getCourierFormGroup;
  }

  hasCourier(courier: any) {
    if (!courier?.country && !courier?.state && !courier?.postcode)
      return false;

    return true;
  }
}
