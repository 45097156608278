import { OnInit, Input, Directive } from '@angular/core';

@Directive()
export class SubCategoryAreaComponent implements OnInit {
  @Input() categories: any[];
  @Input() isEnabled: boolean;
  @Input() width?: number;
  @Input() view: string;
  @Input() nbShowedBoxes: number;

  imageStyle: any;

  protected DEFAULT_NB_SHOWED_BOXES: number;

  constructor() {}

  ngOnInit() {}
}
