<div *ngIf="isEnabled" class="section d-flex">
  <ng-container *ngFor="let ad of ads">
    <div
      *ngIf="ad.isEnabled"
      [ngStyle]="{ width: ad.width, height: ad.height }"
      class="overflow-hidden"
    >
      <a *ngIf="ad.routerLink" [routerLink]="ad.routerLink">
        <img [src]="ad.imageSrc" [alt]="ad.name" class="d-block w-100" />
      </a>

      <a *ngIf="ad.href" [href]="ad.href">
        <img [src]="ad.imageSrc" [alt]="ad.name" class="d-block w-100" />
      </a>
    </div>
  </ng-container>
</div>
