import { tassign } from 'tassign';
import * as itemsViewedActions from './items-viewed.actions';

export interface IItemsViewedState {
  products: any[];
}

export const ITEMS_VIEWED_INITIAL_STATE: IItemsViewedState = {
  products: [],
};

class ItemsViewedActions {
  constructor(private state, private action) {}

  getAll() {
    const { products } = this.action;

    document.dispatchEvent(
      new CustomEvent('gotRecentlyViewed', {
        bubbles: true,
        cancelable: true,
        detail: {
          products,
        },
      })
    );

    return tassign(this.state, {
      products: this.state.products.concat(products),
    });
  }

  addItem() {
    const haveProduct = this.state.products.find(
      (product: any) => product.id === this.action.product.id
    );

    if (haveProduct) {
      return tassign(this.state, {
        products: this.state.products,
      });
    }

    return tassign(this.state, {
      products: this.state.products.concat(this.action.product),
    });
  }

  clear() {
    return tassign(this.state, {
      products: [],
    });
  }
}

export function itemsViewedReducer(
  state: IItemsViewedState = ITEMS_VIEWED_INITIAL_STATE,
  action
): IItemsViewedState {
  const actions = new ItemsViewedActions(state, action);

  switch (action.type) {
    case itemsViewedActions.ADD_ITEM_VIEWED:
      return actions.addItem();
    case itemsViewedActions.GET_ITEMS_VIEWED:
      return actions.getAll();
    case itemsViewedActions.CLEAR_ITEM_VIEWED:
      return actions.clear();
  }

  return state;
}
