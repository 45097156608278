import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { CookieService } from '../../ecommerce/services/cookie/cookie.service';

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss'],
})
export class CookieModalComponent implements OnInit {
  @ViewChild('cookieModalTemplate', { static: true })
  cookieModalTemplate: ElementRef;

  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.modalRef = this.modalService.show(this.cookieModalTemplate as any, {
      class: 'modal-dialog-centered',
    });
  }

  onOKClick() {
    this.modalRef.hide();
    setTimeout(() => this.cookieService.set('cookies-accepted', 'true'), 2000);
  }
}
