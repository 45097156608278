import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, ɵgetDOM } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotifierModule } from 'angular-notifier';
import {
  NgRedux,
  DevToolsExtension,
  NgReduxModule,
} from '@angular-redux2/store';

import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { SessionExpiredComponent } from './components/session-expired/session-expired.component';
import { ErrorComponent } from './error/error.component';

import { CookieService } from 'ngx-cookie-service';

import { ModalModule } from 'ngx-bootstrap/modal';

import { BrowserStateInterceptor } from './interceptors/browserstate.interceptor';

import { ROUTES } from './app.routes';

import { AppConfig } from './app.config';

import { IAppState, rootReducer, INITIAL_STATE } from './store';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CourierService } from './ecommerce/services/courier/courier.service';
import { ShippingService } from './ecommerce/services/shipping/shipping.service';

const APP_PROVIDERS = [AppConfig];
const BROWSERSTATE_PROVIDER = {
  provide: HTTP_INTERCEPTORS,
  useClass: BrowserStateInterceptor,
  multi: true,
};

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    ModalModule.forRoot(),
    NgReduxModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12,
        },
        vertical: {
          position: 'bottom',
          distance: 64,
          gap: 10,
        },
      },
      behaviour: {
        stacking: false,
      },
    }),
    RouterModule.forRoot(ROUTES, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top',
    }),
    // TransferHttpCacheModule,
    NgtUniversalModule,
  ],
  bootstrap: [AppComponent],
  declarations: [AppComponent, ErrorComponent, SessionExpiredComponent],
  providers: [
    APP_PROVIDERS,
    CookieService,
    CourierService,
    ShippingService,
    BROWSERSTATE_PROVIDER,
  ],
})
export class AppModule {
  constructor(
    public ngRedux: NgRedux<IAppState> // devTools: DevToolsExtension
  ) {
    // Note: DevTools are not working here and
    // I belive there's a differnet way to manage it

    // console.log('is dev ' + isDevMode());
    // const enhancers = isDevMode() ? [devTools.enhancer()] : [];

    this.ngRedux.configureStore(rootReducer, INITIAL_STATE);
  }
}
