import { Directive, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { SiteService } from '../../ecommerce/services/site/site.service';
import { CourierService } from '../../ecommerce/services/courier/courier.service';
import { StripeService } from '../../ecommerce/services/stripe/stripe.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CouponService } from '../../ecommerce/services/coupon/coupon.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { ICartState } from '../../ecommerce/store';
import { Observable } from 'rxjs';
import { Select } from '@angular-redux2/store';
import { OrdersService } from '../../ecommerce/services/orders/orders.service';
import { IOrdersState } from '../../ecommerce/store/orders.store';
import { ShippingService } from '../../ecommerce/services/shipping/shipping.service';
import { Router } from '@angular/router';

@Directive()
export class BuynowComponent implements OnInit {
  @ViewChild('buynowModalTemplate')
  buynowModalTemplate: ElementRef;

  @Select((s) => s.cart) cart: Observable<ICartState>;
  @Select((s) => s.orders) orders: Observable<IOrdersState>;

  modalRef: BsModalRef;
  shippingCountriesForm: FormGroup;
  courierForm: FormGroup;
  couponForm: FormGroup;
  showCourierOpts: boolean = false;
  hasCourier: boolean = false;
  onCourier: boolean = false;
  fullCountriesList: any[];
  countryList: any[];
  item: any;
  products: any[];

  stripe: any;
  elements: any;
  readonly STRIPE: any;
  stripeLink: boolean = true;
  showModal: boolean;
  infos: any;
  coupon: any;
  showCheckoutSection: boolean = false;
  courierRate: number;
  totalShippingFee: number = 0;
  shipFeeLoaded: boolean = true;
  couponPercent: number;
  couponCode: string;
  couponDiscount: number = 0;

  //Below are all cart props - final
  currentSelectedCountry: string;
  subTotal: number;
  items: any;
  tax: number;
  totalShippingCost: number;

  nonCourierShippingFee: number = -1;
  nonCourierShippingAddlItemFee: number = 0;

  isStripeLinkEnabled: boolean;

  get f(): { [key: string]: AbstractControl } {
    return this.courierForm?.controls;
  }

  get cf(): { [key: string]: AbstractControl } {
    return this.couponForm.controls;
  }

  get isCourierFormValid(): boolean {
    const courier = this.f.courier as FormGroup;

    for (const key in courier.controls) {
      if (courier.get(key).invalid) {
        return false;
      }
    }

    return true;
  }

  constructor(
    protected router: Router,
    public siteService: SiteService,
    protected cookieService: CookieService,
    protected courierService: CourierService,
    protected stripeService: StripeService,
    protected couponService: CouponService,
    protected cartService: CartService,
    protected ordersService: OrdersService,
    protected shippingService: ShippingService
  ) {}

  ngOnInit() {
    this.shipFeeLoaded = false;
    this.couponCode = null;
    this.couponDiscount = 0;
    this.couponPercent = 0;
    this.currentSelectedCountry = null;
    this.nonCourierShippingFee = -1;
    this.couponService.removeCouponParams();
  }

  async onCourierSubmitClick() {
    this.stripeService.isStripeLinkEnabled$.subscribe((status) => {
      this.isStripeLinkEnabled = status;
    });

    const courier = {
      country: this.f.courier.value.country,
      state: this.f.courier.value.state,
      postcode: this.f.courier.value.postcode,
    };
    this.cookieService.set('courier', JSON.stringify(courier));
    this.onCourier = true;

    await this.cartService.convertCartItemsToBuyNowType(courier);

    //Check in cartService if this item already exits and get exact qty
    let { currentCartItem, items } = await this.getCurrentItemAndOthersFromCart(
      this.infos.product.fullId
    );

    //make http call to get product n shipping cost
    let shippingCostForItem: number = 0;
    let requestObj: any = {};
    if (this.hasCourier) {
      //we now make a call to API to get courier rates (supports few suppliers/merchants only)
      requestObj = this.getCourierRequestObject(courier, currentCartItem);
      shippingCostForItem = await this.courierService.getCourierRates(
        this.siteService.domain,
        requestObj
      );
    }

    //1. Now add item to the cart when shipping has no errors - When error it'll return -1
    if (shippingCostForItem >= 0 || this.nonCourierShippingFee >= 0) {
      await this.cartService.resetCartStatus();
      this.shipFeeLoaded = true;

      let addItemParams = this.getAddCartParams(
        requestObj,
        shippingCostForItem,
        currentCartItem,
        items
      );

      //Do not show addToCart popup if its mobile view - in BuyNow popup
      let notifyAddToCart = !this.siteService.isMobileView;

      await this.cartService.addItem(
        addItemParams.product,
        addItemParams.qty,
        addItemParams.shippingCost,
        addItemParams.country,
        addItemParams?.shippingGroup,
        this.hasCourier,
        notifyAddToCart
      );

      await this.createUpdateOrder();

      this.onCourier = false;
      this.showCheckoutSection = true;

      //2. Then get all items from cart once again
      //Subscribe to cart and keep updating stripeLink
      await this.cart.subscribe(async (cart: ICartState) => {
        const { items, total, totalShippingCost, tax } = cart;

        //Set these props now
        this.subTotal = total;
        this.items = items;
        this.totalShippingCost = totalShippingCost;
        this.tax = tax;
      });

      //Dont sent coupon code here
      await this.stripeService.enableStripLink(
        this.items,
        this.subTotal,
        this.totalShippingCost,
        this.tax
      );
    } else {
      this.onHide();
      const event = new CustomEvent('buynowCannotShip', {
        bubbles: true,
        cancelable: true,
        detail: 'Cannot ship to selected country or postcode',
      });

      document.dispatchEvent(event);
    }

    //reset the status here
    this.hasCourier = false;
  }

  getAddCartParams(
    courierRequestObj: any,
    courierCostForItem: any,
    currentCartItem: any = null,
    cartItems: any
  ) {
    if (!this.hasCourier) {
      //This is for nonCourier
      return this.cartService.getNonCourierCartParams(
        this.nonCourierShippingFee,
        this.nonCourierShippingAddlItemFee,
        this.currentSelectedCountry ?? this.f.courier.value.country,
        currentCartItem ?? this.infos.product,
        cartItems
      );
    } else {
      //This is from courier API service
      return {
        product: this.infos.product,
        qty: courierRequestObj.qty,
        shippingCost: courierCostForItem,
        country: this.currentSelectedCountry ?? this.f.courier.value.country,
        shippingGroup: null,
      };
    }
  }

  async createUpdateOrder() {
    let params: any = this.getOrderParam();

    if (!params) return;

    //Create order here first
    if (!this.siteService?.order?.id) {
      //create order
      await this.ordersService.createOrderAsync(params);

      //subscribe to order state now
      this.getTotalShippingFee();
    } else {
      params.id = this.siteService?.order?.id;
      await this.ordersService.updateOrderAsync(params);

      this.getTotalShippingFee();
    }
  }

  getOrderParam() {
    if (!this.f) return null;

    return {
      cartId: this.siteService.cartId,
      userId: this.siteService?.user?.id ?? this.siteService?.userId,
      siteId: this.siteService.domain,
      ipAddress: this.siteService.ip,
      shippingAddress: {
        country: this.f.courier.value.country,
        state: this.f.courier.value.state,
        postcode: this.f.courier.value.postcode,
      },
      billingAddress: {
        country: this.f.courier.value.country,
        state: this.f.courier.value.state,
        postcode: this.f.courier.value.postcode,
      },
    };
  }

  getTotalShippingFee() {
    this.orders.subscribe((orders: IOrdersState) => {
      let response = this.ordersService.getTotalShippingCostAndProducts(
        orders.shippingDetails
      );

      this.totalShippingFee = response.totalDeliveryCost;
      this.shipFeeLoaded = true;
    });
  }

  async getCouponPercent($event) {
    this.couponPercent = $event;
    this.couponDiscount = this.subTotal * ($event / 100);
  }

  async getCouponCode($event) {
    this.couponCode = $event;

    //if coupon has value then regenerate the stripe link
    if (this.couponDiscount > 0 && this.couponCode != null) {
      //Apply coupon for the cart
      var params = {
        userId: this.siteService?.user?.id ?? this.siteService.userId,
        siteId: this.siteService.domain,
        cartId: this.siteService.cartId,
        couponCode: this.couponCode,
        country: this.currentSelectedCountry,
      };

      //save params in cookie
      this.cookieService.set('couponParams', JSON.stringify(params));

      var couponResp = await this.cartService.applyCoupon(params);

      let subTotal = couponResp.total - couponResp.couponDiscountAmount;
      let tax = couponResp.tax;

      await this.stripeService.enableStripLink(
        this.items,
        subTotal,
        couponResp.totalShippingCost,
        tax,
        this.couponCode
      );
    }
  }

  async getCurrentItemAndOthersFromCart(prodId: string) {
    var cartResponse = await this.cartService.getAllItems();
    let items = cartResponse?.items;
    if (items === null || items === undefined)
      return {
        currentCartItem: null,
        items: null,
      };

    const currentCartItem = items.find((item) => item.fullId === prodId);
    return { currentCartItem, items };
  }

  getCourierRequestObject(courier: any, cartItem: any) {
    let qty = 1;
    if (cartItem?.qty) qty += cartItem?.qty;

    return {
      prodId: cartItem?.id ?? this.infos.product.id,
      isQubyk: cartItem?.isQubyk ?? this.infos.product.isQubyk ?? false,
      isVariation:
        cartItem?.isVariation ?? this.infos.product.isVariation ?? false,
      variationSku:
        cartItem?.variationSku ?? this.infos.product.variationSku ?? null,
      weight: this.infos.weight ?? null,
      weightUnit: this.infos.weightUnit ?? null,
      qty: qty,
      currency: this.siteService.currency,
      country: courier.country,
      state: courier.state,
      postcode: courier.postcode,
    };
  }

  onHide() {
    this.showModal = false;
    this.couponService.removeCouponParams();
  }

  onCountryChange(event) {
    //we apply nonCourierShippingFee for products with no courier service support
    let hasCourier = this.courierService.hasCourier(this.infos.courier);
    if (hasCourier) return;

    let selectedCountryCode = event.target.value;
    let countryCode = this.extractLastTwoCharacters(selectedCountryCode);
    this.currentSelectedCountry = countryCode;

    //get shippingCost from this.infos.shipping based on country code
    let nonCourierCost = this.shippingService.getNonCourierShippingCost(
      countryCode,
      this.infos.product
    );

    this.nonCourierShippingFee = nonCourierCost.shippingFee;
    this.nonCourierShippingAddlItemFee = nonCourierCost.addFee;
  }

  extractLastTwoCharacters(inputString: string) {
    if (inputString.length >= 2) {
      const lastTwoCharacters = inputString.slice(-2);
      return lastTwoCharacters;
    } else {
      // Handle the case where the input string is less than 2 characters
      return inputString;
    }
  }

  async onDefaultCheckoutClick() {
    // Dispatch the custom event
    const event = new CustomEvent('buynowPopupClose', {
      bubbles: true,
      cancelable: true,
    });
    document.dispatchEvent(event);

    // Wait for a certain time before navigation (5000ms or 5 seconds)
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    // Navigate to the desired route
    this.router.navigate(['/checkout']);
  }
}
