<ng-template #productDetailReviewsModalTemplate>
  <div class="modal-header">
    <h5 class="modal-title">Reviews</h5>
    <button (click)="hide()" type="button" class="close">
      <span class="fa fa-times"></span>
    </button>
  </div>

  <div class="modal-body">
    <h3 *ngIf="!reviews" class="text-center">No Reviews</h3>

    <div *ngFor="let review of reviews">
      <div>
        <div class="rating">
          <span>{{ review.rating }}</span>
          <span class="fa fa-star"></span>
        </div>

        <span>{{ review.name }}</span>
      </div>

      <div>
        {{ review.message }}
      </div>
    </div>
  </div>
</ng-template>
