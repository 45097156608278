import { Component, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { RegisterComponent } from './register.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { SocialLoginService } from '../../ecommerce/services/social-login/social-login.service';
import { SeoService } from '../../ecommerce/seo/seo.service';

@Component({
  selector: 'app-register-desktop',
  templateUrl: './register-desktop.component.html',
  styleUrls: ['./register-desktop.component.scss'],
})
export class RegisterDesktopComponent extends RegisterComponent {
  show: boolean;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected seoService: SeoService,

    public siteService: SiteService,
    public socialLoginService: SocialLoginService
  ) {
    super(
      router,
      activatedRoute,
      cookieService,
      accountService,
      seoService,
      siteService,
      socialLoginService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    // TO CHECK
    setTimeout(() => (this.show = true), 250);

    this.seoService.setTitle('Register');

    const getPasswordControl = () => {
      return new FormControl('', [
        Validators.required,
        Validators.minLength(this.MIN_PASSWORD_LENGTH),
        Validators.maxLength(this.MAX_PASSWORD_LENGTH),
        Validators.pattern(/^.*(?=.*?[0-9])(?=.*?[^\w]).*$/),
      ]);
    };

    this.registerForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      ]),
      password: getPasswordControl(),
      recaptcha: new FormControl(false, [Validators.required]),
    });

    this.passwordType = 'password';

    this.onRegister = 'false';
    this.onInstagramLogin = 'false';
  }

  onRegisterClick(recaptcha: any) {
    const params: any = {
      email: this.f.email.value,
      password: this.f.password.value,
      captchaResponse: this.recaptchaValue,
      siteId: this.siteService.domain,
    };

    this.onRegister = 'true';

    if (recaptcha) recaptcha.recaptchaAPI.reset();

    this.accountService.register(params).subscribe(
      () => {
        this.onRegister = 'success';
      },
      () => {
        this.onRegister = 'error';
      }
    );
  }
}
