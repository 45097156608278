import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-product-detail-reviews',
  templateUrl: './product-detail-reviews.component.html',
  styleUrls: ['./product-detail-reviews.component.scss'],
})
export class ProductDetailReviewsComponent implements OnInit {
  @ViewChild('productDetailReviewsModalTemplate')
  productDetailReviewsModalTemplate: ElementRef;
  @Input() reviews: any[];

  modalRef: BsModalRef;

  constructor(private modalService: BsModalService) {}

  ngOnInit() {}

  open() {
    this.modalRef = this.modalService.show(
      this.productDetailReviewsModalTemplate as any
    );
  }

  hide() {
    this.modalRef.hide();
  }
}
