import {
  OnInit,
  Input,
  HostListener,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Inject,
  Directive,
} from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';
import { Select } from '@angular-redux2/store';
import { Observable, Subscription } from 'rxjs';

import { IWishListState } from '../../ecommerce/store/wish-list.store';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { ProductsService } from '../../ecommerce/services/products.service';
import { SearchService } from '../../ecommerce/services/search/search.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { WishListService } from '../../ecommerce/services/wish-list/wish-list.service';

@Directive()
export class ProductCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showTotalItems?: boolean = true;
  @Input() totalItems?: number = 0;
  @Input() products: any[];
  @Input() nbColumns: number;
  @Select((s: any) => s.wishList) wishList: Observable<IWishListState>;

  wishListSubscription: Subscription;

  isImageLoaded: boolean[];
  onAddToCard: string[];

  style: any;

  constructor(
    @Inject(WINDOW) protected window: Window,
    protected router: Router,
    protected productService: ProductsService,
    protected searchService: SearchService,

    public siteService: SiteService,
    public cartService: CartService,
    public wishListService: WishListService
  ) {}

  get onGetProducts(): boolean {
    if (this.router.url.includes('/search')) {
      return this.searchService.onSearch;
    }

    return this.productService.onGetProducts;
  }

  ngOnInit() {
    this.onAddToCard = [];
    this.isImageLoaded = [];

    this.setConfiguration();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setInWishList();
  }

  ngOnDestroy() {
    if (this.wishListSubscription) {
      this.wishListSubscription.unsubscribe();
    }
  }

  setConfiguration() {
    if (this.nbColumns === undefined) {
      this.style = {
        'grid-template-columns': 'repeat(4, 1fr)',
      };
    } else {
      this.style = {
        'grid-template-columns': `repeat(${this.nbColumns}, 1fr)`,
      };
    }
  }

  setInWishList() {
    if (this.wishListSubscription) {
      this.wishListSubscription.unsubscribe();
    }

    this.wishListSubscription = this.wishList.subscribe(
      (wishList: IWishListState) => {
        for (const product of this.products) {
          product.inWishList = wishList.ids.includes(product.id);
        }
      }
    );
  }

  isOnWishListChange(id: string): boolean {
    return (
      this.wishListService.onAddItem[id] === 'true' ||
      this.wishListService.onDeleteItem[id] === 'true'
    );
  }

  onCardImageLoaded(productId: string) {
    this.isImageLoaded[productId] = true;
  }

  onCardMouseEnter(product: any): void {
    if (product.image[1]) {
      product.currentImage = product.image[1];
    }
  }

  onCardMouseLeave(product: any): void {
    product.currentImage = product.image[0];
  }

  onAddProductCartClick(product: any) {
    this.cartService.addItem(product);
  }

  onStarClick(product: any) {
    product = JSON.parse(JSON.stringify(product));

    if (product.inWishList) {
      this.wishListService.deleteItem(product.id);
    } else {
      this.wishListService.addItem(product);
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setConfiguration();
  }
}
