<div class="section" [class.padding]="padding">
  <div class="d-flex align-items-baseline pb-4">
    <div [class.flex-grow-1]="link === undefined">
      <h4 [class]="titleClass" class="section-title">{{title}}</h4>
    </div>

    <div *ngIf="link">
      <a [routerLink]="link.routerLink">
        <h5>{{link.name}}</h5>
      </a>
    </div>
  </div>

  <ng-content></ng-content>
</div>
