import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { LoginComponent } from './login.component';

import { SiteService } from '../../ecommerce/services/site/site.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { AccountService } from '../../ecommerce/services/account/account.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { OrdersService } from '../../ecommerce/services/orders/orders.service';
import { ItemsViewedService } from '../../ecommerce/services/items-viewed/items-viewed.service';
import { SocialLoginService } from '../../ecommerce/services/social-login/social-login.service';
import { WishListService } from '../../ecommerce/services/wish-list/wish-list.service';
import { SeoService } from '../../ecommerce/seo/seo.service';

declare let FB: any;

@Component({
  selector: 'app-login-desktop',
  templateUrl: './login-desktop.component.html',
  styleUrls: ['./login-desktop.component.scss'],
})
export class LoginDesktopComponent extends LoginComponent {
  show: boolean;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cookieService: CookieService,
    protected accountService: AccountService,
    protected cartService: CartService,
    protected ordersService: OrdersService,
    protected itemsViewedService: ItemsViewedService,
    protected wishListService: WishListService,
    protected seoService: SeoService,

    public siteService: SiteService,
    public socialLoginService: SocialLoginService
  ) {
    super(
      router,
      activatedRoute,
      cookieService,
      accountService,
      cartService,
      ordersService,
      itemsViewedService,
      wishListService,
      seoService,
      siteService,
      socialLoginService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => (this.show = true), 250);

    this.seoService.setTitle('Login');

    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(this.MIN_PASSWORD_LENGTH),
        Validators.maxLength(this.MAX_PASSWORD_LENGTH),
        Validators.pattern(/^.*(?=.*?[0-9])(?=.*?[^\w]).*$/),
      ]),
      recaptcha: new FormControl(false, [Validators.required]),
    });

    this.onLogin = 'false';
    this.onInstagramLogin = 'false';
  }

  onLoginClick(recaptcha: any) {
    const params = {
      email: this.f.email.value,
      password: this.f.password.value,
      captchaResponse: this.recaptchaValue,
    };

    this.onLogin = 'true';

    if (recaptcha) recaptcha.recaptchaAPI.reset();

    this.accountService.login(params).subscribe(
      (data: any) => {
        const user = {
          id: data.memberId,
          token: data.token,
          tokenType: 'general',
        };

        this.siteService.user = user;
        this.onLogin = 'success';

        this.cookieService.set('user', JSON.stringify(user));

        const cartId = this.cookieService.get('cartId');
        const order = this.cookieService.get('order');

        if (cartId) {
          this.siteService.cartId = cartId;
        }

        if (order) {
          this.siteService.order = JSON.parse(order);
        }

        if (this.siteService.cartId) {
          this.cartService.getAllItems();
        }

        if (this.siteService.order) {
          this.ordersService.getOrder();
        }

        this.accountService.getMember();
        this.wishListService.getAll();

        // Update user id
        const updateParams = {
          siteId: this.siteService.domain,
          tempId: this.siteService.userId,
          userId: this.siteService.user.id,
          currency: this.siteService.currency,
        };

        this.itemsViewedService.updateUserId(updateParams).subscribe(
          () => {
            this.itemsViewedService.clear();
            this.itemsViewedService.get();
          },
          () => {
            this.siteService.unsetUserId();
          }
        );

        this.router.navigate(['/']);
      },
      (error: HttpErrorResponse) => {
        this.onLogin = 'error';
        this.loginError = error.error.message;
      }
    );
  }

  onBackClick() {
    if (this.siteService.previousPage === '/login') {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([this.siteService.previousPage]);
    }
  }

  onSignUpClick() {
    this.router.navigate(['/register']);
  }

  onPasswordForgottenClick() {
    super.onPasswordForgottenClick();
  }
}
