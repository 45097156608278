<div *ngIf="isEnabled" id="this">
  <ng-container *ngIf="view === 'slider'">
    <app-carousel-items
      *ngIf="ads"
      [width]="width"
      [nbItemsDisplayed]="nbShowedBoxes"
      [hideControls]="true"
      [showScrollBar]="true"
      [items]="ads"
      [$item]="itemTemplate"
    ></app-carousel-items>
  </ng-container>

  <ng-container *ngIf="view === 'vertical'">
    <ng-container *ngFor="let ad of ads">
      <ng-container *ngTemplateOutlet="adTemplate; context: { ad: ad }">
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #prevTemplate>
    <button class="carousel-btn carousel-btn-left">
      <i class="fa fa-angle-left fa-2x"></i>
    </button>
  </ng-template>

  <ng-template #nextTemplate>
    <button class="carousel-btn carousel-btn-right">
      <i class="fa fa-angle-right fa-2x"></i>
    </button>
  </ng-template>

  <ng-template let-ad #itemTemplate>
    <ng-container
      *ngTemplateOutlet="adTemplate; context: { ad: ad }"
    ></ng-container>
  </ng-template>

  <ng-template #adTemplate let-ad="ad">
    <div *ngIf="ad.isEnabled" [ngStyle]="imageStyle" class="this-item">
      <a *ngIf="ad.routerLink" [routerLink]="ad.routerLink">
        <img [src]="ad.imageSrc" [alt]="ad.name" class="d-block w-100" />
      </a>

      <a *ngIf="ad.href" [href]="ad.href">
        <img [src]="ad.imageSrc" [alt]="ad.name" class="d-block w-100" />
      </a>
    </div>
  </ng-template>
</div>
