import { Component, OnInit } from '@angular/core';
import { SiteService } from '../../ecommerce/services/site/site.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

  constructor(public siteService: SiteService) { }

  ngOnInit() {
  }

}
