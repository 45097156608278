import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { SiteService } from '../../ecommerce/services/site/site.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss'],
})
export class SessionExpiredComponent implements OnInit {
  @ViewChild('sessionExpiredModalTemplate')
  sessionExpiredModalTemplate: ElementRef;

  modalRef: BsModalRef;
  show: boolean;

  constructor(
    private modalService: BsModalService,
    private siteService: SiteService
  ) {}

  ngOnInit() {
    if (this.siteService.IS_BROWSER) {
      setTimeout(() => (this.show = true), 1000);
    }
  }

  open() {
    this.modalRef = this.modalService.show(this.sessionExpiredModalTemplate as any);
  }

  onOKClick() {
    this.modalRef.hide();
  }
}
