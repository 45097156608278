import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
  HostListener, Inject,
} from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'categories-area-mobile',
  templateUrl: './categories-area-mobile.component.html',
  styleUrls: ['./categories-area-mobile.component.scss'],
})
export class CategoriesAreaMobileComponent implements OnInit, AfterViewInit {
  readonly DEFAULT_NB_SHOWED_BOXES: number;

  @Input() view: string;
  @Input() textAlign: string;
  @Input() articles: any[];
  @Input() width: number;
  @Input() nbShowedBoxes: number;

  articleCardStyle: any;

  constructor(@Inject(WINDOW) private window: Window, private changeDetectorRef: ChangeDetectorRef) {
    this.DEFAULT_NB_SHOWED_BOXES = 2;
  }

  ngOnInit() {
    if (!this.view) {
      this.view = 'slider';
    }

    this.setConfiguration();
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  setConfiguration() {
    if (this.width === undefined) {
      this.width = this.window.innerWidth;
    }

    if (this.nbShowedBoxes === undefined) {
      if (this.window.innerWidth <= 500) {
        this.nbShowedBoxes = 1;
      } else {
        this.nbShowedBoxes = this.DEFAULT_NB_SHOWED_BOXES;
      }
    }

    const width = `${100 / this.nbShowedBoxes}%`;

    this.articleCardStyle = {
      flex: `0 0 ${width}`,
      'max-width': width,
    };
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setConfiguration();
  }
}
