import { tassign } from 'tassign';

import { ICartItem } from '../../models/carts/ICartItem';

import * as wishListActions from './wish-list.actions';

export interface IWishListState {
  items: ICartItem[];
  ids: string[];
}

export const WISH_LIST_INITIAL_STATE: IWishListState = {
  items: [],
  ids: [],
};

class WishListActions {
  constructor(private state, private action) {}

  getWishList() {
    const { items } = this.action;
    const ids = items.map((item: ICartItem) => item.id);

    return tassign(this.state, {
      items,
      ids,
    });
  }

  addItem() {
    const { items, ids } = this.state;
    const { item } = this.action;

    item.id = item.prodId;

    if (items.includes(item.id) === false) {
      items.push(item);
      ids.push(item.id);

      return tassign(this.state, {
        items,
        ids,
      });
    }

    return tassign(this.state);
  }

  deleteItem() {
    const { id } = this.action;
    let { items, ids } = this.state;

    items = items.filter((item: any) => item.id !== id);
    ids = ids.filter((currentId: string) => currentId !== id);

    return tassign(this.state, {
      items,
      ids,
    });
  }

  clear() {
    return tassign(this.state, WISH_LIST_INITIAL_STATE);
  }
}

export function wishListReducer(
  state: IWishListState = WISH_LIST_INITIAL_STATE,
  action
): IWishListState {
  const actions = new WishListActions(state, action);

  switch (action.type) {
    case wishListActions.GET_WISH_LIST_SUCCESS:
      return actions.getWishList();
    case wishListActions.ADD_ITEM_WISH_LIST_SUCCESS:
      return actions.addItem();
    case wishListActions.DELETE_ITEM_WISH_LIST_SUCCESS:
      return actions.deleteItem();
    case wishListActions.CLEAR_WISH_LIST:
      return actions.clear();
  }

  return state;
}
