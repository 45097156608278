import { tassign } from 'tassign';
import * as productsActions from './products.actions';
import { IProduct } from '../../models/products/IProduct';

export interface IProductsState {
  products: IProduct[];
  nav: string;
  stack: boolean;
  preStack: boolean;
}

export const PRODUCTS_INITIAL_STATE: IProductsState = {
  products: [],
  nav: '',
  stack: false,
  preStack: false,
};

class ProductsActions {
  constructor(private state, private action) {}

  getProducts() {
    return tassign(this.state, {
      products: this.state.products,
      nav: this.state.nav,
      stack: this.state.stack,
      preStack: this.state.preStack,
    });
  }

  addProducts() {
    return tassign(this.state, {
      products: this.action.prods.products,
      nav: this.state.nav,
      stack: this.state.stack,
      preStack: this.state.preStack,
    });
  }

  addProductsInStart(products: IProduct[]) {
    if (products) {
      this.action.prods.products = [...this.action.prods.products, ...products];
    }

    return tassign(this.state, {
      products: this.action.prods.products,
      nav: this.state.nav,
      stack: this.state.stack,
      preStack: this.state.preStack,
    });
  }

  addProductsInEnd(products: IProduct[]) {
    if (products) {
      this.action.prods.products = [...products, ...this.action.prods.products];
    }

    return tassign(this.state, {
      products: this.action.prods.products,
      nav: this.state.nav,
      stack: this.state.stack,
      preStack: this.state.preStack,
    });
  }

  deleteProducts() {
    return tassign(this.state, {
      products: [],
      nav: this.state.nav,
      stack: false,
      preStack: false,
    });
  }
}

export function productsReducer(
  state: IProductsState = PRODUCTS_INITIAL_STATE,
  action
): IProductsState {
  const prodActions = new ProductsActions(state, action);

  switch (action.type) {
    case productsActions.GET_PRODUCTS:
      return prodActions.getProducts();
    case productsActions.GET_PRODUCTS_SUCCESS:
      if (action.prods.stack) {
        return prodActions.addProductsInEnd(state.products);
      } else if (action.prods.preStack) {
        return prodActions.addProductsInStart(state.products);
      }

      return prodActions.addProducts();
    case productsActions.DELETE_PRODUCTS:
      return prodActions.deleteProducts();
  }

  return state;
}

/*
 loadProducts(products?: IProduct[]) {
    if (products) {
      this.action.prods.products = [...products, ...this.action.prods.products];
    }

    return tassign(this.state, {
      products: this.action.prods.products,
      nav: this.state.nav,
      stack: this.state.stack,
    });
  }
*/
