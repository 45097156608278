import { tassign } from 'tassign';
import * as ordersActions from './orders.action';

export interface IOrdersState {
  id: string;
  cartId: string;
  shippingAddress: {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    phone: string;
  };
  billingAddress: {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
    phone: string;
  };
  status: string;
  shippingCost: number;
  shippingDetails: {
    provider: string;
    products: string[];
    courier: {
      id: string;
      courierName: string;
      cost: number;
      weight: number;
      deliveryTime: string;
      currency: string;
    }[];
  }[];
}

export const ORDERS_INITIAL_STATE: IOrdersState = {
  id: '',
  cartId: '',
  shippingAddress: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    phone: '',
  },
  billingAddress: {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    phone: '',
  },
  status: '',
  shippingCost: 0,
  shippingDetails: [],
};

class OrdersActions {
  constructor(private state, private action) {}

  getOrder() {
    return tassign(this.state, { ...this.action });
  }

  createOrder() {
    return tassign(this.state, { ...this.action });
  }

  reqOrderError() {
    const data: any = JSON.parse(JSON.stringify(this.state));
    data.status = this.action.status;
    return tassign(this.state, { ...data });
  }

  clear() {
    return tassign(this.state, ORDERS_INITIAL_STATE);
  }
}

export function ordersReducer(
  state: IOrdersState = ORDERS_INITIAL_STATE,
  action
): IOrdersState {
  const actions = new OrdersActions(state, action);

  switch (action.type) {
    case ordersActions.GET_ORDER_SUCCESS:
      return actions.getOrder();
    case ordersActions.CREATE_ORDER_SUCCESS:
      return actions.createOrder();
    case ordersActions.CLEAR_ORDER_SUCCESS:
      return actions.clear();
    case ordersActions.REQ_ORDER_ERROR:
      return actions.reqOrderError();
  }

  return state;
}
