import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux2/store';

import {
  GET_WISH_LIST_SUCCESS,
  ADD_ITEM_WISH_LIST_SUCCESS,
  DELETE_ITEM_WISH_LIST_SUCCESS,
  CLEAR_WISH_LIST,
  REQ_WISH_LIST_ERROR,
} from '../../store/wish-list.actions';

import { IWishListState } from '../../store/wish-list.store';

import { ICartItem } from '../../../models/carts/ICartItem';

import { SiteService } from '../site/site.service';
import { ProductsService } from '../products.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WishListService {
  readonly API_PREFIX: string;

  onGetAllItems: string;
  onAddItem: string[];
  onDeleteItem: string[];

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngRedux: NgRedux<IWishListState>,
    private siteService: SiteService,
    private productsService: ProductsService
  ) {
    this.API_PREFIX = `${environment.siteUrl}/api/wish-list/${environment.apiVersion}`;

    this.onGetAllItems = 'false';
    this.onAddItem = [];
    this.onDeleteItem = [];
  }

  openLogin() {
    if (this.siteService.isMobileView) {
      this.router.navigate(['/login'], {
        queryParams: { redirectTo: this.router.url },
      });
    } else {
      const event = new CustomEvent('openLogin', {
        bubbles: true,
        cancelable: true,
      });

      document.dispatchEvent(event);
    }
  }

  getAll() {
    const url = `${this.API_PREFIX}/${this.siteService.domain}/${this.siteService.currency}/${this.siteService.user.id}/all`;

    this.onGetAllItems = 'true';

    this.http
      .get(url, {
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        (data: any) => {
          if (data.status === 'Success' && data.data.length) {
            const items = data.data;

            this.siteService.setProductsImages(items);
            this.productsService.setProductsFullId(items);

            this.onGetAllItems = 'success';

            return this.ngRedux.dispatch({
              type: GET_WISH_LIST_SUCCESS,
              items,
            });
          } else {
            this.onGetAllItems = 'error';
          }
        },
        () => {
          this.onGetAllItems = 'error';

          return this.ngRedux.dispatch({
            type: REQ_WISH_LIST_ERROR,
            items: [],
          });
        }
      );
  }

  addItem(product: ICartItem) {
    if (!this.siteService.user) {
      this.openLogin();
      return;
    }

    const {
      brand,
      title,
      size,
      price,
      rrp,
      image,
      category,
      subCat,
      childCat,
      currency,
      discount,
    } = product;
    const params: any = {
      siteId: this.siteService.domain,
      userId: this.siteService.user.id,
      prodId: product.id,
      currency,
      discount,
      brand,
      title,
      size,
      price,
      rrp,
      image,
      category,
      subCat,
      childCat,
    };

    this.onAddItem[product.id] = 'true';

    this.siteService.unsetProductsImage([product]);

    this.http
      .post(`${this.API_PREFIX}/add`, params, {
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        (data: any) => {
          this.onAddItem[product.id] = 'success';

          return this.ngRedux.dispatch({
            type: ADD_ITEM_WISH_LIST_SUCCESS,
            item: params,
          });
        },
        () => {
          delete this.onAddItem[product.id];

          return this.ngRedux.dispatch({
            type: REQ_WISH_LIST_ERROR,
          });
        }
      );
  }

  deleteItem(id: string) {
    if (!this.siteService.user) {
      this.openLogin();
      return;
    }

    const params = {
      siteId: this.siteService.domain,
      userId: this.siteService.user.id,
      currency: this.siteService.currency,
      prodId: id,
    };

    this.onDeleteItem[id] = 'true';

    this.http
      .request('delete', `${this.API_PREFIX}/remove`, {
        body: params,
        headers: this.siteService.getAuthorizationHeader(),
      })
      .subscribe(
        () => {
          this.onDeleteItem[id] = 'success';

          return this.ngRedux.dispatch({
            type: DELETE_ITEM_WISH_LIST_SUCCESS,
            id,
          });
        },
        () => {
          delete this.onDeleteItem[id];

          return this.ngRedux.dispatch({
            type: REQ_WISH_LIST_ERROR,
          });
        }
      );
  }

  clear() {
    return this.ngRedux.dispatch({
      type: CLEAR_WISH_LIST,
    });
  }
}
