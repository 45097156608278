<!-- <ng-container *ngIf="templateUrl; else loadingTemplate">
  <ng-container *ngTemplateOutlet="loadedTemplate"></ng-container>
</ng-container>
<ng-template #loadingTemplate>Loading...</ng-template> -->
<!-- start of banner -->
<section class="banner bg-white overflow-hidden">
  <div class="container">
    <div class="row">
      <div class="col-xl-7 col-lg-9 col-md-10 position-relative mx-auto">
        <h1 class="title display-1 d-inline mb-0">
          <svg
            class="shape text-primary"
            width="46"
            height="50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15 46A91.9 91.9 0 0 0 2 43.8c-1-.1-1.8.5-1.9 1.5-.1.9.6 1.7 1.4 1.8a87 87 0 0 1 12.7 2.3c.9.3 1.8-.2 2.1-1.1.3-1-.3-1.9-1.1-2.1ZM28.1 29c-7-7-14.7-13.2-21.5-20.5-.6-.7-1.7-.7-2.3 0-.7.6-.8 1.6-.1 2.3C11 18 18.8 24.2 25.7 31.3c.7.7 1.7.7 2.4 0 .6-.6.7-1.7 0-2.3ZM41 1.8l.7 12.1c0 1 .8 1.7 1.7 1.6 1 0 1.6-.8 1.6-1.7l-.6-12.2c0-1-.8-1.6-1.8-1.6-.8 0-1.6.9-1.5 1.8Z"
              fill="currentColor"
            />
          </svg>
          Latest News Updates and Tips
        </h1>
      </div>
    </div>
  </div>
</section>
<!-- end of banner -->

<!-- start of Recent Posts -->
<section class="section">
  <div class="container">
    <div class="row align-items-center section-title">
      <div class="col-sm-7">
        <h2 class="h3 mb-0 title">Recent Posts</h2>
      </div>
      <!-- <div class="col-sm-5 text-end d-none d-sm-block">
        <a class="text-link lead active">
          All Posts <i class="ti ti-arrow-up-right"></i>
        </a>
      </div> -->
    </div>
    <div class="row gy-5 gx-md-5">
      <div *ngFor="let blog of blogs; let i = index" class="col-lg-4 col-md-6">
        <article class="bg-white d-flex flex-column h-100">
          <div class="post-image">
            <a
              class="d-block"
              [href]="'blog/' + blog?.href"
              (click)="redirectBlog(blog?.href)"
              title="What Do You Do When You Know You’re Not Fine?"
            >
              <img
                loading="lazy"
                class="w-100 h-auto"
                [src]="blog?.imageSrc"
                [alt]="blog?.title"
                width="400"
                height="264"
              />
            </a>
          </div>
          <div class="p-4 pb-0">
            <ul class="post-meta list-inline mb-3">
              <li class="list-inline-item">
                <i class="ti ti-calendar-event me-1"></i
                >{{ blog?.updated | date }}
              </li>
              <li class="list-inline-item">•</li>
              <li class="list-inline-item">
                <i class="ti ti-clock-2 me-1"></i>02 min read
              </li>
            </ul>
            <div class="position-relative">
              <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                <a
                  class="text-link stretched-link"
                  [href]="'blog/' + blog?.href"
                  (click)="redirectBlog(blog?.href)"
                  title="Read more about - What Do You Do When You Know You’re Not Fine?"
                  >{{ blog?.title }}</a
                >
              </h3>
              <p class="mb-0 line-clamp clamp-3">
                {{ blog?.subTitle }}
              </p>
            </div>
          </div>
          <div class="post-author d-flex mt-auto p-4">
            <div class="flex-shrink-0">
              <a
                (click)="redirectBlog(blog?.href)"
                class="is-hoverable"
                title="Read all posts of - Robert Britt"
              >
                <img
                  loading="lazy"
                  class="rounded-circle w-auto"
                  src="../../../assets/blog/templates/AU/assets/images/author/qubyk-logo.png"
                  [alt]="blog?.editor"
                  width="42"
                  height="42"
                />
              </a>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="mb-0 lh-base small">Written by</p>
              <a
                class="text-link"
                [href]="'blog/' + blog?.href"
                (click)="redirectBlog(blog?.href)"
                title="Read all posts by - Robert Britt"
                >{{ blog?.editor }}</a
              >
            </div>
          </div>
        </article>
      </div>
      <div class="col-12 text-center pt-5 mt-5">
        <ul class="pagination justify-content-center">
          <li
            class="page-item"
            [className]="currentPage == 1 ? 'disabled' : ''"
          >
            <a
              [className]="
                currentPage == 1
                  ? 'page-link page-link-previous bg-transparent rounded-0 px-0 border-0 text-dark text-link active text-uppercase fw-medium disable-link'
                  : 'page-link page-link-previous bg-transparent rounded-0 px-0 border-0 text-dark text-link active text-uppercase fw-medium'
              "
              (click)="previous()"
              aria-label="Pagination Arrow"
            >
              <i class="ti ti-chevron-left"></i>
              <span>Previous</span>
            </a>
          </li>
          <li class="page-item page-count" title="Page 1 of 2">
            <span class="current-page">{{ currentPage }}</span>
            <span class="total-page">{{ totalPages }}</span>
          </li>
          <li class="page-item">
            <a
              [className]="
                currentPage >= totalPages
                  ? 'page-link bg-transparent rounded-0 px-0 border-0 text-dark text-link active text-uppercase fw-medium disable-link'
                  : 'page-link bg-transparent rounded-0 px-0 border-0 text-dark text-link active text-uppercase fw-medium'
              "
              (click)="next()"
              aria-label="Pagination Arrow"
              disabled
            >
              <span>Next</span>
              <i class="ti ti-chevron-right"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
<!-- end of Recent Posts -->

<!-- start of Featured Posts -->
<section class="featured-posts section bg-white">
  <div class="container">
    <div class="row align-items-center section-title">
      <div class="col-sm-7">
        <h1 class="h3 mb-0 title">Featured Posts</h1>
      </div>
      <!-- <div class="col-sm-5 text-end d-none d-sm-block">
        <a class="text-link lead active" href="featured-posts.html">
          Featured Posts <i class="ti ti-arrow-up-right"></i>
        </a>
      </div> -->
    </div>
    <div class="row gy-5 gx-md-5">
      <div class="col-lg-4 col-md-6 order-0">
        <article class="bg-white d-flex flex-column h-100">
          <div class="post-image">
            <a
              class="d-block"
              [href]="'blog/' + featuredBlogs[0]?.href"
              (click)="redirectBlog(featuredBlogs[0]?.href)"
              title="What Do You Do When You Know You’re Not Fine?"
            >
              <img
                loading="lazy"
                class="w-100 h-auto"
                [src]="featuredBlogs[0]?.imageSrc"
                [alt]="featuredBlogs[0]?.title"
                width="400"
                height="264"
              />
            </a>
          </div>
          <div class="p-4 pb-0">
            <ul class="post-meta list-inline mb-3">
              <li class="list-inline-item">
                <i class="ti ti-calendar-event me-1"></i
                >{{ featuredBlogs[0]?.updated | date }}
              </li>
              <li class="list-inline-item">•</li>
              <li class="list-inline-item">
                <i class="ti ti-clock-2 me-1"></i
                >{{ featuredBlogs[0]?.readTime }} min read
              </li>
            </ul>
            <div class="position-relative">
              <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                <a
                  class="text-link stretched-link"
                  [href]="'blog/' + featuredBlogs[0]?.href"
                  (click)="redirectBlog(featuredBlogs[0]?.href)"
                  title="{{ featuredBlogs[0]?.title }}"
                  >{{ featuredBlogs[0]?.title }}</a
                >
              </h3>
              <p class="mb-0 line-clamp clamp-3">
                {{ featuredBlogs[0]?.subTitle }}
              </p>
            </div>
          </div>
          <div class="post-author d-flex mt-auto p-4">
            <div class="flex-shrink-0">
              <a
                [href]="'blog/' + featuredBlogs[0]?.href"
                (click)="redirectBlog(featuredBlogs[0]?.href)"
                class="is-hoverable"
                title="Read all posts of - {{ featuredBlogs[0]?.editor }}"
              >
                <img
                  loading="lazy"
                  class="rounded-circle w-auto"
                  src="../../../assets/blog/templates/AU/assets/images/author/qubyk-logo.png"
                  [alt]="featuredBlogs[0]?.editor"
                  width="42"
                  height="42"
                />
              </a>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="mb-0 lh-base small">Written by</p>
              <a
                class="text-link"
                [href]="'blog/' + featuredBlogs[0]?.href"
                (click)="redirectBlog(featuredBlogs[0]?.href)"
                title="Read all posts by - {{ featuredBlogs[0]?.editor }}"
                >{{ featuredBlogs[0]?.editor }}</a
              >
            </div>
          </div>
        </article>
      </div>
      <div class="col-lg-4 col-md-12 order-2 order-lg-1">
        <div class="row gx-0 gx-md-5 gx-lg-0 gy-5">
          <div class="col-lg-12 col-md-6">
            <article class="bg-white d-flex flex-column h-100">
              <div class="p-4 pb-0">
                <ul class="post-meta list-inline mb-3">
                  <li class="list-inline-item">
                    <i class="ti ti-calendar-event me-1"></i
                    >{{ featuredBlogs[1]?.updated | date }}
                  </li>
                  <li class="list-inline-item">•</li>
                  <li class="list-inline-item">
                    <i class="ti ti-clock-2 me-1"></i
                    >{{ featuredBlogs[1]?.readTime }} min read
                  </li>
                </ul>
                <div class="position-relative">
                  <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                    <a
                      class="text-link stretched-link"
                      [href]="'blog/' + featuredBlogs[1]?.href"
                      (click)="redirectBlog(featuredBlogs[1]?.href)"
                      title="{{ featuredBlogs[1]?.title }}"
                      >{{ featuredBlogs[1]?.title }}</a
                    >
                  </h3>
                  <p class="mb-0 line-clamp clamp-3">
                    {{ featuredBlogs[1]?.subTitle }}
                  </p>
                </div>
              </div>
              <div class="post-author d-flex mt-auto p-4">
                <div class="flex-shrink-0">
                  <a
                    [href]="'blog/' + featuredBlogs[1]?.href"
                    (click)="redirectBlog(featuredBlogs[1]?.href)"
                    class="is-hoverable"
                    title="Read all posts of - {{ featuredBlogs[1]?.editor }}"
                  >
                    <img
                      loading="lazy"
                      class="rounded-circle w-auto"
                      src="../../../assets/blog/templates/AU/assets/images/author/qubyk-logo.png"
                      [alt]="featuredBlogs[1]?.editor"
                      width="42"
                      height="42"
                    />
                  </a>
                </div>
                <div class="flex-grow-1 ms-3">
                  <p class="mb-0 lh-base small">Written by</p>
                  <a
                    class="text-link"
                    (click)="redirectBlog(featuredBlogs[1]?.href)"
                    title="Read all posts by - {{ featuredBlogs[1]?.editor }}"
                    >{{ featuredBlogs[1]?.editor }}</a
                  >
                </div>
              </div>
            </article>
          </div>
          <div class="col-lg-12 col-md-6">
            <article class="bg-white d-flex flex-column h-100">
              <div class="p-4 pb-0">
                <ul class="post-meta list-inline mb-3">
                  <li class="list-inline-item">
                    <i class="ti ti-calendar-event me-1"></i
                    >{{ featuredBlogs[2]?.updated | date }}
                  </li>
                  <li class="list-inline-item">•</li>
                  <li class="list-inline-item">
                    <i class="ti ti-clock-2 me-1"></i
                    >{{ featuredBlogs[2]?.readTime }} min read
                  </li>
                </ul>
                <div class="position-relative">
                  <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                    <a
                      class="text-link stretched-link"
                      [href]="'blog/' + featuredBlogs[2]?.href"
                      (click)="redirectBlog(featuredBlogs[2]?.href)"
                      title="{{ featuredBlogs[2]?.title }}"
                      >{{ featuredBlogs[2]?.title }}</a
                    >
                  </h3>
                  <p class="mb-0 line-clamp clamp-3">
                    {{ featuredBlogs[2]?.subTitle }}
                  </p>
                </div>
              </div>
              <div class="post-author d-flex mt-auto p-4">
                <div class="flex-shrink-0">
                  <a
                    [href]="'blog/' + featuredBlogs[2]?.href"
                    (click)="redirectBlog(featuredBlogs[2]?.href)"
                    class="is-hoverable"
                    title="Read all posts of - {{ featuredBlogs[2]?.editor }}"
                  >
                    <img
                      loading="lazy"
                      class="rounded-circle w-auto"
                      src="../../../assets/blog/templates/AU/assets/images/author/qubyk-logo.png"
                      [alt]="featuredBlogs[2]?.editor"
                      width="42"
                      height="42"
                    />
                  </a>
                </div>
                <div class="flex-grow-1 ms-3">
                  <p class="mb-0 lh-base small">Written by</p>
                  <a
                    class="text-link"
                    [href]="'blog/' + featuredBlogs[2]?.href"
                    (click)="redirectBlog(featuredBlogs[2]?.href)"
                    title="Read all posts by - {{ featuredBlogs[2]?.editor }}"
                    >{{ featuredBlogs[2]?.editor }}</a
                  >
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 order-1 order-lg-2">
        <article class="bg-white d-flex flex-column h-100">
          <div class="post-image">
            <a
              class="d-block"
              [href]="'blog/' + featuredBlogs[3]?.href"
              (click)="redirectBlog(featuredBlogs[3]?.href)"
              title="What Do You Do When You Know You’re Not Fine?"
            >
              <img
                loading="lazy"
                class="w-100 h-auto"
                [src]="featuredBlogs[3]?.imageSrc"
                [alt]="featuredBlogs[3]?.title"
                width="400"
                height="264"
              />
            </a>
          </div>
          <div class="p-4 pb-0">
            <ul class="post-meta list-inline mb-3">
              <li class="list-inline-item">
                <i class="ti ti-calendar-event me-1"></i
                >{{ featuredBlogs[3]?.updated | date }}
              </li>
              <li class="list-inline-item">•</li>
              <li class="list-inline-item">
                <i class="ti ti-clock-2 me-1"></i
                >{{ featuredBlogs[3]?.readTime }} min read
              </li>
            </ul>
            <div class="position-relative">
              <h3 class="h4 post-title mb-2 line-clamp clamp-2">
                <a
                  class="text-link stretched-link"
                  [href]="'blog/' + featuredBlogs[3]?.href"
                  (click)="redirectBlog(featuredBlogs[3]?.href)"
                  title="{{ featuredBlogs[3]?.title }}"
                  >{{ featuredBlogs[3]?.title }}</a
                >
              </h3>
              <p class="mb-0 line-clamp clamp-3">
                {{ featuredBlogs[3]?.subTitle }}
              </p>
            </div>
          </div>
          <div class="post-author d-flex mt-auto p-4">
            <div class="flex-shrink-0">
              <a
                [href]="'blog/' + featuredBlogs[3]?.href"
                (click)="redirectBlog(featuredBlogs[3]?.href)"
                class="is-hoverable"
                title="Read all posts of - {{ featuredBlogs[3]?.editor }}"
              >
                <img
                  loading="lazy"
                  class="rounded-circle w-auto"
                  src="../../../assets/blog/templates/AU/assets/images/author/qubyk-logo.png"
                  [alt]="featuredBlogs[3]?.editor"
                  width="42"
                  height="42"
                />
              </a>
            </div>
            <div class="flex-grow-1 ms-3">
              <p class="mb-0 lh-base small">Written by</p>
              <a
                class="text-link"
                [href]="'blog/' + featuredBlogs[3]?.href"
                (click)="redirectBlog(featuredBlogs[3]?.href)"
                title="Read all posts by - {{ featuredBlogs[3]?.editor }}"
                >{{ featuredBlogs[3]?.editor }}</a
              >
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</section>
<!-- end of Featured Posts -->

<!-- start of Post of the month -->
<section
  *ngIf="postoftheMonth"
  class="post-of-the-month"
  [ngStyle]="{
    'background-image': 'url(' + postoftheMonth.monthImageSrc + ')'
  }"
>
  <div class="container">
    <div class="row">
      <div class="col-xl-7 col-lg-8 col-md-10 mx-auto text-center">
        <div class="mb-5 pb-4">
          <h2
            class="text-uppercase h6 text-black bg-white mb-0 d-inline-block px-3 py-2 lh-1"
            style="background-color: white !important; color: black"
          >
            Post of the month
          </h2>
        </div>
        <ul class="post-meta list-inline mb-3 text-light">
          <li class="list-inline-item" style="color: white">
            <i class="ti ti-calendar-event me-1"></i
            >{{ postoftheMonth?.updated | date }}
          </li>
          <li class="list-inline-item" style="color: white">•</li>
          <li class="list-inline-item" style="color: white">
            <i class="ti ti-clock-2 me-1"></i>{{ postoftheMonth?.readTime }} min
            read
          </li>
        </ul>
        <h3 class="h2 post-title mb-4 position-relative" style="color: white">
          <a
            class="text-white text-link stretched-link"
            [href]="'blog/' + postoftheMonth?.href"
            (click)="redirectBlog(postoftheMonth?.href)"
            >{{ postoftheMonth?.title }}</a
          >
        </h3>
        <div class="post-author" style="color: white">
          <a
            [href]="'blog/' + postoftheMonth?.href"
            (click)="redirectBlog(postoftheMonth?.href)"
            class="is-hoverable"
            title="Read all posts of - {{ postoftheMonth?.editor }}"
          >
            <img
              loading="lazy"
              class="w-auto rounded-circle me-2"
              src="../../../assets/blog/templates/AU/assets/images/author/alexander-hipp.jpg"
              [alt]="postoftheMonth?.editor"
              width="26"
              height="26"
            />
          </a>
          <span class="text-light">by </span>
          <a
            [href]="'blog/' + postoftheMonth?.href"
            (click)="redirectBlog(postoftheMonth?.href)"
            class="text-link text-white"
            title="Read all posts of - Alexander Hipp"
            >{{ postoftheMonth?.editor }}</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
<!-- end of Post of the month -->

<!-- start newsletter -->
<section class="line-bg bg-white">
  <div class="newsletter-block border-bottom">
    <div class="container">
      <div
        class="row gy-5 align-items-center justify-content-center text-center text-md-start"
      >
        <div class="col-xl-5 col-lg-5 col-md-6 col-sm-10">
          <div class="pe-0 pe-xl-4">
            <h2 class="mb-3 lh-sm">Subscribe to our monthly newsletter</h2>
            <p class="mb-0">
              Stay up-to-date about latest tech and new world. Unsubscribe at
              anytime!
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-6">
          <div class="ps-0 ps-xl-4">
            <div id="mc_embed_signup">
              <div id="mc_embed_signup_scroll" class="input-group">
                <div class="input-group-append w-100">
                  <button
                    type="button"
                    name="subscribe"
                    (click)="onSubscribeClick()"
                    id="mc-embedded-subscribe"
                    class="input-group-text w-100 mb-0"
                    aria-label="Subscription Button"
                  >
                    Subscribe Now <i class="ti ti-arrow-up-right ms-auto"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- end newsletter -->

<app-subscribe></app-subscribe>
