import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgRedux } from '@angular-redux2/store';
import { Observable } from 'rxjs';

import {
  GET_ITEMS_VIEWED,
  ADD_ITEM_VIEWED,
  CLEAR_ITEM_VIEWED,
  REQ_ITEM_VIEWED_ERROR,
} from '../../store/items-viewed.actions';

import { IItemsViewedState } from '../../store';

import { SiteService } from '../site/site.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ItemsViewedService {
  readonly API_PREFIX: string;

  onGetItemsViewed: string;

  constructor(
    private http: HttpClient,
    private ngRedux: NgRedux<IItemsViewedState>,
    private siteService: SiteService
  ) {
    this.API_PREFIX = `${environment.siteUrl}/items-viewed/${environment.apiVersion}`;
  }

  get(start: number = 0, limit: number = 10) {
    let userId = this.siteService?.user?.id ?? this.siteService?.userId;

    const url = `${this.API_PREFIX}/${this.siteService.domain}/${this.siteService.currency}/${userId}/all/${start}/${limit}`;

    this.onGetItemsViewed = 'true';

    this.http.get(url).subscribe(
      (data: any) => {
        const products = data.data;

        setTimeout(() => (this.onGetItemsViewed = 'success'), 1000);

        return this.ngRedux.dispatch({
          type: GET_ITEMS_VIEWED,
          products,
        });
      },
      () => {
        this.onGetItemsViewed = 'error';

        return this.ngRedux.dispatch({
          type: REQ_ITEM_VIEWED_ERROR,
        });
      }
    );
  }

  add(product: any) {
    product = JSON.parse(JSON.stringify(product));

    const {
      id,
      title,
      brand,
      price,
      rrp,
      image,
      size,
      category,
      subCat,
      childCat,
    } = product;

    for (let i = 0; i < image.length; i += 1) {
      image[i] = image[i].replace(this.siteService.IMAGE_BUCKET, '');
    }

    const url = `${this.API_PREFIX}/add`;
    const params: any = {
      siteId: this.siteService.domain,
      prodId: id,
      brand,
      title,
      size,
      currency: this.siteService.currencyInfo.code,
      price,
      rrp,
      image,
      category,
      subCat,
      childCat,
    };
    const headers = {
      Authorization: this.siteService.getAuthorizationHeader().Authorization,
    };

    params.userId = this.siteService?.user?.id ?? this.siteService?.userId;

    this.http.post(url, params, { headers }).subscribe(
      () => {
        return this.ngRedux.dispatch({
          type: ADD_ITEM_VIEWED,
          product,
        });
      },
      () => {
        return this.ngRedux.dispatch({
          type: REQ_ITEM_VIEWED_ERROR,
        });
      }
    );
  }

  updateUserId(params: any): Observable<any> {
    return this.http.put(`${this.API_PREFIX}/update-userId`, params);
  }

  clear() {
    return this.ngRedux.dispatch({
      type: CLEAR_ITEM_VIEWED,
    });
  }

  delete(params: any) {}
}
