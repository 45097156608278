import { Component } from '@angular/core';
import { CartItemsComponent } from './cart-items.component';
import { SiteService } from '../../ecommerce/services/site/site.service';
import { CartService } from '../../ecommerce/services/cart/cart.service';
import { CookieService } from '../../ecommerce/services/cookie/cookie.service';
import { CourierService } from '../../ecommerce/services/courier/courier.service';
import { ShippingService } from '../../ecommerce/services/shipping/shipping.service';
import { StripeService } from '../../ecommerce/services/stripe/stripe.service';
import { OrdersService } from '../../ecommerce/services/orders/orders.service';
import { CouponService } from '../../ecommerce/services/coupon/coupon.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-items-desktop',
  templateUrl: './cart-items-desktop.component.html',
  styleUrls: ['./cart-items-desktop.component.scss'],
})
export class CartItemsDesktopComponent extends CartItemsComponent {
  constructor(
    protected router: Router,
    public siteService: SiteService,
    protected cartService: CartService,
    protected cookieService: CookieService,
    protected courierService: CourierService,
    protected shippingService: ShippingService,
    protected stripeService: StripeService,
    protected ordersService: OrdersService,
    protected couponService: CouponService
  ) {
    super(
      router,
      siteService,
      cartService,
      cookieService,
      courierService,
      shippingService,
      stripeService,
      ordersService,
      couponService
    );
  }
}
