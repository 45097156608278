import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgRedux } from '@angular-redux2/store';
import { Observable } from 'rxjs';

import { IQubykQuickBox } from '../../../models/qubyk-quick/IQubykQuickBox.model';

import { IQubykQuickState } from '../../store/qubykQuick.store';
import {
  REQ_QUBYK_QUICK_ERROR,
  GET_QUBYK_QUICK_BOXES_SUCCESS,
} from '../../store/qubykQuick.actions';

import { SiteService } from '../site/site.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QubykQuickService {
  readonly API_PREFIX: string;

  onGetBoxes: string;

  constructor(
    private http: HttpClient,
    private ngRedux: NgRedux<IQubykQuickState>,
    private siteService: SiteService
  ) {
    this.API_PREFIX = `${environment.siteUrl}/qubyk-quick/${environment.apiVersion}`;
  }

  getBoxes() {
    this.onGetBoxes = 'true';

    this.http
      .get(
        `${this.API_PREFIX}/quick-boxes/${this.siteService.domain}/${this.siteService.currency}`
      )
      .subscribe(
        (boxes: IQubykQuickBox) => {
          this.onGetBoxes = 'success';

          return this.ngRedux.dispatch({
            type: GET_QUBYK_QUICK_BOXES_SUCCESS,
            boxes,
          });
        },
        () => {
          this.onGetBoxes = 'error';

          return this.ngRedux.dispatch({
            type: REQ_QUBYK_QUICK_ERROR,
          });
        }
      );
  }

  getCategoryBoxes(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}/quick-category-boxes`, params);
  }

  getProducts(params: any): Observable<any> {
    return this.http.post(`${this.API_PREFIX}/quick-products`, params);
  }
}
