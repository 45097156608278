import { Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutPayStripeComponent } from './checkout-pay-stripe.component';
import { SiteService } from '../../services/site/site.service';
import { CartService } from '../../services/cart/cart.service';
import { OrdersService } from '../../services/orders/orders.service';
import { CheckoutService } from '../../services/checkout/checkout.service';

@Component({
  selector: 'app-checkout-pay-stripe-simple',
  templateUrl: './checkout-pay-stripe-simple.component.html',
  styleUrls: ['./checkout-pay-stripe-simple.component.scss'],
})
export class CheckoutPayStripeSimpleComponent extends CheckoutPayStripeComponent {
  @Input() orderId: string;
  @Input() redirectUrl: string;
  @Input() shipping: any;
  @Input() showStripePayment: boolean;
  @Output() showStripePaymentChange: EventEmitter<boolean>;

  onCancel: string;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected cartService: CartService,
    protected ordersService: OrdersService,
    protected checkoutService: CheckoutService,

    public siteService: SiteService
  ) {
    super(document, router, activatedRoute, siteService);

    this.showStripePaymentChange = new EventEmitter<boolean>();
  }

  onCancelClick() {
    const params = {
      userId: this.siteService?.user?.id ?? this.siteService?.userId,
      orderId: this.siteService.order.id,
    };

    this.onCancel = 'true';

    this.checkoutService.cancelPayment(params).subscribe(
      () => {
        document.dispatchEvent(
          new CustomEvent('orderCancelled', {
            bubbles: true,
            cancelable: true,
          })
        );

        this.showStripePaymentChange.emit(false);
      },
      () => {
        this.showStripePaymentChange.emit(false);
      }
    );
  }

  async onSubmit() {
    this.onPay = true;

    let siteId = this.siteService.domain;
    let redirectUrl = this.redirectUrl;
    let elements = this.elements;

    const { error } = await this.stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          `${location.origin}/checkout/stripe/validate` +
          `?siteId=${siteId}&token=${redirectUrl}&orderId=${this.orderId}`,
      },
    });

    if (error) {
      this.error = error.message;
    } else {
      // Do not clear the checkout page.
      //Customer himself will clear it by clicking cancel button
      // this.error = null;
      // this.siteService.unsetOrder();
      // this.siteService.unsetCartId();
      // this.cartService.clear();
      // this.ordersService.clear();
    }
  }
}
