import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Autosize } from 'ng-autosize';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { EmptyComponent } from '../components/empty/empty.component';
import { LayoutComponent } from '../layout/layout.component';
import { CarouselComponent } from '../components/carousel/carousel.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { CarouselItemsComponent } from '../components/carousel-items/carousel-items.component';
import { ProductDetailReviewsComponent } from '../ecommerce/product-detail/components/product-detail-reviews/product-detail-reviews.component';
import { ProductCardDesktopComponent } from '../components/product-card/product-card-desktop.component';
import { ProductCardMobileComponent } from '../components/product-card/product-card-mobile.component';
import { PSectionDesktopComponent } from '../components/p-section-desktop/p-section-desktop.component';
import { PSectionMobileComponent } from '../components/p-section-mobile/p-section-mobile.component';
import { SliderDesktopComponent } from '../components/slider-desktop/slider-desktop.component';
import { SliderMobileComponent } from '../components/slider-mobile/slider-mobile.component';
import { CategoriesAreaDesktopComponent } from '../components/categories-area-desktop/categories-area-desktop.component';
import { CategoriesAreaMobileComponent } from '../components/categories-area-mobile/categories-area-mobile.component';
import { AdAreaDesktopComponent } from '../components/ad-area/ad-area-desktop.component';
import { AdAreaMobileComponent } from '../components/ad-area/ad-area-mobile.component';
import { LayoutMobileComponent } from '../layout/layout-mobile.component';
import { LayoutDesktopComponent } from '../layout/layout-desktop.component';
import { BlogDesktopComponent } from '../ecommerce/blog/blog-desktop.component';
import { BlogDetailsDesktopComponent } from '../ecommerce/blog-details/blog-details-desktop.component';
import { StripbarComponent } from '../layout/stripbar/stripbar.component';
import { NavbarDesktopComponent } from '../layout/navbar/navbar-desktop.component';
import { NavbarMobileComponent } from '../layout/navbar/navbar-mobile.component';
import { HeaderMobileComponent } from '../layout/header-mobile/header-mobile.component';
import { NavLinksDesktopComponent } from '../layout/nav-links/nav-links-desktop.component';
import { NavLinksMobileComponent } from '../layout/nav-links/nav-links-mobile.component';
import { LoginDesktopComponent } from '../components/login/login-desktop.component';
import { LoginModalDesktopComponent } from '../components/login/login-modal-desktop.component';
import { LoginMobileComponent } from '../components/login/login-mobile.component';
import { RegisterDesktopComponent } from '../components/register/register-desktop.component';
import { RegisterModalDesktopComponent } from '../components/register/register-modal-desktop.component';
import { RegisterMobileComponent } from '../components/register/register-mobile.component';
import { SubscribeComponent } from '../components/subscribe/subscribe.component';
import { InstagramLoginFormComponent } from '../components/instagram-login-form/instagram-login-form.component';
import { SearchMobileComponent } from '../components/search-mobile/search-mobile.component';
import { CookieModalComponent } from '../components/cookie-modal/cookie-modal.component';
import { CheckoutPayStripeSimpleComponent } from '../ecommerce/checkout/checkout-pay-stripe/checkout-pay-stripe-simple.component';

import { LinkifyPipe } from '../pipes/linkify-pipe';

import { ProductsService } from '../ecommerce/services/products.service';

import { environment } from '../../environments/environment';
import { BuynowModalDesktopComponent } from '../components/buynow/buynow-modal-desktop.component';
import { CouponComponent } from '../components/coupon/coupon.component';
import { CartItemsDesktopComponent } from '../components/cart-items/cart-items-desktop.component';
import { SubCategoryAreaDesktopComponent } from '../components/subcategory-area/subcategory-area-desktop.component';
import { SubCategoryAreaMobileComponent } from '../components/subcategory-area/subcategory-area-mobile.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoadingBarRouterModule,
    ReactiveFormsModule,
    RouterModule,
    GooglePlaceModule,
    NgxImageZoomModule,
    RecaptchaModule.forRoot({
      siteKey: environment.reCaptchaSiteKey,
    }),
    ModalModule,
    CarouselModule.forRoot(),
  ],
  declarations: [
    Autosize,
    EmptyComponent,
    LayoutComponent,
    CarouselComponent,
    CouponComponent,
    CartItemsDesktopComponent,
    CarouselItemsComponent,
    LayoutMobileComponent,
    LayoutDesktopComponent,
    StripbarComponent,
    NavbarDesktopComponent,
    NavbarMobileComponent,
    HeaderMobileComponent,
    NavLinksDesktopComponent,
    NavLinksMobileComponent,
    BuynowModalDesktopComponent,
    LoginDesktopComponent,
    LoginModalDesktopComponent,
    LoginMobileComponent,
    RegisterDesktopComponent,
    RegisterModalDesktopComponent,
    RegisterMobileComponent,
    SubscribeComponent,
    InstagramLoginFormComponent,
    ProductDetailReviewsComponent,
    ProductCardDesktopComponent,
    ProductCardMobileComponent,
    PSectionDesktopComponent,
    PSectionMobileComponent,
    SliderDesktopComponent,
    SliderMobileComponent,
    CategoriesAreaDesktopComponent,
    CategoriesAreaMobileComponent,
    AdAreaDesktopComponent,
    AdAreaMobileComponent,
    SearchMobileComponent,
    CookieModalComponent,
    CheckoutPayStripeSimpleComponent,
    LinkifyPipe,
    SubCategoryAreaDesktopComponent,
    SubCategoryAreaMobileComponent,
    BlogDesktopComponent,
    BlogDetailsDesktopComponent,
    BreadcrumbComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    Autosize,
    NgxImageZoomModule,
    ModalModule,
    CarouselModule,
    GooglePlaceModule,
    EmptyComponent,
    InstagramLoginFormComponent,
    CarouselComponent,
    CarouselItemsComponent,
    ProductDetailReviewsComponent,
    ProductCardDesktopComponent,
    ProductCardMobileComponent,
    PSectionDesktopComponent,
    PSectionMobileComponent,
    SliderDesktopComponent,
    SliderMobileComponent,
    CategoriesAreaDesktopComponent,
    CategoriesAreaMobileComponent,
    AdAreaDesktopComponent,
    AdAreaMobileComponent,
    SearchMobileComponent,
    LoginMobileComponent,
    CookieModalComponent,
    CheckoutPayStripeSimpleComponent,
    LinkifyPipe,
    CouponComponent,
    CartItemsDesktopComponent,
    SubCategoryAreaDesktopComponent,
    SubCategoryAreaMobileComponent,
    BlogDesktopComponent,
    BlogDetailsDesktopComponent,
    BreadcrumbComponent,
  ],
  providers: [ProductsService],
})
export class SharedModule {}
