<header>
  <a
    [routerLink]="['/']"
    onclick="this.style.font='50 30px/1.3 \'Caveat\', Helvetica, sans-serif;'"
  >
  <span style="
  font: 100 35px/1.3 'Caveat', Helvetica, sans-serif;
  color: #000;
  font-weight: 100;
  padding-left: 10px;
  text-decoration: none;
">
    qubyk
  </span>

    <span>{{ siteService.domainExtension }}</span>
  </a>
</header>
