import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SiteService } from '../site/site.service';
import { environment } from '../../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from '../cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  readonly API_PREFIX: string;

  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    private cookieService: CookieService
  ) {
    this.API_PREFIX = `${environment.siteUrl}/api/coupon/${environment.apiVersion}`;
  }

  checkCoupon(code: string): Observable<any> {
    const params = {
      siteId: this.siteService.domain,
      code,
    };

    return this.http.post(`${this.API_PREFIX}/discount`, params);
  }

  getCouponFormGroup(coupon) {
    const getCouponFormGroup = (): FormGroup => {
      return new FormGroup({
        coupon: new FormControl(coupon, [Validators.required]),
      });
    };

    return getCouponFormGroup;
  }

  onCouponCodeInput(cf: any, discount: any) {
    if (cf.coupon.value) {
      discount = null;
    }
  }

  isExists() {
    let cookie = this.cookieService.get('couponParams');

    if (cookie) return true;

    return false;
  }

  removeCouponParams() {
    this.cookieService.delete('couponParams');
  }
}
