import { Routes } from '@angular/router';
import { ErrorComponent } from './error/error.component';

export const ROUTES: Routes = [
  // {
  //  path: '', redirectTo: 'app', pathMatch: 'full'
  // },
  // {
  //   path: '', loadChildren: '../app/ecommerce/ecommerce.module#EcommerceModule'
  // },
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];
